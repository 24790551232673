export interface Tag {
  key: string;
  value: string;
}

export interface IterablePaginationResult<T> extends PaginationResult<T>, Iterable<T> {}

class IterablePaginationResultImpl<T> implements IterablePaginationResult<T> {
  private result: PaginationResult<T>;

  constructor(result: PaginationResult<T>) {
    this.result = result;
  }

  get items(): T[] {
    return this.result.items;
  }

  get total(): number {
    return this.result.total;
  }

  get nextToken(): string | undefined {
    return this.result.nextToken;
  }

  *[Symbol.iterator](): Iterator<T> {
    let counter = 0;
    while (counter < this.items.length) {
      yield this.items[counter++];
    }
  }
}

/**
 * Used to make a PaginationResult iterable and thus play nicely with rxjs
 * @param result returned from gql call
 * @constructor
 */
export function WrapPaginationResultAsIterable<T>(result: PaginationResult<T>): IterablePaginationResult<T> {
  return new IterablePaginationResultImpl(result);
}

export interface PaginationResult<T> {
  items: T[];
  total: number;
  nextToken?: string;
}

export interface SingletonResult<T> {
  item: T;
}
