import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';

const PaddedText = styled('span')(
  css({
    display: 'inline-block',
    marginRight: '8px',
    marginLeft: '8px',
  })
);

export default PaddedText;
