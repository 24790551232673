import axios, { AxiosResponse } from 'axios';
import Logger from 'logger/Logger';
import { getMd5HashOfFile } from 'utils/Helper';

const logger = Logger.getLogger('Upload');

export const PostFileUpload = async (fileToUpload: File, presignedUrl: string): Promise<AxiosResponse> => {
  // Attach File for formdata
  const formData = new FormData();
  formData.append('file', fileToUpload, fileToUpload.name);

  // Get the hash of the file
  const hash = await getMd5HashOfFile(fileToUpload);
  const response = await axios({
    url: presignedUrl,
    method: 'PUT',
    data: await fileToUpload.arrayBuffer(),
    headers: {
      'Content-MD5': hash,
    },
  });
  logger.debug('Got FileUpload Response', { response });
  return response;
};

const FileUpload = {
  PostFileUpload,
};

export default FileUpload;
