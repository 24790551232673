import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { ThemeColors } from '@tanium/theme-data';

const ErrorText = styled('span')(
  css({
    color: ThemeColors.Error,
    fontWeight: 'bold',
  })
);
export default ErrorText;
