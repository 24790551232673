import { IDed } from 'gql/Request';
import { PaginationResult, SingletonResult } from 'gql/Common';
import Helper from 'utils/Helper';

export interface CreateAwsRoleTargetAccess {
  adGroups: string[];
  awsRoleName: string;
  awsSessionPolicy: string;
  description?: string;
  targetOrTargetGroupID: string;
}

export interface UpdateAwsRoleTargetAccess extends IDed, CreateAwsRoleTargetAccess {}

export interface AwsRoleTargetAccess extends UpdateAwsRoleTargetAccess {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
}

export const FULL_AWS_ROLE_TARGET_ACCESS = `
{
  id
  createdBy
  createdAt
  updatedBy
  updatedAt
  description
  awsRoleName
  targetOrTargetGroupID
  awsSessionPolicy
  adGroups
}`;

const GET_AWS_ROLE_TARGET_ACCESS = `
  query getAwsRoleTargetAccess($id: String!) {
    getAwsRoleTargetAccess (id: $id) {
      item ${FULL_AWS_ROLE_TARGET_ACCESS}
    }
  }
`;

const LIST_AWS_ROLE_TARGET_ACCESS = `
  query listAwsRoleTargetAccess($filter: TargetBoundAccessFilterInput, $pagination: PaginationInput) {
    listAwsRoleTargetAccess (filter: $filter, pagination: $pagination) {
      items ${FULL_AWS_ROLE_TARGET_ACCESS}
      total
      nextToken
    }
  }
`;

export interface GetAwsRoleTargetAccessOptions {
  id: string;
}

export const getAwsRoleTargetAccess = async (
  options: GetAwsRoleTargetAccessOptions
): Promise<AwsRoleTargetAccess | undefined> => {
  const result = await Helper.execGQL<SingletonResult<AwsRoleTargetAccess | undefined>>({
    query: GET_AWS_ROLE_TARGET_ACCESS,
    variables: {
      id: options.id,
    },
  });
  return result.getAwsRoleTargetAccess?.item;
};

export interface ListAwsRoleTargetAccessOptions {
  size?: number;
  nextToken?: string;
  includeAllTargetGroups: boolean;
  targetOrTargetGroupID: string;
  userADGroups?: string[];
}

export const listAwsRoleTargetAccess = async (
  options: ListAwsRoleTargetAccessOptions
): Promise<PaginationResult<AwsRoleTargetAccess>> => {
  const result = await Helper.execGQL<PaginationResult<AwsRoleTargetAccess>>({
    query: LIST_AWS_ROLE_TARGET_ACCESS,
    variables: {
      filter: {
        includeAllTargetGroups: options.includeAllTargetGroups,
        targetOrTargetGroupID: options.targetOrTargetGroupID,
        userADGroups: options.userADGroups,
      },
      pagination: {
        size: options.size,
        nextToken: options.nextToken,
      },
    },
  });
  return result.listAwsRoleTargetAccess;
};

const CREATE_AWS_ROLE_TARGET_ACCESS = `
  mutation createAwsRoleTargetAccess($create: CreateAwsRoleTargetAccessInput!) {
    createAwsRoleTargetAccess(create: $create) {
      item {
        id
      }
    } 
  }`;

export const createAwsRoleTargetAccess = async (awsRole: CreateAwsRoleTargetAccess): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<AwsRoleTargetAccess>>({
    query: CREATE_AWS_ROLE_TARGET_ACCESS,
    variables: {
      create: awsRole,
    },
  });
  return result.createAwsRoleTargetAccess.item;
};

const UPDATE_AWS_ROLE_TARGET_ACCESS = `
  mutation updateAwsRoleTargetAccess($update: UpdateAwsRoleTargetAccessInput!) {
      updateAwsRoleTargetAccess(update: $update) {
        item { 
          id
        }
      }
  }`;

export const updateAwsRoleTargetAccess = async (awsRole: UpdateAwsRoleTargetAccess): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<AwsRoleTargetAccess>>({
    query: UPDATE_AWS_ROLE_TARGET_ACCESS,
    variables: {
      update: awsRole,
    },
  });
  return result.updateAwsRoleTargetAccess.item;
};

const DELETE_AWS_ROLE_TARGET_ACCESS = `
mutation deleteAwsRoleTargetAccess($id: String!) {
  deleteAwsRoleTargetAccess(id: $id) {
    item ${FULL_AWS_ROLE_TARGET_ACCESS}
  }
}
`;

export const deleteAwsRoleTargetAccess = async (id: string): Promise<AwsRoleTargetAccess> => {
  const result = await Helper.execGQL<SingletonResult<AwsRoleTargetAccess>>({
    query: DELETE_AWS_ROLE_TARGET_ACCESS,
    variables: {
      id,
    },
  });
  return result.deleteAwsRoleTargetAccess.item;
};
