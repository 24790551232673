import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/RootReducer';
import { ActionStatus } from 'store/ActionState';
import { HostTargetAccess } from 'gql/HostTargetAccess';
import { HostTargetAccessState, hostTargetAccessAdapter } from './HostTargetAccessSlice';

const selectSelf = (state: RootState): HostTargetAccessState => {
  return state.hostTargetAccess;
};

const selectHostTargetAccess = (id?: string) => {
  return (state: RootState): HostTargetAccess | undefined => {
    return id
      ? hostTargetAccessAdapter.getSelectors().selectById(state.hostTargetAccess.hostTargetAccesses, id)
      : undefined;
  };
};

const selectHostTargetAccessLoading = createSelector(selectSelf, (state: HostTargetAccessState): boolean => {
  return state.loadingState.status === ActionStatus.PROCESSING;
});

const selectHostTargetAccesses = createSelector(selectSelf, (state: HostTargetAccessState): HostTargetAccess[] => {
  return hostTargetAccessAdapter.getSelectors().selectAll(state.hostTargetAccesses);
});

const selectMutationSucceeded = createSelector(selectSelf, (state: HostTargetAccessState): boolean => {
  return state.mutationState.status === ActionStatus.SUCCEEDED;
});

const HostTargetAccessSelectors = {
  selectMutationSucceeded,
  selectHostTargetAccessLoading,
  selectHostTargetAccesses,
  selectHostTargetAccess,
};

export default HostTargetAccessSelectors;
