import { createSelector } from '@reduxjs/toolkit';
import { awsAccountObjectAdapter, AwsAccountObjectState } from 'components/pages/console/store/AwsAccountObjectSlice';
import AwsAccountObject from 'gql/AwsAccountObject';
import { ActionStatus } from 'store/ActionState';
import { RootState } from 'store/RootReducer';
import PagerState from 'store/PagerState';

const selectSelf = (state: RootState): AwsAccountObjectState => {
  return state.awsAccountObject;
};

const selectFetchAwsAccountObjectsState = createSelector(selectSelf, (state: AwsAccountObjectState): ActionStatus => {
  return state.fetchAwsAccountObjectsState.status;
});

const selectFetchAwsAccountObjectsProcessing = createSelector(selectSelf, (state: AwsAccountObjectState): boolean => {
  return state.fetchAwsAccountObjectsState.status === ActionStatus.PROCESSING;
});

const selectSaveProcessing = createSelector(selectSelf, (state: AwsAccountObjectState): boolean => {
  return state.saveAwsAccountObjectState.status === ActionStatus.PROCESSING;
});

const selectFormData = createSelector(selectSelf, (state: AwsAccountObjectState): AwsAccountObject | undefined => {
  return state.awsAccountObjectForm;
});

const selectPagerState = createSelector(selectSelf, (state: AwsAccountObjectState): PagerState => {
  return state.pagerState;
});

const selectAwsAccountObjects = createSelector(selectSelf, (state: AwsAccountObjectState): AwsAccountObject[] => {
  if (state.awsAccountObjects) {
    return awsAccountObjectAdapter.getSelectors().selectAll(state.awsAccountObjects);
  }
  return [];
});

const AwsAccountObjectSelectors = {
  selectFetchAwsAccountObjectsState,
  selectFetchAwsAccountObjectsProcessing,
  selectSaveProcessing,
  selectFormData,
  selectAwsAccountObjects,
  selectPagerState,
};

export default AwsAccountObjectSelectors;
