/* eslint-disable import/prefer-default-export */
import User from 'gql/User';
import { ActionStatus } from 'store/ActionState';
import { RootState } from 'store/RootReducer';
import { Notification } from 'components/app/store/UserSlice';

const selectUser = (state: RootState): User | undefined => {
  return state.user.user;
};

const selectIsLoadingUser = (state: RootState): boolean => {
  return (
    state.user.userState.status === ActionStatus.PROCESSING ||
    (state.user.userState.status === ActionStatus.IDLE && !state.user.signOut)
  );
};

const selectNotification = (state: RootState): Notification | undefined => {
  return state.user.notification;
};

const UserSelectors = {
  selectUser,
  selectIsLoadingUser,
  selectNotification,
};

export default UserSelectors;
