import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import Request, { GetRequestsFilter } from 'gql/Request';
import ActionState, { ActionStatus } from 'store/ActionState';
import PagerState, { buildPagerState, PagerStatus } from 'store/PagerState';

export type ConsoleState = {
  requests: EntityState<Request>;
  requestState: ActionState;
  pagerState: PagerState;
};

export const requestAdapter = createEntityAdapter<Request>();
export const approvalAdapter = createEntityAdapter<Request>();

export const initialState: ConsoleState = {
  requests: requestAdapter.getInitialState(),
  requestState: { status: ActionStatus.IDLE },
  pagerState: {
    status: PagerStatus.HIDDEN,
  },
};

const ConsoleSlice = createSlice({
  name: 'console',
  initialState,
  reducers: {
    fetchRequests: (state, _: PayloadAction<GetRequestsFilter>): ConsoleState => {
      return { ...state, requestState: { status: ActionStatus.PROCESSING } };
    },
    fetchRequestsSucceeded: (state, action: PayloadAction<Request[]>): ConsoleState => {
      return {
        ...state,
        requests:
          // the ternary below prevents `cannot call get on a revoked proxy` with 2 empty lists
          action.payload.length === 0
            ? state.requests
            : requestAdapter.upsertMany(
                state.requests ? { ...state.requests } : requestAdapter.getInitialState(),
                action.payload
              ),
        requestState: { status: ActionStatus.SUCCEEDED },
        pagerState: buildPagerState(state.pagerState, action.payload),
      };
    },
    fetchRequestsFailed: (state, action: PayloadAction<Error>): ConsoleState => {
      return {
        ...state,
        requestState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    purgeRequests: (state): ConsoleState => {
      return {
        ...state,
        requests: requestAdapter.setAll({ ...state.requests }, []),
        pagerState: {
          status: PagerStatus.HIDDEN,
        },
      };
    },
  },
});

export default ConsoleSlice;
