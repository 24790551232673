import { mergeMap, catchError, map, concatAll, debounceTime, filter } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { from, of } from 'rxjs';
import i18next from 'i18next';
import RootEpic from 'store/RootEpic';
import RequestSlice from 'components/pages/request/store/RequestSlice';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';
import { createRequest, getRequest, closeSession } from 'gql/Request';
import { getCustomers, getCustomer } from 'gql/Customer';
import { getConfigs, updateSettingsConfig } from 'gql/Config';
import Helper from 'utils/Helper';
import { CONSOLE_ROUTE, REQUEST_ROUTE } from 'Route';
import { startSession } from 'gql/Session';
import { grantApproval } from 'gql/Approval';
import { getSessionLogs } from 'gql/SessionLog';
import { getSessionEvents } from 'gql/SessionEvent';
import { getRecorderSnapshots } from 'gql/RecorderSnapshot';
import { calculateRequestAccessLevel } from 'gql/RequestAccessLevelConfig';
import ChangeRequestSlice from 'components/pages/console/store/ChangeRequestSlice';
import { CHANGE_REQUEST_PREFIX } from 'components/pages/changeRequest/ChangeRequestPage';

// Polling epic - start polling on fetchRequestMetadata, ending on clearRequestState
export const fetchRequestMetadataEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchRequestMetadata.match),
    mergeMap((action) =>
      from(getRequest({ id: action.payload.id })).pipe(
        map((response) => RequestSlice.actions.fetchRequestMetadataSucceeded(response!)),
        catchError((error) => of(RequestSlice.actions.fetchRequestMetadataFailed(error)))
      )
    )
  );

export const fetchRequestMetadataFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchRequestMetadataFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_REQUEST'), err: payload }))
  );

export const fetchConfigMetadataEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchConfigs.match),
    debounceTime(500),
    map(() => RequestSlice.actions.fetchAllConfigs({}))
  );

export const fetchAllConfigMetadataEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchAllConfigs.match),
    mergeMap((action) =>
      from(getConfigs({ nextToken: action.payload.nextToken })).pipe(
        map((response) =>
          response.length > 0 && response[response.length - 1].nextToken
            ? [
                RequestSlice.actions.fetchConfigsSucceeded(response),
                RequestSlice.actions.fetchAllConfigs({ nextToken: response[response.length - 1].nextToken }),
              ]
            : [RequestSlice.actions.fetchConfigsSucceeded(response)]
        ),
        concatAll(),
        catchError((error) => of(RequestSlice.actions.fetchConfigsFailed(error)))
      )
    )
  );

export const fetchConfigMetadataFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchConfigsFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_CONFIGS'), err: payload }))
  );

export const updateSettingsConfigEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.updateSettingsConfig.match),
    mergeMap(({ payload }) => {
      return from(updateSettingsConfig(payload)).pipe(
        map((response) => RequestSlice.actions.updateSettingsConfigSucceeded(response)),
        catchError((error) => of(RequestSlice.actions.updateSettingsConfigFailed(error)))
      );
    })
  );

export const updateSettingsConfigSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.updateSettingsConfigSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_UPDATED_SETTINGS_CONFIG', action.payload.id),
        }),
      ];
    })
  );

export const updateSettingsConfigFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.updateSettingsConfigFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_UPDATE_SETTINGS_CONFIG'), err: payload });
    })
  );

export const fetchCustomerMetadataEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchCustomers.match),
    mergeMap((action) =>
      from(getCustomers({ nextToken: action.payload?.nextToken })).pipe(
        map((response) =>
          response.nextToken
            ? [
                RequestSlice.actions.fetchCustomersSucceeded(response.items),
                RequestSlice.actions.fetchCustomers({ nextToken: response.nextToken }),
              ]
            : [RequestSlice.actions.fetchCustomersSucceeded(response.items)]
        ),
        concatAll(),
        catchError((error) => of(RequestSlice.actions.fetchCustomersFailed(error)))
      )
    )
  );

export const fetchCustomerMetadataFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchCustomersFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_CUSTOMERS'), err: payload }))
  );

export const fetchSingleCustomerMetadataEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchCustomer.match),
    mergeMap((action) =>
      from(getCustomer(action.payload)).pipe(
        map((response) => RequestSlice.actions.fetchCustomerSucceeded(response)),
        catchError((error) => of(RequestSlice.actions.fetchCustomerFailed(error)))
      )
    )
  );

export const fetchSingleCustomerMetadataFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchCustomerFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_CUSTOMERS'), err: payload }))
  );

export const fetchSessionLogsEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchSessionLogs.match),
    mergeMap((action) =>
      from(getSessionLogs(action.payload.requestId)).pipe(
        map((response) => RequestSlice.actions.fetchSessionLogsSucceeded(response)),
        catchError((error) => of(RequestSlice.actions.fetchSessionLogsFailed(error)))
      )
    )
  );

export const fetchSessionLogsFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchSessionLogsFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_SESSION_LOGS'), err: payload }))
  );

export const fetchSessionEventsEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchSessionEvents.match),
    mergeMap((action) =>
      from(getSessionEvents(action.payload.requestId)).pipe(
        map((response) => RequestSlice.actions.fetchSessionEventsSucceeded(response)),
        catchError((error) => of(RequestSlice.actions.fetchSessionEventsFailed(error)))
      )
    )
  );

export const fetchSessionEventsFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchSessionEventsFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_SESSION_EVENTS'), err: payload }))
  );

export const fetchRecorderSnapshotsEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchRecorderSnapshots.match),
    mergeMap((action) =>
      from(getRecorderSnapshots(action.payload.requestId)).pipe(
        map((response) => RequestSlice.actions.fetchRecorderSnapshotsSucceeded(response)),
        catchError((error) => of(RequestSlice.actions.fetchRecorderSnapshotsFailed(error)))
      )
    )
  );

export const fetchRecorderSnapshotsFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.fetchRecorderSnapshotsFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_RECORDER_SNAPSHOTS'), err: payload }))
  );

export const createNewRequestEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.createNewRequest.match),
    mergeMap((action) =>
      from(createRequest({ createRequest: action.payload })).pipe(
        map((response) => RequestSlice.actions.createNewRequestSucceeded(response!)),
        catchError((error) => of(RequestSlice.actions.createNewRequestFailed(error)))
      )
    )
  );

// createNewRequestSuccessEpic - This epic relies on the router being in state.
// On a success the new request route is pushed to the router's history which triggers the user to navigate to that view
export const createNewRequestSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.createNewRequestSucceeded.match),
    mergeMap((action) => [
      showNotification({ type: 'success', contents: i18next.t('SUCCESS_CREATED_NEW_REQUEST') }),
      push(Helper.interpolateRoute(REQUEST_ROUTE.url, { requestId: action.payload.id })),
    ])
  );

export const createNewRequestCancelEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.cancelCreateNewRequest.match),
    map(() => push(CONSOLE_ROUTE.url))
  );

export const createNewRequestFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.createNewRequestFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_CREATE_REQUEST'), err: payload }))
  );

export const startNewSessionEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.startNewSession.match),
    mergeMap((action) =>
      from(startSession({ ...action.payload })).pipe(
        map((response) => RequestSlice.actions.startNewSessionSucceeded(response)),
        catchError((error) => of(RequestSlice.actions.startNewSessionFailed(error)))
      )
    )
  );

export const startNewSessionFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.startNewSessionFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_CREATE_SESSION'), err: payload }))
  );

// Attempt to close the active session
export const closeSessionEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.closeSession.match),
    mergeMap((action) =>
      from(closeSession({ requestID: action.payload })).pipe(
        map((response) => RequestSlice.actions.closeSessionSucceeded(response!)),
        catchError((error) => of(RequestSlice.actions.closeSessionFailed(error)))
      )
    )
  );

export const closeSessionSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.closeSessionSucceeded.match),
    mergeMap(() => [
      showNotification({
        type: 'success',
        contents: i18next.t('SUCCESS_CLOSED_SESSION'),
      }),
    ])
  );

// Failure when trying to close a session early
export const closeSessionFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.closeSessionFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_TO_CLOSE_EARLY'), err: payload }))
  );

// This reloads the request post grant
export const grantApprovalEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.acceptOrRejectApproval.match),
    mergeMap((action) =>
      from(grantApproval(action.payload)).pipe(
        map((result) =>
          RequestSlice.actions.acceptOrRejectApprovalSucceeded({ requestId: result.requestId, filter: action.payload })
        ),
        catchError((error) => of(RequestSlice.actions.acceptOrRejectApprovalFailed(error)))
      )
    )
  );

export const grantApprovalSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.acceptOrRejectApprovalSucceeded.match),
    mergeMap((action) => [
      showNotification({
        type: 'success',
        contents: i18next.t(action.payload.filter.grant ? 'SUCCESS_APPROVE_REQUEST' : 'SUCCESS_REJECT_REQUEST'),
      }),
      action.payload.requestId.startsWith(CHANGE_REQUEST_PREFIX)
        ? ChangeRequestSlice.actions.getChangeRequest(action.payload.requestId)
        : RequestSlice.actions.fetchRequestMetadata({ id: action.payload.requestId }),
    ])
  );

export const grantApprovalFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.acceptOrRejectApprovalFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GRANT_APPROVAL'), err: payload }))
  );

export const calculateRequestAccessLevelConfigEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.calculateRequestAccessLevelConfig.match),
    mergeMap((action) => {
      return from(calculateRequestAccessLevel(action.payload)).pipe(
        map((response) => RequestSlice.actions.calculateRequestAccessLevelConfigSucceeded(response)),
        catchError((error) => of(RequestSlice.actions.calculateRequestAccessLevelConfigFailed(error)))
      );
    })
  );

export const calculateRequestAccessLevelConfigFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestSlice.actions.calculateRequestAccessLevelConfigFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('calculate failed'), err: payload }))
  );

const RequestEpics: RootEpic[] = [
  fetchRequestMetadataEpic,
  fetchRequestMetadataFailedEpic,
  fetchConfigMetadataEpic,
  fetchConfigMetadataFailedEpic,
  fetchCustomerMetadataEpic,
  fetchCustomerMetadataFailedEpic,
  fetchSingleCustomerMetadataEpic,
  fetchSingleCustomerMetadataFailedEpic,
  updateSettingsConfigEpic,
  updateSettingsConfigSuccessEpic,
  updateSettingsConfigFailedEpic,
  createNewRequestEpic,
  createNewRequestSuccessEpic,
  createNewRequestCancelEpic,
  createNewRequestFailedEpic,
  startNewSessionEpic,
  startNewSessionFailedEpic,
  closeSessionEpic,
  closeSessionSuccessEpic,
  closeSessionFailedEpic,
  fetchSessionLogsEpic,
  fetchSessionLogsFailedEpic,
  fetchSessionEventsEpic,
  fetchSessionEventsFailedEpic,
  fetchRecorderSnapshotsEpic,
  fetchRecorderSnapshotsFailedEpic,
  grantApprovalEpic,
  grantApprovalSuccessEpic,
  grantApprovalFailedEpic,
  calculateRequestAccessLevelConfigEpic,
  calculateRequestAccessLevelConfigFailedEpic,
  fetchAllConfigMetadataEpic,
];

export default RequestEpics;
