import { createSlice, createEntityAdapter, PayloadAction, EntityState } from '@reduxjs/toolkit';
import Approval, { AcceptOrRejectApproval } from 'gql/Approval';
import Audit from 'gql/Audit';
import Config, { FetchAllConfigs } from 'gql/Config';
import Customer, { GetCustomersOptions } from 'gql/Customer';
import Request, { CreateRequest, IDed } from 'gql/Request';
import SessionLog, { SessionLogResponse } from 'gql/SessionLog';
import Session, { StartSession } from 'gql/Session';
import ActionState, { ActionStatus } from 'store/ActionState';
import Bastion from 'gql/Bastion';
import SessionEvent, { SessionEventResponse } from 'gql/SessionEvent';
import { RecorderSnapshot } from 'gql/RecorderSnapshot';
import RequestAccessLevelConfig from 'gql/RequestAccessLevelConfig';

export type RequestState = {
  // These are collections of normalized objects
  request?: Request;
  approvals: Approval[];
  audits: Audit[];
  sessions: Session[];
  bastions: Bastion[];
  loadRequestMetadataState: ActionState;

  configs: EntityState<Config>;
  configState: ActionState;

  customers: EntityState<Customer>;
  customerState: ActionState;
  createRequestState: ActionState;

  sessionLogs: SessionLog[];
  sessionLogNextPage?: string;
  sessionLogState: ActionState;

  sessionEvents: SessionEvent[];
  sessionEventState: ActionState;
  recorderSnapshots: RecorderSnapshot[];
  recorderSnapshotState: ActionState;

  startSessionArgs?: StartSession;
  startedSession?: Session;
  startSessionState: ActionState;
  closeSessionState: ActionState;

  acceptOrRejectApproval: ActionState;

  requestAccessLevelConfig?: RequestAccessLevelConfig;
  calculateRequestAccessLevelConfigState: ActionState;
};

export const approvalAdapter = createEntityAdapter<Approval>();
export const auditAdapter = createEntityAdapter<Audit>();
export const configAdapter = createEntityAdapter<Config>();
export const customerAdapter = createEntityAdapter<Customer>();

export const initialState: RequestState = {
  approvals: [],
  audits: [],
  sessions: [],
  bastions: [],
  loadRequestMetadataState: { status: ActionStatus.IDLE },

  configs: configAdapter.getInitialState(),
  configState: { status: ActionStatus.IDLE },

  customers: customerAdapter.getInitialState(),
  customerState: { status: ActionStatus.IDLE },
  createRequestState: { status: ActionStatus.IDLE },

  sessionLogs: [],
  sessionLogState: { status: ActionStatus.IDLE },

  sessionEvents: [],
  sessionEventState: { status: ActionStatus.IDLE },
  recorderSnapshots: [],
  recorderSnapshotState: { status: ActionStatus.IDLE },

  startSessionState: { status: ActionStatus.IDLE },
  closeSessionState: { status: ActionStatus.IDLE },

  acceptOrRejectApproval: { status: ActionStatus.IDLE },

  requestAccessLevelConfig: undefined,
  calculateRequestAccessLevelConfigState: { status: ActionStatus.IDLE },
};

const normalizeRequestState = (state: RequestState, request: Request): RequestState => {
  return {
    ...state,
    request: {
      ...request,
      approvals: undefined,
      audits: undefined,
      sessions: undefined,
      bastions: undefined,
    },
    approvals: request.approvals || [],
    audits: request.audits || [],
    sessions: request.sessions || [],
    bastions: request.bastions || [],
    loadRequestMetadataState: { status: ActionStatus.SUCCEEDED },
  };
};

const RequestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    fetchRequestMetadata: (state, _: PayloadAction<{ id: string }>): RequestState => {
      return { ...state, loadRequestMetadataState: { status: ActionStatus.PROCESSING } };
    },
    fetchRequestMetadataSucceeded: (state, action: PayloadAction<Request>): RequestState => {
      return {
        ...normalizeRequestState(state, action.payload),
        loadRequestMetadataState: { status: ActionStatus.SUCCEEDED },
      };
    },
    fetchRequestMetadataFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        loadRequestMetadataState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    fetchSessionLogs: (state, _: PayloadAction<{ requestId: string; nextToken?: string }>): RequestState => {
      return {
        ...state,
        sessionLogState: { status: ActionStatus.PROCESSING },
      };
    },
    fetchSessionLogsSucceeded: (state, action: PayloadAction<SessionLogResponse>): RequestState => {
      return {
        ...state,
        sessionLogState: { status: ActionStatus.SUCCEEDED },
        sessionLogNextPage: action.payload.nextToken,
        sessionLogs: [...state.sessionLogs, ...action.payload.sessionLogs],
      };
    },
    fetchSessionLogsFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        sessionLogState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    clearSessionLogs: (state): RequestState => {
      return {
        ...state,
        sessionLogs: [],
        sessionLogNextPage: undefined,
        sessionLogState: { status: ActionStatus.IDLE },
      };
    },
    fetchSessionEvents: (state, _: PayloadAction<{ requestId: string; nextToken?: string }>): RequestState => {
      return {
        ...state,
        sessionEventState: { status: ActionStatus.PROCESSING },
      };
    },
    fetchSessionEventsSucceeded: (state, action: PayloadAction<SessionEventResponse>): RequestState => {
      return {
        ...state,
        sessionEventState: { status: ActionStatus.SUCCEEDED },
        sessionEvents: [...state.sessionEvents, ...action.payload.events],
      };
    },
    fetchSessionEventsFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        sessionEventState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    clearSessionEvents: (state): RequestState => {
      return {
        ...state,
        sessionEvents: [],
        sessionEventState: { status: ActionStatus.IDLE },
      };
    },
    fetchRecorderSnapshots: (state, _: PayloadAction<{ requestId: string }>): RequestState => {
      return { ...state, recorderSnapshotState: { status: ActionStatus.PROCESSING } };
    },
    fetchRecorderSnapshotsSucceeded: (state, action: PayloadAction<RecorderSnapshot[]>): RequestState => {
      return {
        ...state,
        recorderSnapshotState: { status: ActionStatus.SUCCEEDED },
        recorderSnapshots: [...state.recorderSnapshots, ...action.payload],
      };
    },
    fetchRecorderSnapshotsFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        recorderSnapshotState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    clearRecorderSnapshots: (state): RequestState => {
      return {
        ...state,
        recorderSnapshots: [],
        recorderSnapshotState: { status: ActionStatus.IDLE },
      };
    },
    fetchCustomers: (state, _: PayloadAction<GetCustomersOptions | undefined>): RequestState => {
      return {
        ...state,
        customerState: { status: ActionStatus.PROCESSING },
      };
    },
    fetchCustomersSucceeded: (state, action: PayloadAction<Customer[]>): RequestState => {
      return {
        ...state,
        customers:
          action.payload.length === 0
            ? state.customers
            : customerAdapter.upsertMany({ ...state.customers }, action.payload),
        customerState: { status: ActionStatus.SUCCEEDED },
      };
    },
    fetchCustomersFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        customerState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    fetchCustomer: (state, _: PayloadAction<{ id: string }>): RequestState => {
      return {
        ...state,
        customerState: { status: ActionStatus.PROCESSING },
      };
    },
    fetchCustomerSucceeded: (state, action: PayloadAction<Customer>): RequestState => {
      return {
        ...state,
        customers: customerAdapter.upsertOne({ ...state.customers }, action.payload),
        customerState: { status: ActionStatus.SUCCEEDED },
      };
    },
    fetchCustomerFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        customerState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    fetchConfigs: (state): RequestState => {
      return {
        ...state,
        configState: { status: ActionStatus.PROCESSING },
      };
    },
    fetchAllConfigs: (state, _: PayloadAction<FetchAllConfigs>): RequestState => {
      return {
        ...state,
        configState: { status: ActionStatus.PROCESSING },
      };
    },
    fetchConfigsSucceeded: (state, action: PayloadAction<Config[]>): RequestState => {
      return {
        ...state,
        configs: configAdapter.setAll({ ...state.configs }, action.payload),
        configState: { status: ActionStatus.SUCCEEDED },
      };
    },
    fetchConfigsFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        configState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    updateSettingsConfig: (state, action: PayloadAction<Config>): RequestState => {
      return {
        ...state,
        configs: configAdapter.setOne({ ...state.configs }, action.payload),
        configState: { status: ActionStatus.PROCESSING },
      };
    },
    updateSettingsConfigSucceeded: (state, _: PayloadAction<Config>): RequestState => {
      return {
        ...state,
        configState: { status: ActionStatus.SUCCEEDED },
      };
    },
    updateSettingsConfigFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        configState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    createNewRequest: (state, _: PayloadAction<CreateRequest>): RequestState => {
      return { ...state, createRequestState: { status: ActionStatus.PROCESSING } };
    },
    cancelCreateNewRequest: (state): RequestState => {
      return { ...state, createRequestState: { status: ActionStatus.IDLE } };
    },
    createNewRequestSucceeded: (state, _: PayloadAction<IDed>): RequestState => {
      return {
        ...state,
        createRequestState: { status: ActionStatus.SUCCEEDED },
      };
    },
    createNewRequestFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        createRequestState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    startNewSession: (state, action: PayloadAction<StartSession>): RequestState => {
      return { ...state, startSessionArgs: action.payload, startSessionState: { status: ActionStatus.PROCESSING } };
    },
    clearSessionExecutionCommand: (state): RequestState => {
      return { ...state, startSessionArgs: undefined, startedSession: undefined };
    },
    startNewSessionSucceeded: (state, action: PayloadAction<Session>): RequestState => {
      return {
        ...state,
        startedSession: action.payload,
        startSessionState: { status: ActionStatus.SUCCEEDED },
      };
    },
    startNewSessionFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        startSessionState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    closeSession: (state, _: PayloadAction<string>): RequestState => {
      return { ...state, closeSessionState: { status: ActionStatus.PROCESSING } };
    },
    closeSessionSucceeded: (state, action: PayloadAction<Request>): RequestState => {
      return {
        ...normalizeRequestState(state, action.payload),
        closeSessionState: { status: ActionStatus.SUCCEEDED },
      };
    },
    closeSessionFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        closeSessionState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    acceptOrRejectApproval: (state, _: PayloadAction<AcceptOrRejectApproval>): RequestState => {
      return { ...state, acceptOrRejectApproval: { status: ActionStatus.PROCESSING } };
    },
    acceptOrRejectApprovalSucceeded: (
      state,
      _: PayloadAction<{ requestId: string; filter: AcceptOrRejectApproval }>
    ): RequestState => {
      return {
        ...state,
        acceptOrRejectApproval: { status: ActionStatus.SUCCEEDED },
      };
    },
    acceptOrRejectApprovalFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        acceptOrRejectApproval: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    calculateRequestAccessLevelConfig: (state, _: PayloadAction<CreateRequest>): RequestState => {
      return {
        ...state,
        calculateRequestAccessLevelConfigState: { status: ActionStatus.PROCESSING },
      };
    },
    calculateRequestAccessLevelConfigSucceeded: (
      state,
      action: PayloadAction<RequestAccessLevelConfig>
    ): RequestState => {
      return {
        ...state,
        requestAccessLevelConfig: action.payload,
        calculateRequestAccessLevelConfigState: { status: ActionStatus.SUCCEEDED },
      };
    },
    calculateRequestAccessLevelConfigFailed: (state, action: PayloadAction<Error>): RequestState => {
      return {
        ...state,
        requestAccessLevelConfig: undefined,
        calculateRequestAccessLevelConfigState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    clearRequestAccessLevelConfig: (state): RequestState => {
      return {
        ...state,
        requestAccessLevelConfig: undefined,
        calculateRequestAccessLevelConfigState: { status: ActionStatus.IDLE },
      };
    },
    clearRequestState: (): RequestState => {
      return initialState;
    },
  },
});

export default RequestSlice;
