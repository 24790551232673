export enum IApprovalLevel {
  AutoApprovalLevel = 0,
  OtherLevel = 10,
  TeamLevel = 20,
  OrgLevel = 30,
  ManagerLevel = 40,
  ExecLevel = 50,
}

export enum IApprovalLevelStrings {
  AUTO_APPROVAL_LEVEL = 0,
  ANY_LEVEL = 10,
  TEAM_LEVEL = 20,
  ORG_LEVEL = 30,
  MANAGER_LEVEL = 40,
  EXEC_LEVEL = 50,
}

export default IApprovalLevel;
