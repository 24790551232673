import { Link } from '@tanium/react-link';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelpModal from 'components/app_header/HelpModal';
import SmallLightText from 'components/styles/text/SmallLightText';

export enum Tabs {
  FAQ = 'faq',
  VERSION = 'version',
  REQUIRED_SOFTWARE = 'required_software',
}

const HelpLink: React.VFC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(Tabs.FAQ);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Link onClick={() => openModal()}>
        <SmallLightText>{t('HELP')}</SmallLightText>
      </Link>
      <HelpModal isOpen={isModalOpen} closeModal={closeModal} tab={tab} setTab={setTab} />
    </>
  );
};

export default HelpLink;
