import { from, of } from 'rxjs';
import { mergeMap, catchError, map, filter } from 'rxjs/operators';
import i18next from 'i18next';
import {
  describeStepFunctionExecution,
  listAccessRequestExecutions,
  listChangeRequestExecutions,
} from 'gql/Executions';
import RootEpic from 'store/RootEpic';
import { showNotificationFromError } from 'components/app/store/UserSlice';
import StepFunctionExecutionSlice from './StepFunctionExecutionSlice';

export const fetchAccessRequestExecutionListEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(StepFunctionExecutionSlice.actions.fetchAccessRequestExecutionList.match),
    mergeMap((action) =>
      from(listAccessRequestExecutions(action.payload)).pipe(
        map((response) => StepFunctionExecutionSlice.actions.fetchAccessRequestExecutionListSucceeded(response)),
        catchError((error) => of(StepFunctionExecutionSlice.actions.fetchAccessRequestExecutionListFailed(error)))
      )
    )
  );

export const fetchAccessRequestExecutionListFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(StepFunctionExecutionSlice.actions.fetchAccessRequestExecutionListFailed.match),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_TO_GET_STEP_FUNCTION_EXECUTIONS'), err: payload })
    )
  );

export const fetchChangeRequestExecutionListEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(StepFunctionExecutionSlice.actions.fetchChangeRequestExecutionList.match),
    mergeMap((action) =>
      from(listChangeRequestExecutions(action.payload)).pipe(
        map((response) => StepFunctionExecutionSlice.actions.fetchChangeRequestExecutionListSucceeded(response)),
        catchError((error) => of(StepFunctionExecutionSlice.actions.fetchChangeRequestExecutionListFailed(error)))
      )
    )
  );

export const fetchChangeRequestExecutionListFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(StepFunctionExecutionSlice.actions.fetchChangeRequestExecutionListFailed.match),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_TO_GET_STEP_FUNCTION_EXECUTIONS'), err: payload })
    )
  );

export const fetchDescribeStepFunctionExecutionEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(StepFunctionExecutionSlice.actions.fetchDescribeStepFunctionExecution.match),
    mergeMap((action) =>
      from(describeStepFunctionExecution(action.payload)).pipe(
        map((response) => StepFunctionExecutionSlice.actions.fetchDescribeStepFunctionExecutionSucceeded(response)),
        catchError((error) => of(StepFunctionExecutionSlice.actions.fetchDescribeStepFunctionExecutionFailed(error)))
      )
    )
  );

export const fetchDescribeStepFunctionExecutionFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(StepFunctionExecutionSlice.actions.fetchDescribeStepFunctionExecutionFailed.match),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_TO_GET_STEP_FUNCTION_EXECUTION_DATA'), err: payload })
    )
  );

const StepFunctionExecutionEpics: RootEpic[] = [
  fetchAccessRequestExecutionListEpic,
  fetchAccessRequestExecutionListFailedEpic,
  fetchChangeRequestExecutionListEpic,
  fetchChangeRequestExecutionListFailedEpic,
  fetchDescribeStepFunctionExecutionEpic,
  fetchDescribeStepFunctionExecutionFailedEpic,
];

export default StepFunctionExecutionEpics;
