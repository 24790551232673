import { mergeMap, catchError, map, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { ofType } from 'redux-observable';
import i18next from 'i18next';
import { showNotificationFromError, showNotification } from 'components/app/store/UserSlice';

import RootEpic from 'store/RootEpic';
import { execRestartInstance } from 'gql/RestartInstance';
import RestartInstanceSlice from './RestartInstanceSlice';

export const restartInstanceEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(RestartInstanceSlice.actions.restartInstance),
    mergeMap(({ payload }) =>
      from(
        execRestartInstance({
          requestID: payload.requestID,
          instanceID: payload.instanceID,
        })
      ).pipe(
        concatMap((response) =>
          of(
            showNotification({
              type: 'success',
              contents: i18next.t('RESTART_INSTANCE_SUCCESS', {
                instance: payload.instanceID,
              }),
            }),
            RestartInstanceSlice.actions.restartInstanceSuccess(response),
            RestartInstanceSlice.actions.resetRestartInstanceState()
          )
        ),
        catchError((error) => of(RestartInstanceSlice.actions.restartInstanceFail(error)))
      )
    )
  );

export const restartInstanceFailEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(RestartInstanceSlice.actions.restartInstanceFail),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('RESTART_INSTANCE_FAIL'), err: payload }))
  );

const RestartInstanceEpics: RootEpic[] = [restartInstanceEpic, restartInstanceFailEpic];

export default RestartInstanceEpics;
