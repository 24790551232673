export const DEFAULT_DELAY = 100;
export const DEFAULT_MAX_DELAY = 10000;
export const DEFAULT_RETRIES = 3;

export interface RetryOptions {
  delay: number;
  maxDelay: number;
  retries: number;
}

export const DefaultRetryOptions: RetryOptions = {
  delay: DEFAULT_DELAY,
  maxDelay: DEFAULT_MAX_DELAY,
  retries: DEFAULT_RETRIES,
};

const delay = (duration: number): Promise<undefined> => {
  return new Promise((resolve) => setTimeout(resolve, duration));
};

const hasMoreTries = (attempt: number, maxTries: number): boolean => {
  return attempt + 1 <= maxTries;
};

/**
 * Wrap a function with configurable retry functionality
 * @param fn Function to execute and retry
 * @param shouldRetry Determines whether or not to retry the function after a failure
 * @param retryOptions Configures how the function is retried
 * @returns Result of the function
 */
export const retryAsync = async <T>(
  fn: () => Promise<T>,
  shouldRetry: (error: Error) => boolean,
  retryOptions?: Partial<RetryOptions>
): Promise<T> => {
  const options = { ...DefaultRetryOptions, ...retryOptions };
  let lastError: unknown;

  for (let i = 0; i < options.retries + 1; i++) {
    try {
      // eslint-disable-next-line no-await-in-loop
      return await fn();
    } catch (e: unknown) {
      lastError = e;
    }

    if (!hasMoreTries(i, options.retries) || !shouldRetry(lastError as Error)) {
      break;
    }

    // eslint-disable-next-line no-await-in-loop
    await delay(Math.min(options.maxDelay, Math.pow(2, i) * options.delay));
  }

  throw lastError;
};
