import Helper from 'utils/Helper';
import { IDed } from 'gql/Request';

export default interface SessionLog extends IDed {
  command: string;
  output: string[];
  eventTime: string;
  targetId: string;
  error: string | null;
}

export interface SessionLogResponse extends IDed {
  sessionLogs: SessionLog[];
  nextToken: string;
}

/**
 * Return the details for a session log event
 */
export const SESSION_LOG_EVENT = `{
  command
  output
  eventTime
  targetId
  error
}`;

/**
 * Return the details for a session log response
 */
export const SESSION_LOG_RESPONSE = `{
  nextToken
  sessionLogs ${SESSION_LOG_EVENT}
}`;

/**
 * Return all session logs for a given request
 */
const GET_SESSION_LOGS = `
    query sessionLogs($requestID: String!, $nextToken: String) {
      sessionLogs (requestID: $requestID, nextToken: $nextToken) ${SESSION_LOG_RESPONSE}
    }
  `;

/**
 * Calls API for GET_SESSION_LOGS
 * @returns Promise<SessionLogResponse>
 */
export const getSessionLogs = async (requestID: string): Promise<SessionLogResponse> => {
  const finalResponse: SessionLogResponse = {
    id: '',
    sessionLogs: [],
    nextToken: 'default',
  };

  while (finalResponse.nextToken !== null && finalResponse.nextToken !== undefined) {
    // eslint-disable-next-line no-await-in-loop
    const result = await Helper.execGQL<SessionLogResponse>({
      query: GET_SESSION_LOGS,
      variables: {
        requestID,
        nextToken: finalResponse.nextToken !== 'default' ? finalResponse.nextToken : undefined,
      },
    });

    finalResponse.sessionLogs.push(...result.sessionLogs.sessionLogs);
    finalResponse.nextToken = result.sessionLogs.nextToken;
  }

  return finalResponse;
};
