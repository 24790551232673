import { DefinitionListItem } from '@tanium/react-definition-list';
import MediumText from 'components/styles/text/MediumText';
import MediumBoldText from 'components/styles/text/MediumBoldText';

type MediumDefinitionListItemProps = {
  term: string;
  description: JSX.Element | string | undefined;
};

const MediumDefinitionListItem: React.VFC<MediumDefinitionListItemProps> = ({ term, description }) => {
  return (
    <DefinitionListItem
      term={<MediumText>{term}</MediumText>}
      description={<MediumBoldText>{description}</MediumBoldText>}
    />
  );
};

export default MediumDefinitionListItem;
