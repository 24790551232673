import { Link } from '@tanium/react-link';
import { useTranslation } from 'react-i18next';
import SmallLightText from 'components/styles/text/SmallLightText';
import { ADMIN_PANEL_ROUTE } from 'Route';

const AdminPanelLink: React.VFC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Link href={ADMIN_PANEL_ROUTE.url}>
        <SmallLightText>{t('ADMIN_PANEL')}</SmallLightText>
      </Link>
    </>
  );
};

export default AdminPanelLink;
