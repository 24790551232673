import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import RestartWorkflowRequest from 'gql/Workflow';
import ActionState, { ActionStatus } from 'store/ActionState';

export type RestartWorkflowRequestState = {
  // These are collections of normalized objects
  workflow?: RestartWorkflowRequest;
  workflowState: ActionState;
};

export const initialState: RestartWorkflowRequestState = {
  workflow: undefined,
  workflowState: { status: ActionStatus.IDLE },
};

const WorkflowSlice = createSlice({
  name: 'Workflow',
  initialState,
  reducers: {
    restartWorkflow: (state, action: PayloadAction<RestartWorkflowRequest>): RestartWorkflowRequestState => {
      return {
        ...state,
        workflow: action.payload,
        workflowState: { status: ActionStatus.PROCESSING },
      };
    },
    restartWorkflowSucceeded: (state, _: PayloadAction<RestartWorkflowRequest>): RestartWorkflowRequestState => {
      return {
        ...state,
        workflowState: { status: ActionStatus.SUCCEEDED },
      };
    },
    restartWorkflowFailed: (state, action: PayloadAction<Error>): RestartWorkflowRequestState => {
      return {
        ...state,
        workflowState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
  },
});

export default WorkflowSlice;
