import Helper from 'utils/Helper';
import { IDed, RequestAccessInput } from 'gql/Request';
import ISessionInterface from 'common/ISessionInterface';

export default interface Session extends IDed {
  requestId: string;
  signInURL?: string;
}

export const SESSION = `
{
  id
  requestId
  signInURL
}`;

const GENERATE_SESSION = `
  mutation startSession($requestId: String!, $sessionInterface: SessionInterface!, $access: RequestAccessInput!, $instanceID: String, $signIn: Boolean) {
    startSession (requestId: $requestId, sessionInterface: $sessionInterface, access: $access, instanceID: $instanceID, signIn: $signIn) ${SESSION}
  }
`;

export interface StartSession {
  requestId: string;
  sessionInterface: ISessionInterface;
  access: RequestAccessInput;
  instanceID: string;
  signIn: boolean;
}

export const startSession = async (variables: StartSession): Promise<Session> => {
  const result = await Helper.execGQL<Session>({
    query: GENERATE_SESSION,
    variables,
  });
  return result.startSession;
};
