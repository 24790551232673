import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';

const AbsoluteCenteredDiv = styled('div')(
  css({
    position: 'absolute',
    margin: 'auto',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  })
);

export default AbsoluteCenteredDiv;
