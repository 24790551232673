import i18next from 'i18next';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { updateSSMParameter } from 'gql/SsmParameter';
import RootEpic from 'store/RootEpic';
import SSMParameterSlice from 'components/pages/adminPanel/store/ssmParameters/SSMParameterSlice';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';

export const updateSSMParameterEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(SSMParameterSlice.actions.updateSSMParameter.match),
    mergeMap(({ payload }) => {
      return from(updateSSMParameter(payload)).pipe(
        map((response) => SSMParameterSlice.actions.updateSSMParameterSucceeded(response)),
        catchError((error) => of(SSMParameterSlice.actions.updateSSMParameterFailed(error)))
      );
    })
  );

export const updateSSMParameterSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(SSMParameterSlice.actions.updateSSMParameterSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_UPDATED_SSM_PARAMETER', action.payload.name),
        }),
      ];
    })
  );

export const updateSSMParameterFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(SSMParameterSlice.actions.updateSSMParameterFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_UPDATE_SSM_PARAMETER'), err: payload });
    })
  );

const SSMParameterEpics: RootEpic[] = [
  updateSSMParameterEpic,
  updateSSMParameterSuccessEpic,
  updateSSMParameterFailedEpic,
];

export default SSMParameterEpics;
