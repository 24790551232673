import { from, of } from 'rxjs';
import { catchError, concatAll, filter, map, mergeMap } from 'rxjs/operators';
import i18next from 'i18next';
import { PayloadAction } from '@reduxjs/toolkit';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';
import RootEpic from 'store/RootEpic';
import {
  ListHostTargetAccessOptions,
  createHostTargetAccess,
  deleteHostTargetAccess,
  getHostTargetAccess,
  listHostTargetAccess,
  updateHostTargetAccess,
} from 'gql/HostTargetAccess';
import { PermissionAction, PermissionResource, hasPermission } from 'gql/Permission';
import HostTargetAccessSlice from './HostTargetAccessSlice';

// GET
export const getHostTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.getHostTargetAccessByID.match),
    mergeMap(({ payload }) => {
      return from(getHostTargetAccess(payload)).pipe(
        map((response) => HostTargetAccessSlice.actions.getHostTargetAccessByIDSucceeded(response)),
        catchError((error) => {
          return of(HostTargetAccessSlice.actions.getHostTargetAccessByIDFailed(error));
        })
      );
    })
  );

export const getHostTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.getHostTargetAccessByIDFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_HOST_TARGET_ACCESS'), err: payload }))
  );

// LIST
export const listHostTargetAccessEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(() =>
      hasPermission(state$.value.user.user?.permissions, PermissionAction.READ, PermissionResource.HOST_TARGET_ACCESS)
    ),
    filter(
      (action): action is PayloadAction<ListHostTargetAccessOptions> =>
        HostTargetAccessSlice.actions.listHostTargetAccessForTargetOrGroup.match(action) ||
        HostTargetAccessSlice.actions.listNextPageHostTargetAccessForTargetOrGroup.match(action)
    ),
    mergeMap(({ payload }) => {
      return from(listHostTargetAccess(payload)).pipe(
        map((response) => {
          return response.nextToken
            ? [
                HostTargetAccessSlice.actions.listHostTargetAccessForTargetOrGroupSucceeded(response.items),
                HostTargetAccessSlice.actions.listNextPageHostTargetAccessForTargetOrGroup({
                  includeAllTargetGroups: payload.includeAllTargetGroups,
                  targetOrTargetGroupID: payload.targetOrTargetGroupID,
                  userADGroups: payload.userADGroups,
                  nextToken: response.nextToken,
                  size: payload.size,
                }),
              ]
            : [HostTargetAccessSlice.actions.listHostTargetAccessForTargetOrGroupSucceeded(response.items)];
        }),
        concatAll(),
        catchError((error) => {
          return of(HostTargetAccessSlice.actions.listHostTargetAccessForTargetOrGroupFailed(error));
        })
      );
    })
  );

export const listHostTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.listHostTargetAccessForTargetOrGroupFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_HOST_TARGET_ACCESS'), err: payload }))
  );

// CREATE
export const createHostTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.createHostTargetAccess.match),
    mergeMap(({ payload }) =>
      from(createHostTargetAccess(payload)).pipe(
        map((response) => HostTargetAccessSlice.actions.createHostTargetAccessSucceeded(response)),
        catchError((error) => of(HostTargetAccessSlice.actions.createHostTargetAccessFailed(error)))
      )
    )
  );

export const createHostTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.createHostTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_CREATED_HOST_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const createHostTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.createHostTargetAccessFailed.match),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_CREATE_HOST_TARGET_ACCESS'), err: payload })
    )
  );

// UPDATE
export const updateHostTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.updateHostTargetAccess.match),
    mergeMap(({ payload }) => {
      return from(updateHostTargetAccess(payload)).pipe(
        map((response) => HostTargetAccessSlice.actions.updateHostTargetAccessSucceeded(response)),
        catchError((error) => of(HostTargetAccessSlice.actions.updateHostTargetAccessFailed(error)))
      );
    })
  );

export const updateHostTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.updateHostTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_UPDATED_HOST_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const updateHostTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.updateHostTargetAccessFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_UPDATE_HOST_TARGET_ACCESS'), err: payload });
    })
  );

// DELETE
export const deleteHostTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.deleteHostTargetAccess.match),
    mergeMap(({ payload }) => {
      return from(deleteHostTargetAccess(payload)).pipe(
        map((response) => HostTargetAccessSlice.actions.deleteHostTargetAccessSucceeded(response)),
        catchError((error) => of(HostTargetAccessSlice.actions.deleteHostTargetAccessFailed(error)))
      );
    })
  );

export const deleteHostTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.deleteHostTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_DELETED_HOST_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const deleteHostTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(HostTargetAccessSlice.actions.deleteHostTargetAccessFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_DELETE_HOST_TARGET_ACCESS'), err: payload });
    })
  );

const HostTargetAccessEpics: RootEpic[] = [
  getHostTargetAccessEpic,
  getHostTargetAccessFailedEpic,
  listHostTargetAccessEpic,
  listHostTargetAccessFailedEpic,
  createHostTargetAccessEpic,
  createHostTargetAccessSuccessEpic,
  createHostTargetAccessFailedEpic,
  updateHostTargetAccessEpic,
  updateHostTargetAccessSuccessEpic,
  updateHostTargetAccessFailedEpic,
  deleteHostTargetAccessEpic,
  deleteHostTargetAccessFailedEpic,
  deleteHostTargetAccessSuccessEpic,
];

export default HostTargetAccessEpics;
