import { mergeMap, catchError, map } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import i18next from 'i18next';
import { getClusters } from 'gql/KubernetesCluster';
import RootEpic from 'store/RootEpic';
import KubernetesClusterSlice from 'components/pages/console/store/KubernetesClusterSlice';
import { showNotificationFromError } from 'components/app/store/UserSlice';

export const fetchClustersEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(KubernetesClusterSlice.actions.fetchClusters),
    mergeMap(({ payload }) =>
      from(getClusters(payload)).pipe(
        map((response) => KubernetesClusterSlice.actions.fetchClustersSucceeded(response)),
        catchError((error) => of(KubernetesClusterSlice.actions.fetchClustersFailed(error)))
      )
    )
  );

export const fetchClustersFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(KubernetesClusterSlice.actions.fetchClustersFailed),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_CLUSTERS'), err: payload }))
  );

const KubernetesClusterEpics: RootEpic[] = [fetchClustersEpic, fetchClustersFailedEpic];

export default KubernetesClusterEpics;
