import { mergeMap, catchError, map, delay, mapTo } from 'rxjs/operators';
import { from, iif, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import { getUser } from 'gql/User';
import UserSlice from 'components/app/store/UserSlice';
import RootEpic from 'store/RootEpic';
import { NOT_AUTHORIZED_ROUTE } from 'Route';
import { isLockboxRole } from 'common/IUserRole';

export const notificationEpic: RootEpic = (action$) =>
  action$.pipe(ofType(UserSlice.actions.showNotification), delay(100), mapTo(UserSlice.actions.clearNotification()));

export const fetchUserEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(UserSlice.actions.fetchUser),
    mergeMap(() =>
      from(getUser()).pipe(
        map((response) => UserSlice.actions.fetchUserSucceeded(response)),
        catchError((error) => of(UserSlice.actions.fetchUserFailed(error)))
      )
    )
  );

// On user fetch, check their roles and show an unauthorized page if they don't have a base role
//  Note - this is just for user clarity, lockbox backend does all the actual authentication
export const fetchUserSucceededEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(UserSlice.actions.fetchUserSucceeded),
    mergeMap(({ payload }) =>
      iif(() => payload.roles.some((role: string) => isLockboxRole(role)), of(), of(push(NOT_AUTHORIZED_ROUTE.url)))
    )
  );

export const fetchUserFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(UserSlice.actions.fetchUserFailed),
    map(({ payload }) =>
      UserSlice.actions.showNotificationFromError({ msg: i18next.t('FAILED_GET_USER'), err: payload })
    )
  );

const UserEpics: RootEpic[] = [notificationEpic, fetchUserEpic, fetchUserSucceededEpic, fetchUserFailedEpic];

export default UserEpics;
