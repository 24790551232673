import styled from '@tanium/react-emotion-9';
import { ThemeColors } from '@tanium/theme-data';
import { css } from '@tanium/style-system';
import { Box } from '@tanium/react-box';

export const AppHeaderStyle = {
  borderHeight: 5,
  containerHeight: 50,
  marginHeight: 10,
};

export const BreadcrumbHeight = { containerHeight: 52, bottomPadding: 52 };

export const TabNavigationHeight = { containerHeight: 52, filterHeight: 52 };

export const StackedListHeight = {
  counterHeight: 35,
};

export const StaticPageTop =
  AppHeaderStyle.borderHeight +
  AppHeaderStyle.containerHeight +
  AppHeaderStyle.marginHeight +
  BreadcrumbHeight.containerHeight +
  BreadcrumbHeight.bottomPadding +
  TabNavigationHeight.containerHeight +
  TabNavigationHeight.filterHeight +
  StackedListHeight.counterHeight;

const ContainerBox = styled(Box)(
  css({
    bg: ThemeColors.HeaderTier2,
    height: `${AppHeaderStyle.containerHeight}px`,
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
  })
);

export default ContainerBox;
