import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import ActionState, { ActionStatus } from 'store/ActionState';
import { PresignedUrlRequest, PresignedUrlResponse, UploadFileToInstanceRequest } from 'gql/FileUpload';
import { IDed } from 'gql/Request';

export type FileUploadState = {
  presignedUrl?: PresignedUrlResponse;
  presignedUrlState?: ActionState;
  fileUploadState?: ActionState;
  uploadFileToInstanceState?: ActionState;
};

export const initialState: FileUploadState = {
  presignedUrl: undefined,
  fileUploadState: { status: ActionStatus.IDLE },
  presignedUrlState: { status: ActionStatus.IDLE },
  uploadFileToInstanceState: { status: ActionStatus.IDLE },
};

const FileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState,
  reducers: {
    // Presigned URL
    fetchPresignedUrl: (state, _: PayloadAction<PresignedUrlRequest>) => {
      return { ...state, presignedUrlState: { status: ActionStatus.PROCESSING } };
    },
    fetchPresignedUrlSuccess: (state, action: PayloadAction<PresignedUrlResponse>) => {
      return {
        ...state,
        presignedUrl: action.payload,
        presignedUrlState: { status: ActionStatus.SUCCEEDED },
      };
    },
    fetchPresignedUrlFail: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        presignedUrlState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // File Upload
    fileUpload: (state, _: PayloadAction<{ fileToUpload: File; presignedUrl: string }>) => {
      return {
        ...state,
        fileUploadState: { status: ActionStatus.PROCESSING },
      };
    },
    fileUploadFail: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        fileUploadState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    fileUploadSuccess: (state, _: PayloadAction<AxiosResponse>) => {
      return {
        ...state,
        fileUploadState: { status: ActionStatus.SUCCEEDED },
      };
    },

    // File link to instance
    uploadFileToInstance: (state, _: PayloadAction<UploadFileToInstanceRequest>) => {
      return {
        ...state,
        uploadFileToInstanceState: { status: ActionStatus.PROCESSING },
      };
    },

    uploadFileToInstanceFail: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        uploadFileToInstanceState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    uploadFileToInstanceSuccess: (state, _: PayloadAction<IDed>) => {
      return {
        ...state,
        uploadFileToInstanceState: { status: ActionStatus.SUCCEEDED },
      };
    },

    // Reset all states
    resetFileUploadState: () => {
      return initialState;
    },
  },
});

export const {
  fetchPresignedUrl,
  fetchPresignedUrlSuccess,
  fetchPresignedUrlFail,
  fileUploadFail,
  fileUploadSuccess,
  uploadFileToInstance,
  uploadFileToInstanceFail,
  uploadFileToInstanceSuccess,
  resetFileUploadState,
} = FileUploadSlice.actions;

export default FileUploadSlice;
