import { mergeMap, catchError, map } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { ofType } from 'redux-observable';
import i18next from 'i18next';
import DependencyArtifactsSlice from 'components/app/store/dependencyArtifacts/DependencyArtifactsSlice';
import { showNotificationFromError } from 'components/app/store/UserSlice';

import RootEpic from 'store/RootEpic';
import { getDependencyArtifacts } from 'gql/DependencyArtifacts';

export const fetchDependencyArtifacts: RootEpic = (action$) =>
  action$.pipe(
    ofType(DependencyArtifactsSlice.actions.fetchDependencyArtifacts),
    mergeMap(({ payload }) =>
      from(getDependencyArtifacts(payload)).pipe(
        map((response) => {
          return DependencyArtifactsSlice.actions.fetchDependencyArtifactsSuccess(response);
        }),
        catchError((error) => {
          return of(DependencyArtifactsSlice.actions.fetchDependencyArtifactsFail(error));
        })
      )
    )
  );

export const fetchDependencyArtifactsFailEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(DependencyArtifactsSlice.actions.fetchDependencyArtifactsFail),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_DEPENDENCY_ARTIFACTS'), err: payload }))
  );

const DependencyArtifactsEpics: RootEpic[] = [fetchDependencyArtifacts, fetchDependencyArtifactsFailEpic];

export default DependencyArtifactsEpics;
