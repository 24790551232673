import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChangeRequest } from 'gql/ChangeRequest';
import ActionState, { ActionStatus } from 'store/ActionState';
import { IDed } from 'gql/Request';

export type ChangeRequestState = {
  changeRequest?: ChangeRequest;
  loadingState: ActionState;
  mutationState: ActionState;
  saveForm?: ChangeRequest;
  mutatedChangeRequestId?: string;
};

export const changeRequestAdapter = createEntityAdapter<ChangeRequest>();

export const initialState: ChangeRequestState = {
  loadingState: { status: ActionStatus.IDLE },
  mutationState: { status: ActionStatus.IDLE },
};

const ChangeRequestSlice = createSlice({
  name: 'changeRequest',
  initialState,
  reducers: {
    // GET
    getChangeRequest: (state, _: PayloadAction<string>): ChangeRequestState => {
      return { ...state, loadingState: { status: ActionStatus.PROCESSING } };
    },
    getChangeRequestSucceeded: (state, action: PayloadAction<ChangeRequest>): ChangeRequestState => {
      return {
        ...state,
        changeRequest: action.payload,
        loadingState: { status: ActionStatus.SUCCEEDED },
      };
    },
    getChangeRequestFailed: (state, action: PayloadAction<Error>): ChangeRequestState => {
      return { ...state, loadingState: { status: ActionStatus.FAILED, error: action.payload } };
    },

    // CREATE
    createChangeRequest: (state, action: PayloadAction<ChangeRequest>): ChangeRequestState => {
      return {
        ...state,
        saveForm: action.payload,
        mutationState: { status: ActionStatus.PROCESSING },
        mutatedChangeRequestId: undefined,
      };
    },
    createChangeRequestSucceeded: (state, action: PayloadAction<IDed>): ChangeRequestState => {
      return {
        ...state,
        saveForm: undefined,
        mutationState: { status: ActionStatus.SUCCEEDED },
        mutatedChangeRequestId: action.payload.id,
      };
    },
    createChangeRequestFailed: (state, action: PayloadAction<Error>): ChangeRequestState => {
      return {
        ...state,
        saveForm: undefined,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
  },
});

export default ChangeRequestSlice;
