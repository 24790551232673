import i18next from 'i18next';
import { Link } from '@tanium/react-link';
import React from 'react';
import { RequestTargetType } from 'gql/Request';
import Helper from 'utils/Helper';
import { AWS_ACCOUNT_ROUTE, SERVICE_ROUTE } from 'Route';

interface Target {
  targetType: RequestTargetType;
  targetName: string;
  targetId: string;
}

type MediumDefinitionListItemProps = {
  target: Target;
};

const TargetLink: React.VFC<MediumDefinitionListItemProps> = ({ target }) => {
  if (target.targetType === RequestTargetType.CustomerEnvironment) {
    return (
      <div className="truncated-text">
        {i18next.t(target.targetType)}: {target.targetName}
      </div>
    );
  }
  if (target.targetType === RequestTargetType.GlobalService) {
    return (
      <Link href={Helper.interpolateRoute(SERVICE_ROUTE.url, { serviceId: target.targetId })}>
        <div className="truncated-text">
          {i18next.t(target.targetType)}: {target.targetName}
        </div>
      </Link>
    );
  }
  if (target.targetType === RequestTargetType.AWSAccount) {
    return (
      <Link href={Helper.interpolateRoute(AWS_ACCOUNT_ROUTE.url, { awsAccountObjectId: target.targetId })}>
        <div className="truncated-text">
          {i18next.t(target.targetType)}: {target.targetName}
        </div>
      </Link>
    );
  }
  return (
    <div className="truncated-text">
      {i18next.t(target.targetType)}: {target.targetId}
    </div>
  );
};

export default TargetLink;
