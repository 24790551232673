import { SimpleTooltip, SimpleTooltipProps } from '@tanium/react-tooltip';
import React from 'react';

export interface Props extends SimpleTooltipProps {
  /**
   * Name of test ID. Defaults to tooltip
   */
  testId?: string;
  children: any;
}

const Tooltip = (props: Props): React.ReactElement => {
  return (
    <SimpleTooltip {...props}>
      {({ ref }): React.ReactElement => (
        <div ref={ref} data-testid={props?.testId}>
          {props.children}
        </div>
      )}
    </SimpleTooltip>
  );
};

export default Tooltip;
