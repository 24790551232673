import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import Text from 'components/styles/text/Text';

const MediumBoldText = styled(Text)(
  css({
    fontSize: 14,
    fontWeight: 'bold',
  })
);

export default MediumBoldText;
