import { push } from 'connected-react-router';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import i18next from 'i18next';
import RootEpic from 'store/RootEpic';
import ChangeRequestSlice from 'components/pages/console/store/ChangeRequestSlice';
import Helper from 'utils/Helper';
import { createChangeRequest, getChangeRequest } from 'gql/ChangeRequest';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';
import { TARGET_GROUP_ROUTE } from 'Route';

// GET
export const getChangeRequestEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(ChangeRequestSlice.actions.getChangeRequest.match),
    mergeMap(({ payload }) => {
      return from(getChangeRequest(payload)).pipe(
        map((response) => {
          return ChangeRequestSlice.actions.getChangeRequestSucceeded(response);
        }),
        catchError((error) => {
          return of(ChangeRequestSlice.actions.getChangeRequestFailed(error));
        })
      );
    })
  );

// CREATE
export const createChangeRequestEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(ChangeRequestSlice.actions.createChangeRequest.match),
    mergeMap(({ payload }) =>
      from(createChangeRequest(payload)).pipe(
        map((response) => ChangeRequestSlice.actions.createChangeRequestSucceeded(response)),
        catchError((error) => of(ChangeRequestSlice.actions.createChangeRequestFailed(error)))
      )
    )
  );

export const createChangeRequestSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(ChangeRequestSlice.actions.createChangeRequestSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({ type: 'success', contents: i18next.t('SUCCESS_CREATED_TARGET_GROUP', action.payload.id) }),
        push(Helper.interpolateRoute(TARGET_GROUP_ROUTE.url, { changeRequestId: action.payload.id })),
      ];
    })
  );

export const createChangeRequestFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(ChangeRequestSlice.actions.createChangeRequestFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_CREATE_TARGET_GROUP'), err: payload }))
  );

const ChangeRequestEpics: RootEpic[] = [
  getChangeRequestEpic,
  createChangeRequestEpic,
  createChangeRequestSuccessEpic,
  createChangeRequestFailedEpic,
];

export default ChangeRequestEpics;
