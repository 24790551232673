import { IDed } from 'gql/Request';
import { PaginationResult, SingletonResult } from 'gql/Common';
import Helper from 'utils/Helper';
import APIAccessType from 'common/IAPIAccessType';

export interface K8sRoleTargetAccess extends IDed, K8sRoleTargetAccessBase {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  id: string;
}
export interface K8sRoleTargetAccessBase {
  targetOrTargetGroupID: string;
  adGroups: string[];
  description: string;
  roleName: string;
  namespace: string;
  apiAccessType: APIAccessType;
}

export const K8S_ROLE_TARGET_ACCESS = `
{
  id
  createdBy
  createdAt
  updatedBy
  updatedAt
  description
  adGroups
  roleName
  namespace
  apiAccessType
  targetOrTargetGroupID
}`;

const LIST_K8S_ROLE_TARGET_ACCESS = `
  query listKubernetesRoleTargetAccess($filter: TargetBoundAccessFilterInput, $pagination: PaginationInput) {
    listKubernetesRoleTargetAccess (filter: $filter, pagination: $pagination) {
      items ${K8S_ROLE_TARGET_ACCESS}
      total
      nextToken
    }
  }
`;

export interface GetK8sRoleTargetAccessByIdOptions {
  id: string;
}

export interface ListK8sRoleTargetAccessOptions {
  targetOrTargetGroupID?: string;
  includeAllTargetGroups?: boolean;
  userADGroups?: string[];
  size?: number;
  nextToken?: string;
}

export const listK8sRoleTargetAccess = async (
  options?: ListK8sRoleTargetAccessOptions
): Promise<PaginationResult<K8sRoleTargetAccess>> => {
  const result = await Helper.execGQL<PaginationResult<K8sRoleTargetAccess>>({
    query: LIST_K8S_ROLE_TARGET_ACCESS,
    variables: {
      filter: {
        targetOrTargetGroupID: options?.targetOrTargetGroupID,
        includeAllTargetGroups: options?.includeAllTargetGroups,
        userADGroups: options?.userADGroups,
      },
      pagination: {
        size: options?.size,
        nextToken: options?.nextToken,
      },
    },
  });
  result.listKubernetesRoleTargetAccess.items.forEach(validateK8sTargetAccess);
  return result.listKubernetesRoleTargetAccess;
};

const GET_K8S_ROLE_TARGET_ACCESS = `
  query getKubernetesRoleTargetAccess($id: String!) {
    getKubernetesRoleTargetAccess(id: $id) {
      item ${K8S_ROLE_TARGET_ACCESS}
    }
  }`;

export const getK8sRoleTargetAccess = async (
  options: GetK8sRoleTargetAccessByIdOptions
): Promise<K8sRoleTargetAccess> => {
  const result = await Helper.execGQL<SingletonResult<K8sRoleTargetAccess>>({
    query: GET_K8S_ROLE_TARGET_ACCESS,
    variables: {
      id: options.id,
    },
  });
  if (result.getKubernetesRoleTargetAccess.item) {
    validateK8sTargetAccess(result.getKubernetesRoleTargetAccess.item);
  }
  return result.getKubernetesRoleTargetAccess.item;
};

const CREATE_K8S_ROLE_TARGET_ACCESS = `
  mutation createKubernetesRoleTargetAccess($create: CreateKubernetesRoleTargetAccessInput!) {
    createKubernetesRoleTargetAccess(create: $create) {
      item {
        id
      }
    } 
  }`;

export const createK8sRoleTargetAccess = async (targetAccess: K8sRoleTargetAccessBase): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<K8sRoleTargetAccess>>({
    query: CREATE_K8S_ROLE_TARGET_ACCESS,
    variables: {
      create: targetAccess,
    },
  });
  return result.createKubernetesRoleTargetAccess.item;
};

const UPDATE_K8S_ROLE_TARGET_ACCESS = `
  mutation updateKubernetesRoleTargetAccess($update: UpdateKubernetesRoleTargetAccessInput!) {
      updateKubernetesRoleTargetAccess(update: $update) {
          item { 
              id
          }
      }
  }`;

export const updateK8sRoleTargetAccess = async (targetAccess: K8sRoleTargetAccess): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<K8sRoleTargetAccess>>({
    query: UPDATE_K8S_ROLE_TARGET_ACCESS,
    variables: {
      update: targetAccess,
    },
  });
  return result.updateKubernetesRoleTargetAccess.item;
};

const DELETE_K8S_ROLE_TARGET_ACCESS = `
mutation deleteKubernetesRoleTargetAccess($id: String!) {
  deleteKubernetesRoleTargetAccess(id: $id) {
      item {
          id
      }
  }
} `;

export const deleteK8sRoleTargetAccess = async (id: string): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<K8sRoleTargetAccess>>({
    query: DELETE_K8S_ROLE_TARGET_ACCESS,
    variables: {
      id,
    },
  });
  return result.deleteKubernetesRoleTargetAccess.item;
};

const validateK8sTargetAccess = (access: K8sRoleTargetAccessBase) => {
  if (!(access.apiAccessType in APIAccessType)) {
    throw new Error('Unknown K8sAPIAccess type provided for K8sRoleTargetAccess');
  }
};
