import Helper from 'utils/Helper';
import { RequestTargetType } from './Request';

type RequestTargetTypeResponse = {
  types: RequestTargetType[];
};

const GET_TARGET_TYPES = `
    query availableTargetType {
      availableTargetTypes {
        types
      }
    }
  `;

/**
 * Calls API for GET_CONFIGS
 * @returns Promise<Config>
 */
export const getAvailableTargetTypes = async (): Promise<RequestTargetType[]> => {
  const result = await Helper.execGQL<RequestTargetTypeResponse>({
    query: GET_TARGET_TYPES,
  });
  return result.availableTargetTypes.types;
};

export const temp = '';
