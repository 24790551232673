import { mergeMap, catchError, map } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import i18next from 'i18next';
import { showNotificationFromError } from 'components/app/store/UserSlice';
import { getRequests } from 'gql/Request';
import RootEpic from 'store/RootEpic';
import ConsoleSlice from 'components/pages/console/store/ConsoleSlice';

export const fetchRequestsEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(ConsoleSlice.actions.fetchRequests),
    mergeMap(({ payload }) =>
      from(getRequests(payload)).pipe(
        map((response) => ConsoleSlice.actions.fetchRequestsSucceeded(response)),
        catchError((error) => of(ConsoleSlice.actions.fetchRequestsFailed(error)))
      )
    )
  );

export const fetchRequestsFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(ConsoleSlice.actions.fetchRequestsFailed),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_REQUESTS'), err: payload }))
  );

const ConsoleEpics: RootEpic[] = [fetchRequestsEpic, fetchRequestsFailedEpic];

export default ConsoleEpics;
