import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import SSMParameter from 'gql/SsmParameter';
import ActionState, { ActionStatus } from 'store/ActionState';

export type SSMParameterState = {
  // These are collections of normalized objects
  ssmParameter?: SSMParameter;
  ssmParameterState: ActionState;
};

export const initialState: SSMParameterState = {
  ssmParameter: undefined,
  ssmParameterState: { status: ActionStatus.IDLE },
};

const SSMParameterSlice = createSlice({
  name: 'SSM Parameter',
  initialState,
  reducers: {
    updateSSMParameter: (state, action: PayloadAction<SSMParameter>): SSMParameterState => {
      return {
        ...state,
        ssmParameter: action.payload,
        ssmParameterState: { status: ActionStatus.PROCESSING },
      };
    },
    updateSSMParameterSucceeded: (state, _: PayloadAction<SSMParameter>): SSMParameterState => {
      return {
        ...state,
        ssmParameterState: { status: ActionStatus.SUCCEEDED },
      };
    },
    updateSSMParameterFailed: (state, action: PayloadAction<Error>): SSMParameterState => {
      return {
        ...state,
        ssmParameterState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
  },
});

export default SSMParameterSlice;
