import { mergeMap, catchError, map } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import i18next from 'i18next';
import RootEpic from 'store/RootEpic';
import { showNotificationFromError } from 'components/app/store/UserSlice';
import { startSession } from 'gql/Session';
import SessionSlice from 'components/pages/request/store/session/SessionSlice';

export const fetchSignInURLEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(SessionSlice.actions.fetchSignInURL),
    mergeMap((action) => {
      return from(startSession(action.payload)).pipe(
        map((response) => SessionSlice.actions.fetchSignInURLSucceeded(response)),
        catchError((error) => of(SessionSlice.actions.fetchSignInURLFailed(error)))
      );
    })
  );

export const fetchSignInURLFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(SessionSlice.actions.fetchSignInURLFailed),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_TO_GET_SESSION_SIGN_IN_URL'), err: payload })
    )
  );

const SessionEpics: RootEpic[] = [fetchSignInURLEpic, fetchSignInURLFailedEpic];

export default SessionEpics;
