import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationType } from '@tanium/react-notifications-base';
import User from 'gql/User';
import ActionState, { ActionStatus } from 'store/ActionState';
import Helper from 'utils/Helper';

export interface Notification {
  /**
   * type of notification.
   */
  type: NotificationType;
  /**
   * content of notification.
   */
  contents: string;
}

export type ErrorNotification = {
  /**
   * Additional msg based on failure context
   */
  msg: string;

  /**
   * Originating error
   */
  err: Error;
};

export type UserState = {
  notification?: Notification;

  user?: User;
  userState: ActionState;
  signOut: boolean;
};

export const initialState: UserState = {
  user: undefined,
  userState: { status: ActionStatus.IDLE },
  signOut: false,
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearNotification: (state): UserState => {
      return { ...state, notification: undefined };
    },
    showNotification: (state, action: PayloadAction<Notification>): UserState => {
      return { ...state, notification: action.payload };
    },
    showNotificationFromError: (state, action: PayloadAction<ErrorNotification>): UserState => {
      const { msg, err } = action.payload;
      // To be replaced with a localized error code based system at some point
      const contents = Helper.humanReadableError(msg, err);
      return { ...state, notification: { type: 'error', contents } };
    },
    fetchUser: (state): UserState => {
      return { ...state, userState: { status: ActionStatus.PROCESSING }, signOut: false };
    },
    fetchUserSucceeded: (state, action: PayloadAction<User>): UserState => {
      return {
        ...state,
        user: action.payload,
        userState: { status: ActionStatus.SUCCEEDED },
      };
    },
    fetchUserFailed: (state, action: PayloadAction<Error>): UserState => {
      return {
        ...state,
        userState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    signOutUser: (state): UserState => {
      return {
        ...state,
        user: undefined,
        userState: { status: ActionStatus.IDLE },
        signOut: true,
      };
    },
  },
});

export const { showNotification, showNotificationFromError } = UserSlice.actions;

export default UserSlice;
