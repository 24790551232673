import { catchError, concatAll, filter, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import i18next from 'i18next';
import { PayloadAction } from '@reduxjs/toolkit';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';
import RootEpic from 'store/RootEpic';
import {
  ListAwsRoleTargetAccessOptions,
  createAwsRoleTargetAccess,
  deleteAwsRoleTargetAccess,
  getAwsRoleTargetAccess,
  listAwsRoleTargetAccess,
  updateAwsRoleTargetAccess,
} from 'gql/AwsRoleTargetAccess';
import { PermissionAction, PermissionResource, hasPermission } from 'gql/Permission';
import AwsRoleTargetAccessSlice from './AwsRoleTargetAccessSlice';

// GET
export const getAwsRoleTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.getAwsRoleTargetAccessByID.match),
    mergeMap(({ payload }) => {
      return from(getAwsRoleTargetAccess(payload)).pipe(
        map((response) => AwsRoleTargetAccessSlice.actions.getAwsRoleTargetAccessByIDSucceeded(response)),
        catchError((error) => {
          return of(AwsRoleTargetAccessSlice.actions.getAwsRoleTargetAccessByIDFailed(error));
        })
      );
    })
  );

export const getAwsRoleTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.getAwsRoleTargetAccessByIDFailed.match),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_GET_AWS_ROLE_TARGET_ACCESS'), err: payload })
    )
  );

// LIST
export const listAwsRoleTargetAccessEpic: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(() =>
      hasPermission(
        state$.value.user.user?.permissions,
        PermissionAction.READ,
        PermissionResource.AWS_ROLE_TARGET_ACCESS
      )
    ),
    filter(
      (action): action is PayloadAction<ListAwsRoleTargetAccessOptions> =>
        AwsRoleTargetAccessSlice.actions.listAwsRoleTargetAccessForTargetOrGroup.match(action) ||
        AwsRoleTargetAccessSlice.actions.listNextPageAwsRoleTargetAccessForTargetOrGroup.match(action)
    ),
    mergeMap(({ payload }) => {
      return from(listAwsRoleTargetAccess(payload)).pipe(
        map((response) => {
          return response.nextToken
            ? [
                AwsRoleTargetAccessSlice.actions.listAwsRoleTargetAccessForTargetOrGroupSucceeded(response.items),
                AwsRoleTargetAccessSlice.actions.listNextPageAwsRoleTargetAccessForTargetOrGroup({
                  includeAllTargetGroups: payload.includeAllTargetGroups,
                  targetOrTargetGroupID: payload.targetOrTargetGroupID,
                  userADGroups: payload.userADGroups,
                  nextToken: response.nextToken,
                  size: payload.size,
                }),
              ]
            : [AwsRoleTargetAccessSlice.actions.listAwsRoleTargetAccessForTargetOrGroupSucceeded(response.items)];
        }),
        concatAll(),
        catchError((error) => {
          return of(AwsRoleTargetAccessSlice.actions.listAwsRoleTargetAccessForTargetOrGroupFailed(error));
        })
      );
    })
  );
};

export const listAwsRoleTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.listAwsRoleTargetAccessForTargetOrGroupFailed.match),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_GET_AWS_ROLE_TARGET_ACCESS'), err: payload })
    )
  );

// CREATE
export const createAwsRoleTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.createAwsRoleTargetAccess.match),
    mergeMap(({ payload }) =>
      from(createAwsRoleTargetAccess(payload)).pipe(
        map((response) => AwsRoleTargetAccessSlice.actions.createAwsRoleTargetAccessSucceeded(response)),
        catchError((error) => of(AwsRoleTargetAccessSlice.actions.createAwsRoleTargetAccessFailed(error)))
      )
    )
  );

export const createAwsRoleTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.createAwsRoleTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_CREATED_AWS_ROLE_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const createAwsRoleTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.createAwsRoleTargetAccessFailed.match),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_CREATE_AWS_ROLE_TARGET_ACCESS'), err: payload })
    )
  );

// UPDATE
export const updateAwsRoleTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.updateAwsRoleTargetAccess.match),
    mergeMap(({ payload }) => {
      return from(updateAwsRoleTargetAccess(payload)).pipe(
        map((response) => AwsRoleTargetAccessSlice.actions.updateAwsRoleTargetAccessSucceeded(response)),
        catchError((error) => of(AwsRoleTargetAccessSlice.actions.updateAwsRoleTargetAccessFailed(error)))
      );
    })
  );

export const updateAwsRoleTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.updateAwsRoleTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_UPDATED_AWS_ROLE_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const updateAwsRoleTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.updateAwsRoleTargetAccessFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_UPDATE_AWS_ROLE_TARGET_ACCESS'), err: payload });
    })
  );

// DELETE
export const deleteAwsRoleTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.deleteAwsRoleTargetAccess.match),
    mergeMap(({ payload }) => {
      return from(deleteAwsRoleTargetAccess(payload)).pipe(
        map((response) => AwsRoleTargetAccessSlice.actions.deleteAwsRoleTargetAccessSucceeded(response)),
        catchError((error) => of(AwsRoleTargetAccessSlice.actions.deleteAwsRoleTargetAccessFailed(error)))
      );
    })
  );

export const deleteAwsRoleTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.deleteAwsRoleTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_DELETED_AWS_ROLE_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const deleteAwsRoleTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsRoleTargetAccessSlice.actions.deleteAwsRoleTargetAccessFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_DELETE_AWS_ROLE_TARGET_ACCESS'), err: payload });
    })
  );

const AwsRoleTargetAccessEpics: RootEpic[] = [
  getAwsRoleTargetAccessEpic,
  getAwsRoleTargetAccessFailedEpic,
  listAwsRoleTargetAccessEpic,
  listAwsRoleTargetAccessFailedEpic,
  createAwsRoleTargetAccessEpic,
  createAwsRoleTargetAccessSuccessEpic,
  createAwsRoleTargetAccessFailedEpic,
  updateAwsRoleTargetAccessEpic,
  updateAwsRoleTargetAccessSuccessEpic,
  updateAwsRoleTargetAccessFailedEpic,
  deleteAwsRoleTargetAccessEpic,
  deleteAwsRoleTargetAccessFailedEpic,
  deleteAwsRoleTargetAccessSuccessEpic,
];

export default AwsRoleTargetAccessEpics;
