import User from 'gql/User';

// Mirroring permissions granted here: https://git.corp.tanium.com/tanium-cloud/tss-lockbox-handler/blob/8471c225edb38eb2c3f86dd4e3d0c44bd0c6c1b1/internal/permissions/permissions.go#L149-L372
export enum IUserRole {
  LOCKBOX_SYSTEM = 'lockbox_system',
  TAAS_LOCKBOX_USER = 'taas_lockbox_user',
  TAAS_SECURITY_ENGINEER = 'taas_security_engineer',
  TAAS_TDIR_ENGINEER = 'taas_tdir_engineer',
  TAAS_TSS_ADMIN = 'taas_tss_admin',
  TAAS_ENGINEERING_MANAGER = 'taas_engineering_manager',
  TAAS_EXECUTIVE = 'taas_executive',
  TAAS_ENGINEER = 'taas_engineer',
  TAAS_SUPPORT_ENGINEER = 'taas_support_engineer',
  TAAS_CUSTOMER_ORG = 'taas_customer_org',
}
export const hasRole = (user: User | undefined, role: IUserRole): boolean => {
  return !!(user && user.roles.indexOf(role) > -1);
};

export const isLockboxRole = (role: string): boolean => {
  return Object.values(IUserRole).some((value: string) => value.toLowerCase() === role.toLowerCase());
};

export default IUserRole;
