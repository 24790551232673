import { mergeMap, catchError, map, concatAll, filter } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { from, of } from 'rxjs';
import i18next from 'i18next';
import { createService, getServices } from 'gql/GlobalService';
import RootEpic from 'store/RootEpic';
import Helper from 'utils/Helper';
import GlobalServiceSlice from 'components/pages/console/store/GlobalServiceSlice';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';
import { CONSOLE_ROUTE, SERVICE_ROUTE } from 'Route';
import { PermissionAction, PermissionResource, hasPermission } from 'gql/Permission';

export const fetchServicesEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(() =>
      hasPermission(state$.value.user.user?.permissions, PermissionAction.READ, PermissionResource.GLOBAL_SERVICE)
    ),
    filter(GlobalServiceSlice.actions.fetchServices.match),
    mergeMap(({ payload }) =>
      from(getServices(payload)).pipe(
        map((response) => GlobalServiceSlice.actions.fetchServicesSucceeded(response)),
        catchError((error) => of(GlobalServiceSlice.actions.fetchServicesFailed(error)))
      )
    )
  );

export const fetchAllServicesEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(() =>
      hasPermission(state$.value.user.user?.permissions, PermissionAction.READ, PermissionResource.GLOBAL_SERVICE)
    ),
    filter(GlobalServiceSlice.actions.fetchAllServices.match),
    mergeMap(({ payload }) =>
      from(getServices(payload)).pipe(
        map((response) => {
          return response.length > 0 && response[response.length - 1].nextToken
            ? [
                GlobalServiceSlice.actions.fetchServicesSucceeded(response),
                GlobalServiceSlice.actions.fetchAllServices({
                  nextToken: response[response.length - 1].nextToken,
                }),
              ]
            : [GlobalServiceSlice.actions.fetchServicesSucceeded(response)];
        }),
        concatAll(),
        catchError((error) => of(GlobalServiceSlice.actions.fetchServicesFailed(error)))
      )
    )
  );

export const fetchServicesFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(GlobalServiceSlice.actions.fetchServicesFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_SERVICES'), err: payload }))
  );

export const createNewServiceEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(GlobalServiceSlice.actions.createNewService.match),
    mergeMap((action) =>
      from(createService({ createService: action.payload })).pipe(
        map((response) => GlobalServiceSlice.actions.createNewServiceSucceeded(response!)),
        catchError((error) => of(GlobalServiceSlice.actions.createNewServiceFailed(error)))
      )
    )
  );

export const createNewServiceSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(GlobalServiceSlice.actions.createNewServiceSucceeded.match),
    mergeMap((action) => [
      showNotification({ type: 'success', contents: i18next.t('SUCCESS_CREATED_NEW_SERVICE', action.payload.id) }),
      push(Helper.interpolateRoute(SERVICE_ROUTE.url, { serviceId: action.payload.id })),
    ])
  );

export const createNewServiceFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(GlobalServiceSlice.actions.createNewServiceFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_CREATE_SERVICE'), err: payload }))
  );

export const createNewServiceCancelEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(GlobalServiceSlice.actions.cancelCreateNewService.match),
    map(() => push(CONSOLE_ROUTE.url))
  );

const GlobalServiceEpics: RootEpic[] = [
  fetchServicesEpic,
  fetchServicesFailedEpic,
  createNewServiceEpic,
  createNewServiceSuccessEpic,
  createNewServiceFailedEpic,
  createNewServiceCancelEpic,
  fetchAllServicesEpic,
];

export default GlobalServiceEpics;
