/* eslint-disable @typescript-eslint/restrict-template-expressions */
import BastionType from 'gql/BastionType';
import Helper from 'utils/Helper';
import { LITE_REQUEST_ACCESS } from 'gql/TargetGroup';
import IAccessConfiguration from 'common/IAccessConfiguration';
import { Tag } from 'gql/Common';
import { IDed } from './Request';
import BaseTarget from './BaseTarget';

export default interface GlobalServiceObject extends BaseTarget {
  workspace: string;
  vpcID: string;
  awsAccount: string;
  awsRegion: string;
  bastionTypes?: BastionType[];
  nextToken?: string;
}

export interface GetServicesFilter {
  nextToken?: string;
}

export const GLOBAL_SERVICE = `
{
  id
  name
  description
  tier
  workspace
  vpcID
  awsAccount
  awsRegion
  createdBy
  createdAt
  updatedBy
  updatedAt
  approvalSlackChannel
  notifyAutoApproval
  enablePaging
  teamRole
  orgLeaderRole
  managersADGroups
  accessConfiguration {
    level
    access ${LITE_REQUEST_ACCESS}
  }
  accessRequiresBastion
  limitedAccessRoles
  tags {
    key
    value
  }
  bastionTypes
  deleted
  nextToken
}`;

const GET_SERVICES = `
  query services($nextToken: String)  {
    globalServices(nextToken: $nextToken) ${GLOBAL_SERVICE}
  }
`;

export const getServices = async (filter: GetServicesFilter): Promise<GlobalServiceObject[]> => {
  const result = await Helper.execGQL<GlobalServiceObject[]>({
    query: GET_SERVICES,
    variables: {
      ...filter,
    },
  });
  let { globalServices } = result;
  if (result.globalServices) {
    globalServices = result.globalServices.filter((globalService) => {
      return !globalService.deleted;
    });
  }
  return globalServices || [];
};

const CREATE_SERVICE = `
    mutation createOrUpdateGlobalService($createService: CreateOrUpdateGlobalServiceObject!) {
      createOrUpdateGlobalService (GlobalServiceObject: $createService) {
        id
      }
    }
  `;

export interface CreateOrUpdateGlobalServiceObject extends IDed {
  name: string;
  description?: string;
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  tier: string;
  approvalSlackChannel?: string;
  notifyAutoApproval?: boolean;
  enablePaging: boolean;
  teamRole?: string;
  managersADGroups?: string;
  orgLeaderRole?: string;
  limitedAccessRoles: string[];
  accessRequiresBastion?: boolean;
  accessConfiguration?: IAccessConfiguration[];
  tags?: Tag[];
  workspace: string;
  vpcID: string;
  awsAccount: string;
  awsRegion: string;
}

export const createService = async (variables: {
  createService: CreateOrUpdateGlobalServiceObject;
}): Promise<IDed | undefined> => {
  const result = await Helper.execGQL<IDed>({
    query: CREATE_SERVICE,
    variables,
  });
  return result.createOrUpdateGlobalService;
};
