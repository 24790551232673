/* eslint-disable @typescript-eslint/restrict-template-expressions */

export default interface Bastion {
  instanceID: string;
  state: string;
}

export const BASTION = `
{
  instanceID
  state
}`;
