/* eslint-disable @typescript-eslint/restrict-template-expressions */
import moment from 'moment-timezone';
import Helper from 'utils/Helper';
import Approval, { APPROVAL } from 'gql/Approval';
import Audit from 'gql/Audit';
import Session, { SESSION } from 'gql/Session';
import Customer, { CUSTOMER } from 'gql/Customer';
import GlobalServiceObject, { GLOBAL_SERVICE } from 'gql/GlobalService';
import KubernetesClusterObject, { KUBERENETES_CLUSTER } from 'gql/KubernetesCluster';
import IAccessTypes from 'common/IAccessTypes';
import Bastion, { BASTION } from 'gql/Bastion';
import ISessionInterface from 'common/ISessionInterface';
import { InstanceServiceStatus } from 'common/IRequestAccess';
import AwsAccountObject, { AWS_ACCOUNT_OBJECT } from 'gql/AwsAccountObject';
import { LITE_REQUEST_ACCESS } from 'gql/TargetGroup';

export interface IDed {
  id: string;
}

export default interface Request extends IDed {
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  targetType: RequestTargetType;
  targetId: string;
  targetName: string;
  access?: RequestAccess[];
  customerId?: string;
  ticketInfo?: string;
  details: string;
  autopage: boolean;
  isApproved: boolean;
  isRejected: boolean;
  canAcceptOrReject: boolean;
  canOpenSession: boolean;
  canCloseSessions: boolean;
  canViewSessionLogs: boolean;
  canViewUserActivity: boolean;
  canViewRecorderSnapshot: boolean;
  canViewScratchpad: boolean;
  canCreateScratchpad: boolean;
  requestCloseTime: moment.Moment;
  sessionCloseTime?: moment.Moment;
  accessLevel?: number;
  approvals?: Approval[];
  audits?: Audit[];
  sessions?: Session[];
  bastions?: Bastion[];
  customer?: Customer;
  globalService?: GlobalServiceObject;
  awsAccountObject?: AwsAccountObject;
  kubernetesCluster: KubernetesClusterObject;
  hasFailedExecution: boolean;
  executionARN: string;
  nextToken?: string;
}

export enum RequestTargetType {
  CustomerEnvironment = 'CUSTOMER_ENVIRONMENT',
  GlobalService = 'GLOBAL_SERVICE',
  AWSAccount = 'AWS_ACCOUNT',
  KubernetesCluster = 'KUBERNETES_CLUSTER',
  TargetGroup = 'TARGET_GROUP',
}

export interface RequestAccessInput {
  type: IAccessTypes;
  instances?: RequestInstance[];
  portForward?: boolean;
  targetAccessID?: string;
}

export interface RequestInstance {
  instanceID?: string;
  canUploadFiles?: boolean;
  canRestartInstance?: boolean;
  serviceStatus?: InstanceServiceStatus;
}

export interface RequestAccess extends RequestAccessInput {
  allowedAccessInterfaces?: ISessionInterface[];
  canPortForward?: boolean;
}

export interface CreateRequest {
  targetType: RequestTargetType;
  targetId: string;
  access: RequestAccessInput[];
  ticketInfo?: string;
  details: string;
  autopage: boolean;
}

export interface GetRequestsFilter {
  startDate?: string;
  endDate?: string;
  createdBy?: string;
  isActiveRequest?: boolean;
  nextToken?: string;
}

const REQUEST_ACCESS = `
{
  type
  instances {
    instanceID
    canUploadFiles
    canRestartInstance
    serviceStatus {
      ec2InstanceStatus
      ssmAgentStatus
      vncServerStatus
    }
  }
  allowedAccessInterfaces
  canPortForward
  targetAccessID
}`;

export const FULL_REQUEST = `
{
  id
  createdBy
  createdAt
  updatedBy
  updatedAt
  customerId
  targetType
  targetId
  accessLevel
  access ${REQUEST_ACCESS}
  ticketInfo
  details
  autopage
  isApproved
  isRejected
  canAcceptOrReject
  canOpenSession
  canCloseSessions
  canViewSessionLogs
  canViewUserActivity
  canViewRecorderSnapshot
  canViewScratchpad
  canCreateScratchpad
  hasFailedExecution
  executionARN
  requestCloseTime
  sessionCloseTime
  approvals ${APPROVAL}
  audits {
    id
    createdBy
    createdAt
    updatedBy
    updatedAt
    requestId
    diffs {
      member
      oldValue
      newValue
    }
    oldImage
    newImage
    eventName
    itemType
  }
  sessions ${SESSION}
  bastions ${BASTION}
  customer ${CUSTOMER}
  globalService ${GLOBAL_SERVICE}
  awsAccountObject ${AWS_ACCOUNT_OBJECT}
  kubernetesCluster ${KUBERENETES_CLUSTER}
}`;

const LITE_REQUEST = `
{
  id
  createdBy
  createdAt
  targetType
  targetId
  targetName
  access ${LITE_REQUEST_ACCESS}
  ticketInfo
  details
  autopage
  isApproved
  isRejected
  sessionCloseTime
}`;

const GET_REQUESTS = `
  query requests($startDate: String, $endDate: String, $createdBy: String, $isActiveRequest: Boolean, $nextToken: String) {
    requests (startDate: $startDate, endDate: $endDate, createdBy: $createdBy, isActiveRequest: $isActiveRequest, nextToken: $nextToken) ${LITE_REQUEST}
  }
`;

export const parseTimeStrings = (oldRequest: Request): Request => {
  const newRequest: Request = {
    ...oldRequest,
    requestCloseTime: moment(oldRequest.requestCloseTime),
    sessionCloseTime: oldRequest.sessionCloseTime ? moment(oldRequest.sessionCloseTime) : undefined,
  };
  return newRequest;
};

export const getRequests = async (filter: GetRequestsFilter): Promise<Request[]> => {
  const result = await Helper.execGQL<Request[]>({
    query: GET_REQUESTS,
    variables: {
      ...filter,
    },
  });
  return result.requests?.map(parseTimeStrings) || [];
};

const GET_REQUEST_BY_ID = `
    query request($id: String!) {
      request (id: $id) ${FULL_REQUEST}
    }
  `;

/**
 * Calls API for GET_REQUEST_BY_ID
 * @param variables { id: string }
 * @returns Promise<Request>
 */
export const getRequest = async (variables: { id: string }): Promise<Request | undefined> => {
  const result = await Helper.execGQL<Request>({
    query: GET_REQUEST_BY_ID,
    variables,
  });
  return result.request ? parseTimeStrings(result.request) : undefined;
};

const CREATE_REQUEST = `
    mutation createRequest($createRequest: CreateRequestInput!) {
      createRequest (createRequest: $createRequest) {
        id
      }
    }
  `;

export const createRequest = async (variables: { createRequest: CreateRequest }): Promise<IDed | undefined> => {
  const result = await Helper.execGQL<IDed>({
    query: CREATE_REQUEST,
    variables,
  });
  return result.createRequest;
};

const CLOSE_SESSION = `
  mutation closeSessionWindow($requestID: String!) {
    closeSessionWindow (requestID: $requestID) ${FULL_REQUEST}
  }
`;

export const closeSession = async (variables: { requestID: string }): Promise<Request | undefined> => {
  const result = await Helper.execGQL<Request>({
    query: CLOSE_SESSION,
    variables,
  });
  return result.closeSessionWindow ? parseTimeStrings(result.closeSessionWindow) : undefined;
};
