import { push } from 'connected-react-router';
import { catchError, concatAll, map, mergeMap, filter } from 'rxjs/operators';
import { from, of } from 'rxjs';
import i18next from 'i18next';
import RootEpic from 'store/RootEpic';
import TargetGroupSlice from 'components/pages/console/store/TargetGroupSlice';
import Helper from 'utils/Helper';
import {
  createTargetGroup,
  getTargetGroup,
  listTargetGroups,
  updateTargetGroup,
  deleteTargetGroup,
} from 'gql/TargetGroup';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';
import { TARGET_GROUP_ROUTE, CONSOLE_ROUTE } from 'Route';
import { PermissionAction, PermissionResource, hasPermission } from 'gql/Permission';

// LIST
export const listTargetGroupsEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(() =>
      hasPermission(state$.value.user.user?.permissions, PermissionAction.READ, PermissionResource.TARGET_GROUP)
    ),
    filter(TargetGroupSlice.actions.listTargetGroups.match),
    mergeMap(({ payload }) => {
      return from(listTargetGroups(payload)).pipe(
        map((response) => {
          return response.nextToken
            ? [
                TargetGroupSlice.actions.listTargetGroupsSucceeded(response.items),
                TargetGroupSlice.actions.listTargetGroups({ nextToken: response.nextToken, size: payload?.size }),
              ]
            : [TargetGroupSlice.actions.listTargetGroupsSucceeded(response.items)];
        }),
        concatAll(),
        catchError((error) => {
          return of(TargetGroupSlice.actions.listTargetGroupsFailed(error));
        })
      );
    })
  );

export const listTargetGroupsFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(TargetGroupSlice.actions.listTargetGroupsFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_TARGET_GROUPS'), err: payload }))
  );

// GET
export const getTargetGroupEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(() =>
      hasPermission(state$.value.user.user?.permissions, PermissionAction.READ, PermissionResource.TARGET_GROUP)
    ),
    filter(TargetGroupSlice.actions.getTargetGroup.match),
    mergeMap(({ payload }) => {
      return from(getTargetGroup(payload)).pipe(
        map((response) => {
          return TargetGroupSlice.actions.getTargetGroupSucceeded(response);
        }),
        catchError((error) => {
          return of(TargetGroupSlice.actions.getTargetGroupFailed(error));
        })
      );
    })
  );

// CREATE
export const createTargetGroupEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(TargetGroupSlice.actions.createTargetGroup.match),
    mergeMap(({ payload }) =>
      from(createTargetGroup(payload)).pipe(
        map((response) => TargetGroupSlice.actions.createTargetGroupSucceeded(response)),
        catchError((error) => of(TargetGroupSlice.actions.createTargetGroupFailed(error)))
      )
    )
  );

export const createTargetGroupSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(TargetGroupSlice.actions.createTargetGroupSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({ type: 'success', contents: i18next.t('SUCCESS_CREATED_TARGET_GROUP', action.payload.id) }),
        push(Helper.interpolateRoute(TARGET_GROUP_ROUTE.url, { targetGroupId: action.payload.id })),
      ];
    })
  );

export const createTargetGroupFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(TargetGroupSlice.actions.createTargetGroupFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_CREATE_TARGET_GROUP'), err: payload }))
  );

// UPDATE
export const updateTargetGroupEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(TargetGroupSlice.actions.updateTargetGroup.match),
    mergeMap(({ payload }) => {
      return from(updateTargetGroup(payload)).pipe(
        map((response) => TargetGroupSlice.actions.updateTargetGroupSucceeded(response)),
        catchError((error) => of(TargetGroupSlice.actions.updateTargetGroupFailed(error)))
      );
    })
  );

export const updateTargetGroupSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(TargetGroupSlice.actions.updateTargetGroupSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({ type: 'success', contents: i18next.t('SUCCESS_UPDATED_TARGET_GROUP', action.payload.id) }),
        push(Helper.interpolateRoute(TARGET_GROUP_ROUTE.url, { targetGroupId: action.payload.id })),
      ];
    })
  );

export const updateTargetGroupFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(TargetGroupSlice.actions.updateTargetGroupFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_UPDATE_TARGET_GROUP'), err: payload });
    })
  );

// DELETE
export const deleteTargetGroupEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(TargetGroupSlice.actions.deleteTargetGroup.match),
    mergeMap(({ payload }) => {
      return from(deleteTargetGroup(payload)).pipe(
        map((response) => TargetGroupSlice.actions.deleteTargetGroupSucceeded(response)),
        catchError((error) => of(TargetGroupSlice.actions.deleteTargetGroupFailed(error)))
      );
    })
  );

export const deleteTargetGroupSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(TargetGroupSlice.actions.deleteTargetGroupSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({ type: 'success', contents: i18next.t('SUCCESS_DELETED_TARGET_GROUP', action.payload.id) }),
        push(`${CONSOLE_ROUTE.url}?tab=targetGroups`),
        TargetGroupSlice.actions.listTargetGroups,
      ];
    })
  );

export const deleteTargetGroupFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(TargetGroupSlice.actions.deleteTargetGroupFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_DELETE_TARGET_GROUP'), err: payload });
    })
  );

const TargetGroupEpics: RootEpic[] = [
  listTargetGroupsEpic,
  listTargetGroupsFailedEpic,
  getTargetGroupEpic,
  createTargetGroupEpic,
  createTargetGroupSuccessEpic,
  createTargetGroupFailedEpic,
  updateTargetGroupEpic,
  updateTargetGroupSuccessEpic,
  updateTargetGroupFailedEpic,
  deleteTargetGroupEpic,
  deleteTargetGroupSuccessEpic,
  deleteTargetGroupFailedEpic,
];

export default TargetGroupEpics;
