import Helper from 'utils/Helper';

export default interface SessionEvent {
  identity: string;
  accessKeyID?: string;
  eventID?: string;
  eventName?: string;
  eventSource?: string;
  eventTime?: string;
  readOnly?: string;
}

export interface SessionEventResponse {
  events: SessionEvent[];
  nextToken?: string;
}

export const SESSION_EVENT = `{
  identity
  accessKeyID
  eventID
  eventName
  eventSource
  eventTime
  readOnly
}`;

export const SESSION_EVENT_RESPONSE = `{
  nextToken
  events ${SESSION_EVENT}
}`;

const GET_SESSION_EVENTS = `
  query sessionEvents($requestID: String!, $nextToken: String) {
    sessionEvents (requestID: $requestID, nextToken: $nextToken) ${SESSION_EVENT_RESPONSE}
  }
`;

export const getSessionEvents = async (requestId: string): Promise<SessionEventResponse> => {
  const response: SessionEventResponse = {
    events: [],
    nextToken: '',
  };

  do {
    // eslint-disable-next-line no-await-in-loop
    const partialResponse = await Helper.execGQL<SessionEventResponse>({
      query: GET_SESSION_EVENTS,
      variables: {
        requestID: requestId,
        nextToken: response.nextToken ? response.nextToken : undefined,
      },
    });

    response.events.push(...partialResponse.sessionEvents.events);
    response.nextToken = partialResponse.sessionEvents.nextToken;
  } while (response.nextToken);

  return response;
};
