import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import StepFunctionExecution, { DescribeStepFunctionExecutionInput } from 'gql/Executions';
import ActionState, { ActionStatus } from 'store/ActionState';

export type StepFunctionExecutionState = {
  executions: StepFunctionExecution[];
  execution?: StepFunctionExecution;
  executionState: ActionState;
};

export const initialState: StepFunctionExecutionState = {
  executions: [],
  executionState: { status: ActionStatus.IDLE },
};

export const stepFunctionExecutionAdapter = createEntityAdapter<StepFunctionExecution>();

const StepFunctionExecutionSlice = createSlice({
  name: 'stepFunctionExecutions',
  initialState,
  reducers: {
    fetchAccessRequestExecutionList: (
      state,
      _: PayloadAction<{ status: string; size: number }>
    ): StepFunctionExecutionState => {
      return {
        ...state,
        executionState: {
          status: ActionStatus.PROCESSING,
        },
      };
    },
    fetchAccessRequestExecutionListSucceeded: (
      _,
      action: PayloadAction<StepFunctionExecution[]>
    ): StepFunctionExecutionState => {
      return {
        executions: [...new Set(action.payload)],
        executionState: {
          status: ActionStatus.SUCCEEDED,
        },
      };
    },
    fetchAccessRequestExecutionListFailed: (state, action: PayloadAction<Error>): StepFunctionExecutionState => {
      return {
        ...state,
        executionState: {
          status: ActionStatus.FAILED,
          error: action.payload,
        },
      };
    },
    fetchChangeRequestExecutionList: (
      state,
      _: PayloadAction<{ status: string; size: number }>
    ): StepFunctionExecutionState => {
      return {
        ...state,
        executionState: {
          status: ActionStatus.PROCESSING,
        },
      };
    },
    fetchChangeRequestExecutionListSucceeded: (
      _,
      action: PayloadAction<StepFunctionExecution[]>
    ): StepFunctionExecutionState => {
      return {
        executions: [...new Set(action.payload)],
        executionState: {
          status: ActionStatus.SUCCEEDED,
        },
      };
    },
    fetchChangeRequestExecutionListFailed: (state, action: PayloadAction<Error>): StepFunctionExecutionState => {
      return {
        ...state,
        executionState: {
          status: ActionStatus.FAILED,
          error: action.payload,
        },
      };
    },
    fetchDescribeStepFunctionExecution: (
      state,
      _: PayloadAction<DescribeStepFunctionExecutionInput>
    ): StepFunctionExecutionState => {
      return {
        ...state,
        executionState: {
          status: ActionStatus.PROCESSING,
        },
      };
    },
    fetchDescribeStepFunctionExecutionSucceeded: (
      state,
      action: PayloadAction<StepFunctionExecution>
    ): StepFunctionExecutionState => {
      return {
        ...state,
        execution: action.payload,
        executionState: {
          status: ActionStatus.SUCCEEDED,
        },
      };
    },
    fetchDescribeStepFunctionExecutionFailed: (state, action: PayloadAction<Error>): StepFunctionExecutionState => {
      return {
        ...state,
        executionState: {
          status: ActionStatus.FAILED,
          error: action.payload,
        },
      };
    },
  },
});

export default StepFunctionExecutionSlice;
