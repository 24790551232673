import React from 'react';
import { TaniumSpinner } from '@tanium/react-tanium-spinner';
import AbsoluteCenteredDiv from 'components/styles/containers/AbsoluteCenteredDiv';

const Spinner = (): React.ReactElement => {
  return (
    <AbsoluteCenteredDiv>
      <TaniumSpinner fill={true} />
    </AbsoluteCenteredDiv>
  );
};

export default Spinner;
