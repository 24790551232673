/*
 *  Statuses to define the reducer state.
 */
export enum ActionStatus {
  IDLE,
  PROCESSING,
  SUCCEEDED,
  FAILED,
}

/**
 * ActionState maintains the state of an action.
 */
export default interface ActionState {
  /**
   * enum to indicate the status of an action.
   */
  status: ActionStatus;
  /**
   * Has Error if an error occurred during query/mutation, undefined in case of success.
   */
  error?: Error;
}

/**
 * Check if the status is in a processing state
 */
export const isProcessing = (status: ActionStatus): boolean => {
  return status === ActionStatus.PROCESSING;
};

/**
 * Check if the status is in an end state
 */
export const isEndState = (status: ActionStatus): boolean => {
  return status === ActionStatus.SUCCEEDED || status === ActionStatus.FAILED;
};
