import { createSelector } from '@reduxjs/toolkit';
import { BastionTargetAccess } from 'gql/BastionTargetAccess';
import { ActionStatus } from 'store/ActionState';
import { RootState } from 'store/RootReducer';
import {
  BastionTargetAccessState,
  bastionTargetAccessAdapter,
} from 'components/pages/console/store/BastionTargetAccessSlice';

const selectSelf = (state: RootState): BastionTargetAccessState => {
  return state.bastionTargetAccess;
};

const selectBastionTargetAccessLoading = createSelector(selectSelf, (state: BastionTargetAccessState): boolean => {
  return state.loadingState.status === ActionStatus.PROCESSING;
});

const selectBastionTargetAccess = (id?: string) => {
  return (state: RootState): BastionTargetAccess | undefined => {
    return id
      ? bastionTargetAccessAdapter.getSelectors().selectById(state.bastionTargetAccess.bastionTargetAccesses, id)
      : undefined;
  };
};

const selectBastionTargetAccesses = createSelector(
  selectSelf,
  (state: BastionTargetAccessState): BastionTargetAccess[] => {
    return bastionTargetAccessAdapter.getSelectors().selectAll(state.bastionTargetAccesses);
  }
);

const selectMutationSucceeded = createSelector(selectSelf, (state: BastionTargetAccessState): boolean => {
  return state.mutationState.status === ActionStatus.SUCCEEDED;
});

const BastionTargetAccessSelectors = {
  selectMutationSucceeded,
  selectBastionTargetAccessLoading,
  selectBastionTargetAccesses,
  selectBastionTargetAccess,
};

export default BastionTargetAccessSelectors;
