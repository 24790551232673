import axios from 'axios';
import Logger from 'logger/Logger';
import { customGraphQLHeaders } from 'utils/AuthSession';
import Helper, { EndpointType } from 'utils/Helper';

const logger = Logger.getLogger('CsrfToken');

export const CSRF_HEADER = 'x-csrf-token';
export const CSRF_TOKEN_KEY = 'CSRF_TOKEN';

export const SaveCsrfToken = async (): Promise<void> => {
  const headers = await customGraphQLHeaders();
  try {
    const response = await axios.get(Helper.getEndpoint(EndpointType.Csrf), { headers });
    logger.debug('Got CSRF Response', { response });
    sessionStorage.setItem(CSRF_TOKEN_KEY, response.headers[CSRF_HEADER]);
  } catch (err) {
    logger.error('failed to get csrf token', err);
  }
};

export const GetCsrfToken = (): string | null => {
  return sessionStorage.getItem(CSRF_TOKEN_KEY);
};

export const RemoveCsrfToken = (): void => {
  sessionStorage.removeItem(CSRF_TOKEN_KEY);
};
