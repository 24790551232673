import { mergeMap, catchError, map, concatMap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { ofType } from 'redux-observable';
import i18next from 'i18next';
import FileUploadSlice from 'components/app/store/fileUpload/FileUploadSlice';
import { createPresignedUrl, uploadFileToInstanceCall, UploadFileToInstanceRequest } from 'gql/FileUpload';
import { showNotificationFromError, showNotification } from 'components/app/store/UserSlice';
import { PostFileUpload } from 'utils/FileUpload';

import RootEpic from 'store/RootEpic';

export const fetchPresignedUrlFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(FileUploadSlice.actions.fetchPresignedUrlFail),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_TO_GET_PRESIGNED_URL'), err: payload }))
  );

export const fetchPresignedUrl: RootEpic = (action$) =>
  action$.pipe(
    ofType(FileUploadSlice.actions.fetchPresignedUrl),
    mergeMap(({ payload }) =>
      from(
        createPresignedUrl({
          requestID: payload.requestID,
          instanceID: payload.instanceID,
          fileName: payload.fileName,
          contentMD5: payload.contentMD5,
        })
      ).pipe(
        map((response) => {
          return FileUploadSlice.actions.fetchPresignedUrlSuccess(response);
        }),
        catchError((error) => {
          return of(FileUploadSlice.actions.fetchPresignedUrlFail(error));
        })
      )
    )
  );

export const fileUploadFailEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(FileUploadSlice.actions.fileUploadFail),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_TO_UPLOAD_FILE'), err: payload }))
  );

export const fileUpload: RootEpic = (action$) =>
  action$.pipe(
    ofType(FileUploadSlice.actions.fileUpload),
    mergeMap(({ payload }) =>
      from(PostFileUpload(payload.fileToUpload, payload.presignedUrl)).pipe(
        map((response) => {
          return FileUploadSlice.actions.fileUploadSuccess(response);
        }),
        catchError((error) => {
          return of(FileUploadSlice.actions.fileUploadFail(error));
        })
      )
    )
  );

export const uploadFileToInstanceFailEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(FileUploadSlice.actions.uploadFileToInstanceFail),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_TO_UPLOAD_FILE'), err: payload }))
  );

export const uploadFileToInstance: RootEpic = (action$) =>
  action$.pipe(
    ofType(FileUploadSlice.actions.uploadFileToInstance),
    mergeMap(({ payload }) =>
      from(
        uploadFileToInstanceCall({
          requestID: payload.requestID,
          instanceID: payload.instanceID,
          fileName: payload.fileName,
          fileUploadID: payload.fileUploadID,
        } as UploadFileToInstanceRequest)
      ).pipe(
        concatMap((response) =>
          of(
            showNotification({
              type: 'success',
              contents: i18next.t('FILE_UPLOAD_SUCCESS', { fileName: payload.fileName, instance: payload.instanceID }),
            }),
            FileUploadSlice.actions.uploadFileToInstanceSuccess(response),
            FileUploadSlice.actions.resetFileUploadState()
          )
        ),
        catchError((error) => {
          return of(FileUploadSlice.actions.uploadFileToInstanceFail(error));
        })
      )
    )
  );

const FileUploadEpics: RootEpic[] = [
  fetchPresignedUrl,
  fetchPresignedUrlFailedEpic,
  fileUploadFailEpic,
  fileUpload,
  uploadFileToInstanceFailEpic,
  uploadFileToInstance,
];

export default FileUploadEpics;
