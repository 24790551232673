import Helper from 'utils/Helper';
import IAccessTypes from 'common/IAccessTypes';
import { RequestAccess } from 'gql/Request';

const GET_REQUEST_ACCESS = `
    query requestAccessTypes {
      requestAccessTypes {
        type
      }
    }
  `;

/**
 * Calls API for GET_REQUEST_ACCESS
 * @returns Promise<IRequestAccess>
 */
export const getRequestAccess = async (): Promise<RequestAccess[]> => {
  const result = await Helper.execGQL<RequestAccess[]>({
    query: GET_REQUEST_ACCESS,
  });
  return result.requestAccessTypes?.filter((access) => access.type in IAccessTypes) || [];
};

export default getRequestAccess;
