import React, { useCallback } from 'react';
import i18next from 'i18next';
import { Link } from '@tanium/react-link';
import SmallLightText from 'components/styles/text/SmallLightText';
import { signOutSession } from 'utils/AuthSession';

const SignOut: React.VFC = () => {
  const signOut = useCallback(async (): Promise<void> => {
    // sign out the user from the identity broker
    await signOutSession();
  }, []);

  return (
    <Link onClick={() => signOut()}>
      <SmallLightText>{i18next.t('SIGN_OUT')}</SmallLightText>
    </Link>
  );
};

export default SignOut;
