import useDarkTheme from 'common/hooks/useDarkTheme/useDarkTheme';
import AwsAccountObjectSelectors from 'components/pages/console/store/AwsAccountObjectSelectors';
import AwsAccountObjectSlice from 'components/pages/console/store/AwsAccountObjectSlice';
import AwsRoleTargetAccessSlice from 'components/pages/console/store/AwsRoleTargetAccessSlice';
import HostTargetAccessSlice from 'components/pages/console/store/HostTargetAccessSlice';
import BastionTargetAccessSlice from 'components/pages/console/store/BastionTargetAccessSlice';
import K8sRoleTargetAccessSlice from 'components/pages/console/store/K8sRoleTargetAccessSlice';
import GlobalServiceSelectors from 'components/pages/console/store/GlobalServiceSelectors';
import GlobalServiceSlice from 'components/pages/console/store/GlobalServiceSlice';
import AwsRoleTargetAccessSelectors from 'components/pages/console/store/AwsRoleTargetAccessSelectors';
import BastionTargetAccessSelectors from 'components/pages/console/store/BastionTargetAccessSelectors';
import HostTargetAccessSelectors from 'components/pages/console/store/HostTargetAccessSelectors';
import K8sRoleTargetAccessSelectors from 'components/pages/console/store/K8sRoleTargetAccessSelectors';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Stack, StackChild } from '@tanium/react-stack';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@tanium/react-box';
import { DefinitionList, DefinitionListItem } from '@tanium/react-definition-list';
import { Panel } from '@tanium/react-panel';
import { Button } from '@tanium/react-button';
import Page from 'components/base_page/Page';
import PageNotFound from 'components/base_page/PageNotFound';
import RequestSelectors from 'components/pages/request/store/RequestSelectors';
import Spinner from 'components/common/spinner/Spinner';
import ChangeRequestSlice from 'components/pages/console/store/ChangeRequestSlice';
import ChangeRequestSelectors from 'components/pages/console/store/ChangeRequestSelectors';
import MediumDefinitionListItem from 'components/common/MediumDefinitonListItem/MediumDefinionListItem';
import TargetLink from 'components/common/targetLink/targetLink';
import Helper from 'utils/Helper';
import { Approvals } from 'components/pages/request/requestPartials/Approvals';
import { AccessLevel } from 'gql/Config';
import RequestSlice from 'components/pages/request/store/RequestSlice';
import MediumText from 'components/styles/text/MediumText';
import MediumBoldText from 'components/styles/text/MediumBoldText';
import StatusIcon, { IIconTypes } from 'components/icons/StatusIcon';
import ReactDiffViewer from 'react-diff-viewer-continued';
import stringify from 'json-stable-stringify';
import { AuditHistory } from '../request/requestPartials/AuditHistory';
import ConfirmationRejectModal from '../request/requestPartials/ConfirmationRejectModal';

interface Params {
  buttonTimeoutSeconds?: number;
}

export const CHANGE_REQUEST_PREFIX = 'changeRequest';

// This page manages a single request
const ChangeRequestPage: React.VFC<Params> = ({ buttonTimeoutSeconds = 2 }) => {
  const { requestId } = useParams<{ requestId: string }>();
  const dispatch = useDispatch();
  const accessLevels = useSelector(RequestSelectors.selectAccessLevels);
  const [accessLevel, setAccessLevel] = useState<AccessLevel | undefined>();
  const [proposedChange, setProposedChange] = useState('');
  const [confirmRejectModal, setConfirmRejectModal] = useState<boolean>(false);
  const changeRequest = useSelector(ChangeRequestSelectors.selectChangeRequest);
  const approvals = useSelector(ChangeRequestSelectors.selectChangeRequestApprovals);
  const fetchComplete = useSelector(ChangeRequestSelectors.selectChangeRequestComplete);
  const bastionTAs = useSelector(BastionTargetAccessSelectors.selectBastionTargetAccesses);
  const awsRoleTAs = useSelector(AwsRoleTargetAccessSelectors.selectAwsRoleTargetAccesses);
  const hostTAs = useSelector(HostTargetAccessSelectors.selectHostTargetAccesses);
  const k8sTAs = useSelector(K8sRoleTargetAccessSelectors.selectK8sRoleTargetAccessList);
  const services = useSelector(GlobalServiceSelectors.selectAll);
  const awsAccounts = useSelector(AwsAccountObjectSelectors.selectAwsAccountObjects);
  const [changeTargetID, setChangeTargetID] = useState('');
  const [originalValue, setOriginalValue] = useState('');
  const [isDarkMode] = useDarkTheme();
  const { t } = useTranslation();

  // On pageload get the initial set of data
  useEffect(() => {
    dispatch(RequestSlice.actions.fetchConfigs());
    dispatch(ChangeRequestSlice.actions.getChangeRequest(requestId));
  }, [dispatch, requestId]);

  useEffect(() => {
    if (changeRequest) {
      if (changeRequest.accessLevel !== undefined && accessLevels) {
        setAccessLevel(accessLevels.find((access) => access.accessLevel === changeRequest?.accessLevel));
      }
      // this logic should be updated when more than one change can be stored on a change request
      if (changeRequest.changes.length > 0) {
        const changeSet = changeRequest.changes[0];
        // get the original value
        const targetType = changeSet.targetID.split('_')[0];
        switch (targetType) {
          case 'service':
            dispatch(GlobalServiceSlice.actions.fetchAllServices({}));
            break;
          case 'bastionTA':
            dispatch(BastionTargetAccessSlice.actions.getBastionTargetAccessByID({ id: changeSet.targetID }));
            break;
          case 'awsRoleTA':
            dispatch(AwsRoleTargetAccessSlice.actions.getAwsRoleTargetAccessByID({ id: changeSet.targetID }));
            break;
          case 'hostTA':
            dispatch(HostTargetAccessSlice.actions.getHostTargetAccessByID({ id: changeSet.targetID }));
            break;
          case 'k8sRoleTA':
            dispatch(K8sRoleTargetAccessSlice.actions.getK8sRoleTargetAccessByID({ id: changeSet.targetID }));
            break;
          case 'awsaccount':
            dispatch(AwsAccountObjectSlice.actions.fetchAwsAccountObjects({ viewAllRoles: false }));
        }
        setChangeTargetID(changeSet.targetID);
        setProposedChange(stringify(JSON.parse(changeSet.proposedValue), { space: '  ' }));
      }
    }
  }, [changeRequest, accessLevels, setChangeTargetID, dispatch]);

  useEffect(() => {
    // populate original value if it's a global service
    const targetAWSAccountRO = awsAccounts.find((acct) => acct.id === changeTargetID);
    if (targetAWSAccountRO) {
      const targetAWSAccount = { ...targetAWSAccountRO };
      targetAWSAccount.nextToken = undefined;
      setOriginalValue(stringify(targetAWSAccount, { space: '  ' }));
    }
  }, [changeTargetID, awsAccounts]);

  useEffect(() => {
    // populate original value if it's a global service
    const targetServiceRO = services.find((service) => service.id === changeTargetID);
    if (targetServiceRO) {
      const targetService = { ...targetServiceRO };
      targetService.nextToken = undefined;
      setOriginalValue(stringify(targetService, { space: '  ' }));
    }
  }, [changeTargetID, services]);

  useEffect(() => {
    // populate original value if it's a target access
    let originalTA = null;
    const targetAccessType = changeTargetID.split('_')[0];
    switch (targetAccessType) {
      case 'bastionTA':
        originalTA = bastionTAs.find((ta) => ta.id === changeTargetID);
        break;
      case 'awsRoleTA':
        originalTA = awsRoleTAs.find((ta) => ta.id === changeTargetID);
        break;
      case 'hostTA':
        originalTA = hostTAs.find((ta) => ta.id === changeTargetID);
        break;
      case 'k8sRoleTA':
        originalTA = k8sTAs.find((ta) => ta.id === changeTargetID);
        break;
    }
    if (originalTA) {
      setOriginalValue(stringify(originalTA, { space: '  ' }));
    }
  }, [changeTargetID, bastionTAs, awsRoleTAs, hostTAs, k8sTAs]);

  const isLastRejected = useCallback((): boolean => {
    if (changeRequest?.approvals?.length) {
      const lastApproval = changeRequest?.approvals[changeRequest?.approvals.length - 1];
      if (lastApproval.rejectedAt && lastApproval.rejectedBy) {
        return true;
      }
    }
    return false;
  }, [changeRequest]);

  // Handle opening / closing the rejection modal
  const openConfirmRejectModal = () => {
    setConfirmRejectModal(true);
  };
  const closeConfirmRejectModal = (proceed: boolean) => {
    if (proceed) {
      handleAcceptOrReject(false);
    }
    setConfirmRejectModal(false);
  };

  // handleAcceptOrReject will grant or deny access on the first eligible approval for this user
  const handleAcceptOrReject = (grant: boolean) => {
    // Filter for approvals that we can perform the requested operation on
    const eligibleApproval = approvals.filter((a) => (grant ? a.canAccept : a.canReject));
    // This call should be safe because the 'Accept' and 'Reject' buttons should not exist if there
    // is not a valid approval to perform the operation
    dispatch(
      RequestSlice.actions.acceptOrRejectApproval({
        approvalID: eligibleApproval[0].id,
        requestID: requestId,
        grant,
      })
    );
  };

  const buttons = [];
  // If an approval can be accepted show 'Accept' button
  if (changeRequest?.canAcceptOrReject && approvals.some((approval) => approval.canAccept)) {
    buttons.push(
      <Button
        data-testid="accept-request"
        onClick={(): void => {
          handleAcceptOrReject(true);
        }}
      >
        {t('APPROVE')}
      </Button>
    );
  }
  // If any approval can be rejected show 'Reject' button
  if (changeRequest?.canAcceptOrReject && approvals.some((approval) => approval.canReject)) {
    buttons.push(
      <Button
        data-testid="reject-request"
        onClick={(): void => {
          openConfirmRejectModal();
        }}
      >
        {t('REJECT')}
      </Button>
    );
  }

  return (
    <Page
      title={requestId}
      breadcrumbs={[
        {
          href: '/?tab=active',
          label: t('REQUESTS'),
        },
      ]}
      testId="change-request-page"
      buttons={buttons}
    >
      {!fetchComplete ? (
        <Spinner />
      ) : (
        <Stack itemSpacing={4} direction="column">
          {changeRequest ? (
            <StackChild>
              <Box pb={1}>
                <Panel title={t('CHANGE_REQUEST_INFO')} isCollapsible>
                  <Stack itemSpacing={10} direction="columns">
                    <DefinitionList>
                      <MediumDefinitionListItem term={t('TARGET_TYPE')} description={t(changeRequest.targetType)} />
                      <MediumDefinitionListItem
                        term={t(changeRequest.targetType)}
                        description={changeRequest.targetName}
                      />
                      <MediumDefinitionListItem
                        term={t('TARGET')}
                        description={<TargetLink target={changeRequest} />}
                      />
                      {accessLevel && (
                        <MediumDefinitionListItem
                          term={t('APPROVAL_NEEDED')}
                          description={
                            <>
                              <Stack itemSpacing={1}>{accessLevel.name}</Stack>
                            </>
                          }
                        />
                      )}
                      {changeRequest?.isApproved && !isLastRejected() && (
                        <DefinitionListItem
                          term={<MediumText>{t('APPROVALS')}:</MediumText>}
                          description={
                            <>
                              <Stack itemSpacing={1}>
                                <MediumBoldText>{t('APPROVED')}</MediumBoldText>
                                <StatusIcon type={IIconTypes.SUCCESS} props={{ outerRadius: 9, innerRadius: 6 }} />
                              </Stack>
                            </>
                          }
                        />
                      )}
                      {changeRequest?.isRejected && (
                        <DefinitionListItem
                          term={<MediumText>{t('APPROVALS')}:</MediumText>}
                          description={
                            <>
                              <Stack itemSpacing={1}>
                                <MediumBoldText>{t('REJECTED')}</MediumBoldText>
                                <StatusIcon type={IIconTypes.WARNING} props={{ outerRadius: 9, innerRadius: 6 }} />
                              </Stack>
                            </>
                          }
                        />
                      )}
                    </DefinitionList>
                    <DefinitionList>
                      <MediumDefinitionListItem term={t('CREATED_BY')} description={changeRequest.createdBy} />
                      <MediumDefinitionListItem
                        term={t('CREATED_AT')}
                        description={Helper.localDateIn12HrFormat(changeRequest.createdAt)}
                      />
                    </DefinitionList>
                  </Stack>
                  <ReactDiffViewer
                    oldValue={originalValue}
                    newValue={proposedChange}
                    useDarkTheme={isDarkMode}
                    splitView={true}
                    disableWordDiff={true}
                  />
                </Panel>
              </Box>
              <Box pb={1}>
                <Approvals approvals={approvals} />
              </Box>
              <Box pb={1}>
                <AuditHistory audits={changeRequest.audits} />
              </Box>
            </StackChild>
          ) : (
            <PageNotFound errorMessage={t('FAILED_GET_CHANGE_REQUEST')} />
          )}
        </Stack>
      )}
      <ConfirmationRejectModal
        isOpen={confirmRejectModal}
        timeoutSeconds={buttonTimeoutSeconds}
        closeModal={closeConfirmRejectModal}
      />
    </Page>
  );
};

export default ChangeRequestPage;
