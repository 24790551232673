import { createSelector } from '@reduxjs/toolkit';
import { ChangeRequestState } from 'components/pages/console/store/ChangeRequestSlice';
import { ChangeRequest } from 'gql/ChangeRequest';
import { ActionStatus } from 'store/ActionState';
import { RootState } from 'store/RootReducer';
import Approval from 'gql/Approval';

const selectSelf = (state: RootState): ChangeRequestState => {
  return state.changeRequest;
};

const selectChangeRequestState = createSelector(selectSelf, (state: ChangeRequestState): ActionStatus => {
  return state.loadingState.status;
});

const selectChangeRequestComplete = createSelector(selectSelf, (state: ChangeRequestState): boolean => {
  return state.loadingState.status === ActionStatus.SUCCEEDED || state.loadingState.status === ActionStatus.FAILED;
});

const selectChangeRequest = createSelector(selectSelf, (state: ChangeRequestState): ChangeRequest | undefined => {
  return state.changeRequest;
});

const selectChangeRequestApprovals = createSelector(selectSelf, (state: ChangeRequestState): Approval[] => {
  return state.changeRequest?.approvals || [];
});

const selectCreateProcessing = createSelector(selectSelf, (state: ChangeRequestState): boolean => {
  return state.mutationState.status === ActionStatus.PROCESSING;
});

const selectCreateChangeRequestId = createSelector(selectSelf, (state: ChangeRequestState): string | undefined => {
  return state.mutatedChangeRequestId;
});

const selectCreateSucceeded = createSelector(selectSelf, (state: ChangeRequestState): boolean => {
  return state.mutationState.status === ActionStatus.SUCCEEDED;
});

const selectUpdateProcessing = createSelector(selectSelf, (state: ChangeRequestState): boolean => {
  return state.mutationState.status === ActionStatus.PROCESSING;
});

const selectUpdateSucceeded = createSelector(selectSelf, (state: ChangeRequestState): boolean => {
  return state.mutationState.status === ActionStatus.SUCCEEDED;
});

const selectUpdateChangeRequestId = createSelector(selectSelf, (state: ChangeRequestState): string | undefined => {
  return state.mutatedChangeRequestId;
});

const ChangeRequestSelectors = {
  selectChangeRequestState,
  selectChangeRequestComplete,
  selectChangeRequest,
  selectChangeRequestApprovals,
  selectCreateProcessing,
  selectCreateChangeRequestId,
  selectCreateSucceeded,
  selectUpdateProcessing,
  selectUpdateChangeRequestId,
  selectUpdateSucceeded,
};

export default ChangeRequestSelectors;
