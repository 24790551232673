import { Box } from '@tanium/react-box';
import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import Text from 'components/styles/text/Text';

export const MediumText = styled(Text)(
  css({
    fontSize: 14,
  })
);

export const MediumTextBox = styled(Box)(
  css({
    fontSize: 14,
  })
);

export default MediumText;
