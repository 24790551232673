import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import Service, { CreateOrUpdateGlobalServiceObject, GetServicesFilter } from 'gql/GlobalService';
import ActionState, { ActionStatus } from 'store/ActionState';
import { IDed } from 'gql/Request';
import PagerState, { buildPagerState, PagerStatus } from 'store/PagerState';

export type GlobalServiceState = {
  service?: Service;
  services: EntityState<Service> | null;
  serviceState: ActionState;

  serviceForm?: CreateOrUpdateGlobalServiceObject;
  createServiceState: ActionState;
  pagerState: PagerState;
};

export const serviceAdapter = createEntityAdapter<Service>();

export const initialState: GlobalServiceState = {
  services: null,
  serviceState: { status: ActionStatus.IDLE },
  createServiceState: { status: ActionStatus.IDLE },
  pagerState: { status: PagerStatus.HIDDEN },
};

const GlobalServiceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    fetchServices: (state, _: PayloadAction<GetServicesFilter>): GlobalServiceState => {
      return { ...state, serviceState: { status: ActionStatus.PROCESSING } };
    },
    fetchAllServices: (state, _: PayloadAction<GetServicesFilter>): GlobalServiceState => {
      return { ...state, serviceState: { status: ActionStatus.PROCESSING } };
    },
    fetchServicesSucceeded: (state, action: PayloadAction<Service[]>): GlobalServiceState => {
      return {
        ...state,
        services:
          action.payload.length === 0
            ? state.services
            : serviceAdapter.upsertMany(
                state.services ? { ...state.services } : serviceAdapter.getInitialState(),
                action.payload
              ),
        serviceState: { status: ActionStatus.SUCCEEDED },
        pagerState: buildPagerState(state.pagerState, action.payload),
      };
    },
    fetchServicesFailed: (state, action: PayloadAction<Error>): GlobalServiceState => {
      return {
        ...state,
        serviceState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    createNewService: (state, action: PayloadAction<CreateOrUpdateGlobalServiceObject>): GlobalServiceState => {
      return { ...state, serviceForm: action.payload, createServiceState: { status: ActionStatus.PROCESSING } };
    },
    cancelCreateNewService: (state): GlobalServiceState => {
      return { ...state, serviceForm: undefined, createServiceState: { status: ActionStatus.IDLE } };
    },
    createNewServiceSucceeded: (state, _: PayloadAction<IDed>): GlobalServiceState => {
      return {
        ...state,
        serviceForm: undefined,
        createServiceState: { status: ActionStatus.SUCCEEDED },
      };
    },
    createNewServiceFailed: (state, action: PayloadAction<Error>): GlobalServiceState => {
      return {
        ...state,
        createServiceState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
  },
});

export default GlobalServiceSlice;
