import React, { useCallback } from 'react';
import i18next from 'i18next';
import { Link } from '@tanium/react-link';
import { signIn as SignInSession } from 'utils/AuthSession';
import SmallLightText from 'components/styles/text/SmallLightText';

const SignIn: React.VFC = () => {
  const signIn = useCallback(async (): Promise<void> => {
    await SignInSession();
  }, []);
  return (
    <Link onClick={() => signIn()}>
      <SmallLightText>{i18next.t('SIGN_IN')}</SmallLightText>
    </Link>
  );
};

export default SignIn;
