import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';

const Text = styled('span')(
  css({
    fontWeight: 'normal',
  })
);

export default Text;
