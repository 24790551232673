import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';

const CenteredSmallDiv = styled('div')(
  css({
    margin: 'auto',
    textAlign: 'center',
    width: '25%',
  })
);

export default CenteredSmallDiv;
