import { applyMiddleware, compose, createStore, Store, AnyAction } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { History } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { combinedEpics } from 'store/RootEpic';
import RootReducer, { RootState } from 'store/RootReducer';

export type RootStateType = RootState;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-types
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
  }
}

export const epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, RootState>();
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create store
export function configureStore(history: History, initialState?: RootStateType): Store {
  const middleware = [routerMiddleware(history), epicMiddleware];
  const enhancer = composeEnhancers(applyMiddleware(...middleware));
  const store = createStore(RootReducer(history), initialState, enhancer);
  epicMiddleware.run(combinedEpics);
  return store;
}
