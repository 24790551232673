import React, { useState } from 'react';
import { Link } from '@tanium/react-link';
import User from 'gql/User';
import PreferencesModal from 'components/app_header/PreferencesModal';
import SmallLightText from 'components/styles/text/SmallLightText';

export enum Tabs {
  USER_INFO = 'user_info',
  PREFERENCES = 'preferences',
}
interface Props {
  user: User;
}

const CurrentUser: React.VFC<Props> = ({ user }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<string>(Tabs.USER_INFO);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Link onClick={() => openModal()}>
        <SmallLightText>{user.username}</SmallLightText>
      </Link>
      <PreferencesModal isOpen={isModalOpen} closeModal={closeModal} tab={tab} setTab={setTab} />
    </>
  );
};

export default CurrentUser;
