/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from '@reduxjs/toolkit';
import Approval from 'gql/Approval';
import Audit from 'gql/Audit';
import Request from 'gql/Request';
import Customer from 'gql/Customer';
import Session, { StartSession } from 'gql/Session';
import SessionLog from 'gql/SessionLog';
import { RootState } from 'store/RootReducer';
import SelectorUtils from 'store/SelectorUtils';
import { configAdapter, customerAdapter, RequestState } from 'components/pages/request/store/RequestSlice';
import { LockboxMetadata, LockboxSettings, SessionLimitSettings, TaniumCESystemUsers } from 'gql/Config';
import ActionState, { ActionStatus } from 'store/ActionState';
import Bastion from 'gql/Bastion';
import SessionEvent from 'gql/SessionEvent';
import { RecorderSnapshot } from 'gql/RecorderSnapshot';
import RequestAccessLevelConfig from 'gql/RequestAccessLevelConfig';

const selectSelf = (state: RootState): RequestState => {
  return state.request;
};

const selectRequestMetadataProcessing = createSelector(selectSelf, (state: RequestState): boolean => {
  return state.loadRequestMetadataState.status === ActionStatus.PROCESSING;
});

const selectRequest = () => {
  return createSelector(selectSelf, (state: RequestState): Request | undefined => state.request);
};

const selectApprovals = () => {
  return (state: RootState): Approval[] => state.request.approvals;
};

const selectAudits = () => {
  return (state: RootState): Audit[] => state.request.audits;
};

const selectSessions = () => {
  return (state: RootState): Session[] | undefined => state.request.sessions;
};

const selectBastions = createSelector(selectSelf, (state: RequestState): Bastion[] | undefined => state.bastions);

const selectStartedSession = () => {
  return createSelector(selectSelf, (state: RequestState): Session | undefined => state.startedSession);
};

const selectStartSessionArgs = () => {
  return createSelector(selectSelf, (state: RequestState): StartSession | undefined => state.startSessionArgs);
};

const selectSessionLogNextToken = () => {
  return createSelector(selectSelf, (state: RequestState): string | undefined => state.sessionLogNextPage);
};

const selectSessionLogRequestState = () => {
  return createSelector(selectSelf, (state: RequestState): ActionState => state.sessionLogState);
};

const selectSessionLogs = () => {
  return createSelector(selectSelf, (state: RequestState): SessionLog[] => state.sessionLogs);
};

const selectSessionEventRequestState = () => {
  return createSelector(selectSelf, (state: RequestState): ActionState => state.sessionEventState);
};

const selectSessionEvents = () => {
  return createSelector(selectSelf, (state: RequestState): SessionEvent[] => state.sessionEvents);
};

const selectRecorderSnapshotsRequestState = () => {
  return createSelector(selectSelf, (state: RequestState): ActionState => state.recorderSnapshotState);
};
const selectRecorderSnapshots = () => {
  return createSelector(selectSelf, (state: RequestState): RecorderSnapshot[] => state.recorderSnapshots);
};

// Config token indicating that the CE workspace should be inserted (if present)
export const CE_WORKSPACE = 'CE_WORKSPACE';

// selectTaniumCESystemUsers - transformative selector to get a specific config entry
const selectTaniumCESystemUsers = (ceWorkspace: string | undefined) => {
  return (state: RootState): TaniumCESystemUsers => {
    const config = configAdaptors.selectById('config_Settings')(state);
    let configValue = config?.configValue || '{}';
    if (ceWorkspace) {
      configValue = configValue.replaceAll(CE_WORKSPACE, ceWorkspace);
    }
    const lockboxSettings: LockboxSettings = JSON.parse(configValue);
    return lockboxSettings?.taniumCESystemUsers || {};
  };
};

// selectLockboxMetadata - transformative selector to get metadata of Lockbox
const selectLockboxMetadata = () => {
  return (state: RootState): LockboxMetadata => {
    const config = configAdaptors.selectById('config_LockboxMetadata')(state);
    return JSON.parse(config?.configValue || '{}') as LockboxMetadata;
  };
};

// selectAccessLevels - transformative selector to get access level config
const selectAccessLevels = createSelector(selectSelf, (state) => {
  const config = state.configs.entities.config_Settings;
  if (config?.configValue) {
    return (JSON.parse(config?.configValue) as LockboxSettings).accessLevels;
  }
  return [];
});

// selectSessionLimitSettings - transformative selector to get session limit settings
const selectSessionLimitSettings = (state: RootState): SessionLimitSettings | undefined => {
  const config = configAdaptors.selectById('config_Settings')(state);
  const lockboxSettings: LockboxSettings = JSON.parse(config?.configValue || '{}', (key, value) => {
    // Without explicit construction of Map, JSON parse results with roleLimits as an Object
    if (key === 'roleLimits') {
      return new Map(Object.entries(value));
    }
    return value;
  });
  return lockboxSettings?.sessionLimitSettings;
};

const selectAllSettings = (state: RootState): LockboxSettings => {
  const config = configAdaptors.selectById('config_Settings')(state);
  const lockboxSettings: LockboxSettings = JSON.parse(config?.configValue || '{}', (_, value) => {
    return value;
  });
  return lockboxSettings;
};

const selectConfigsProcessing = createSelector(selectSelf, (state: RequestState): boolean => {
  return state.configState.status === ActionStatus.PROCESSING;
});

const selectCustomers = () => {
  return (state: RootState): Customer[] => customerAdaptors.selectAll(state);
};

const selectCustomersProcessing = createSelector(selectSelf, (state: RequestState): boolean => {
  return state.customerState.status === ActionStatus.PROCESSING;
});

const selectCreateProcessing = createSelector(selectSelf, (state: RequestState): boolean => {
  return state.createRequestState.status === ActionStatus.PROCESSING;
});

const configAdaptors = SelectorUtils.generateSelectors(configAdapter, (state) => selectSelf(state).configs);

const customerAdaptors = SelectorUtils.generateSelectors(customerAdapter, (state) => selectSelf(state).customers);

const selectRequestAccessLevelConfig = (state: RootState): RequestAccessLevelConfig | undefined => {
  return state.request.requestAccessLevelConfig;
};

const selectCalculateRequestAccessLevelConfigState = (state: RootState): ActionState => {
  return state.request.calculateRequestAccessLevelConfigState;
};

const RequestSelectors = {
  selectRequestMetadataProcessing,
  selectRequest,
  selectApprovals,
  selectAudits,
  selectStartedSession,
  selectStartSessionArgs,
  selectBastions,
  selectSessions,
  selectSessionLogNextToken,
  selectSessionLogRequestState,
  selectSessionLogs,
  selectSessionEventRequestState,
  selectSessionEvents,
  selectRecorderSnapshotsRequestState,
  selectRecorderSnapshots,
  selectTaniumCESystemUsers,
  selectLockboxMetadata,
  selectConfigsProcessing,
  selectCustomers,
  selectCustomersProcessing,
  selectCreateProcessing,
  selectAccessLevels,
  selectRequestAccessLevelConfig,
  selectCalculateRequestAccessLevelConfigState,
  selectSessionLimitSettings,
  selectAllSettings,
};

export default RequestSelectors;
