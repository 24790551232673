import { Box } from '@tanium/react-box';
import { Button } from '@tanium/react-button';
import { Stack } from '@tanium/react-stack';
import { DefinitionList, DefinitionListItem } from '@tanium/react-definition-list';
import { ToggleButton } from '@tanium/react-toggle-button';
import { Modal } from '@tanium/react-modal';
import { Tab, TabSet } from '@tanium/react-tabs';
import { TabSelectEvent } from '@tanium/react-use-tabs';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import RequestSlice from 'components/pages/request/store/RequestSlice';
import MediumBoldText from 'components/styles/text/MediumBoldText';
import MediumText from 'components/styles/text/MediumText';
import UserSlice from 'components/app/store/UserSlice';
import User from 'gql/User';
import UserSelectors from 'components/app/store/UserSelectors';
import { Tabs } from 'components/app_header/CurrentUser';
import useDarkTheme from 'common/hooks/useDarkTheme/useDarkTheme';
import { buildPermissionsMap } from 'gql/Permission';

interface IPreferenceSModalProps {
  isOpen: boolean;
  closeModal: () => void;
  tab: string;
  setTab: (tab: string) => void;
}

const PreferencesModal = ({ isOpen, closeModal, tab, setTab }: IPreferenceSModalProps): React.ReactElement => {
  const { t } = useTranslation();
  const [isDarkMode, setIsDarkMode] = useDarkTheme();
  const user: User | undefined = useSelector(UserSelectors.selectUser);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(RequestSlice.actions.fetchConfigs());
    dispatch(UserSlice.actions.fetchUser());
  }, [dispatch]);

  const handleTabSelect = (e: TabSelectEvent): void => {
    const selectedTab = e.selectedId.toString();
    setTab(selectedTab);
  };

  const handleModeChange = (mode: 'light' | 'dark'): void => {
    window.localStorage.setItem('prefers-color-scheme', mode);
    document.dispatchEvent(new Event('theme-change'));
    setIsDarkMode(mode === 'dark');
  };

  const permissions = useMemo(() => {
    const permissionsMap = buildPermissionsMap(user?.permissions);
    const permissionTxt: string[] = [];
    permissionsMap.forEach((actions, resource) => {
      const actionsTxt: string[] = [];
      actions.forEach((action) => {
        actionsTxt.push(t(`PERMISSION_ACTION_${action}`));
      });
      actionsTxt.sort();
      permissionTxt.push(`${t(`PERMISSION_RESOURCE_${resource}`)}: ${actionsTxt.join(', ')}`);
    });
    permissionTxt.sort();
    return permissionTxt;
  }, [t, user?.permissions]);

  return (
    <Modal
      isOpen={isOpen}
      header={t('PREFERENCES')}
      footer={
        <Stack inline itemSpacing={1}>
          <Button data-testid="close" variant="secondary" onClick={closeModal}>
            {t('CLOSE')}
          </Button>
        </Stack>
      }
    >
      <Box px={1}>
        <Box>
          <TabSet onSelect={handleTabSelect} selectedId={tab}>
            <Tab key={Tabs.USER_INFO} id={Tabs.USER_INFO} label={t('USER_INFO')}>
              <DefinitionList>
                <DefinitionListItem
                  key="email"
                  term={<MediumText>{`${t('EMAIL')}: `}</MediumText>}
                  description={<MediumBoldText>{user?.email}</MediumBoldText>}
                />
                <DefinitionListItem
                  key="username"
                  term={<MediumText>{`${t('USERNAME')}: `}</MediumText>}
                  description={<MediumBoldText>{user?.username}</MediumBoldText>}
                />
                <DefinitionListItem
                  key="roles"
                  term={<MediumText>{`${t('USER_ROLES')}: `}</MediumText>}
                  description={
                    user?.roles.length &&
                    user.roles.map((role, i) => (
                      <div key={`role-${i}`}>
                        <MediumBoldText>{role}</MediumBoldText>
                        <br />
                      </div>
                    ))
                  }
                />
                <DefinitionListItem
                  key="premissions"
                  term={<MediumText>{`${t('USER_PERMISSIONS')}: `}</MediumText>}
                  description={
                    permissions.length &&
                    permissions.map((permission, j) => (
                      <div key={`permission-${j}`}>
                        <MediumBoldText>{permission}</MediumBoldText>
                        <br />
                      </div>
                    ))
                  }
                />
              </DefinitionList>
            </Tab>
            <Tab key={Tabs.PREFERENCES} id={Tabs.PREFERENCES} label={t('PREFERENCES')}>
              <Box display={'inline-block'} p={1}>
                <Box display={'flex'}>
                  <Box display={'flex'} alignItems={'center'}>
                    <MediumText>{t('DARK_THEME')}:</MediumText>
                  </Box>
                  <Box ml={2}>
                    <ToggleButton onToggle={() => handleModeChange('light')} selected={!isDarkMode} size={'sm'}>
                      {t('LIGHT')}
                    </ToggleButton>
                    <ToggleButton onToggle={() => handleModeChange('dark')} selected={isDarkMode} size={'sm'}>
                      {t('DARK')}
                    </ToggleButton>
                  </Box>
                </Box>
              </Box>
            </Tab>
          </TabSet>
        </Box>
      </Box>
    </Modal>
  );
};

export default PreferencesModal;
