import React, { useState } from 'react';
import { TooltippedButton, TooltippedButtonProps } from '@tanium/react-button';
import { CopyClipboardIcon as CopyIcon, CheckIcon } from '@tanium/react-graphics';
import { Box } from '@tanium/react-box';
import clipboardCopy from 'clipboard-copy';
import Logger from 'logger/Logger';
import 'components/common/copyToClipboard/copyToClipboard.css';

/**
 * @param displayText: must be supplied for the button to display a text field inside (can be left empty '')
 * @param copyText: can be omitted if you're using onclick() functionality
 */
export interface ICopyToClipboardProps extends TooltippedButtonProps {
  displayedText: string;
  copyText?: string;
  iconSize?: { width?: number; height?: number };
}

export const CopyToClipboard: React.VFC<ICopyToClipboardProps> = (props: ICopyToClipboardProps) => {
  const [isActiveRequest, setIsActiveRequest] = useState(false);
  const logger = Logger.getLogger('CopyToClipboard');

  const copyClicked = async () => {
    if (props.disabled) {
      return;
    }
    setIsActiveRequest(true);
    if (props.copyText) {
      try {
        await clipboardCopy(props.copyText);
      } catch (error) {
        logger.error((error as Error).message);
      }
    }
    // Resetting the button back to normal view
    setTimeout(() => {
      setIsActiveRequest(false);
    }, 2500);
  };

  return (
    <Box onClick={copyClicked} style={{ cursor: 'pointer' }} display="inline-block">
      <TooltippedButton className={isActiveRequest ? 'copy-to-clipboard-active' : ''} {...props}>
        {isActiveRequest ? (
          <CheckIcon
            style={{ paddingRight: 3 }}
            width={props.iconSize?.width || 13}
            height={props.iconSize?.height || 13}
          />
        ) : (
          <CopyIcon
            style={{ paddingRight: 3 }}
            width={props.iconSize?.width || 13}
            height={props.iconSize?.height || 13}
          />
        )}
        {props.displayedText}
      </TooltippedButton>
    </Box>
  );
};

export default CopyToClipboard;
