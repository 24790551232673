export enum APIAccessType {
  ROLE = 'ROLE',
  CLUSTER_ROLE = 'CLUSTER_ROLE',
}

export const apiAccessToLocalizationKey = (access: APIAccessType): string => {
  if (access === APIAccessType.CLUSTER_ROLE) {
    return 'K8S_API_CLUSTER_ACCESS';
  }
  if (access === APIAccessType.ROLE) {
    return 'K8S_API_ROLE_ACCESS';
  }
  return '';
};

export default APIAccessType;
