export enum PermissionAction {
  READ = 'READ',
  WRITE = 'WRITE',
}

export enum PermissionResource {
  ADMIN_PANEL = 'ADMIN_PANEL',
  AWS_ACCOUNT = 'AWS_ACCOUNT',
  AWS_ROLE_TARGET_ACCESS = 'AWS_ROLE_TARGET_ACCESS',
  BASTION_TARGET_ACCESS = 'BASTION_TARGET_ACCESS',
  CUSTOMER = 'CUSTOMER',
  GLOBAL_SERVICE = 'GLOBAL_SERVICE',
  HOST_TARGET_ACCESS = 'HOST_TARGET_ACCESS',
  K8S_ROLE_TARGET_ACCESS = 'K8S_ROLE_TARGET_ACCESS',
  REQUEST = 'REQUEST',
  TARGET_GROUP = 'TARGET_GROUP',
}

export default interface Permission {
  action: PermissionAction;
  resource: PermissionResource;
}

export const PERMISSION = `
{
  action
  resource
}`;

export const hasPermission = (
  permissions: Permission[] | undefined,
  action: PermissionAction,
  resource: PermissionResource
): boolean => {
  return permissions?.some((p) => p.action === action && p.resource === resource) || false;
};

export const buildPermissionsMap = (
  permissions: Permission[] | undefined
): Map<PermissionResource, Set<PermissionAction>> => {
  const permissionsMap = new Map<PermissionResource, Set<PermissionAction>>();

  if (permissions) {
    permissions.forEach((permission) => {
      const resourcePermissions = permissionsMap.get(permission.resource) || new Set<PermissionAction>();
      permissionsMap.set(permission.resource, resourcePermissions.add(permission.action));
    });
  }

  return permissionsMap;
};
