import { IDed } from 'gql/Request';
import { PaginationResult, SingletonResult } from 'gql/Common';
import Helper from 'utils/Helper';

export interface CreateHostTargetAccess {
  hostName: string;
  userName: string;
  graphicalAccess: boolean;

  description?: string;
  adGroups: string[];
  targetOrTargetGroupID: string;
}

export interface UpdateHostTargetAccess extends IDed, CreateHostTargetAccess {}

export interface HostTargetAccess extends UpdateHostTargetAccess {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
}

export const FULL_HOST_TARGET_ACCESS = `
{
  id
  createdBy
  createdAt
  updatedBy
  updatedAt
  hostName
  userName
  graphicalAccess
  description
  targetOrTargetGroupID
  adGroups
}`;

const GET_HOST_TARGET_ACCESS = `
  query getHostTargetAccess($id: String!) {
    getHostTargetAccess (id: $id) {
      item ${FULL_HOST_TARGET_ACCESS}
    }
  }
`;

export interface GetHostTargetAccessOptions {
  id: string;
}

export const getHostTargetAccess = async (
  options: GetHostTargetAccessOptions
): Promise<HostTargetAccess | undefined> => {
  const result = await Helper.execGQL<SingletonResult<HostTargetAccess | undefined>>({
    query: GET_HOST_TARGET_ACCESS,
    variables: {
      id: options.id,
    },
  });
  return result.getHostTargetAccess?.item;
};

const LIST_HOST_TARGET_ACCESS = `
  query listHostTargetAccess($filter: TargetBoundAccessFilterInput, $pagination: PaginationInput) {
    listHostTargetAccess (filter: $filter, pagination: $pagination) {
      items ${FULL_HOST_TARGET_ACCESS}
      total
      nextToken
    }
  }
`;

export interface ListHostTargetAccessOptions {
  size?: number;
  nextToken?: string;
  includeAllTargetGroups: boolean;
  targetOrTargetGroupID: string;
  userADGroups?: string[];
}

export const listHostTargetAccess = async (
  options: ListHostTargetAccessOptions
): Promise<PaginationResult<HostTargetAccess>> => {
  const result = await Helper.execGQL<PaginationResult<HostTargetAccess>>({
    query: LIST_HOST_TARGET_ACCESS,
    variables: {
      filter: {
        includeAllTargetGroups: options.includeAllTargetGroups,
        targetOrTargetGroupID: options.targetOrTargetGroupID,
        userADGroups: options.userADGroups,
      },
      pagination: {
        size: options.size,
        nextToken: options.nextToken,
      },
    },
  });
  return result.listHostTargetAccess;
};

const CREATE_HOST_TARGET_ACCESS = `
  mutation createHostTargetAccess($create: CreateHostTargetAccessInput!) {
    createHostTargetAccess(create: $create) {
      item {
        id
      }
    } 
  }`;

export const createHostTargetAccess = async (host: CreateHostTargetAccess): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<HostTargetAccess>>({
    query: CREATE_HOST_TARGET_ACCESS,
    variables: {
      create: host,
    },
  });
  return result.createHostTargetAccess.item;
};

const UPDATE_HOST_TARGET_ACCESS = `
  mutation updateHostTargetAccess($update: UpdateHostTargetAccessInput!) {
      updateHostTargetAccess(update: $update) {
        item { 
          id
        }
      }
  }`;

export const updateHostTargetAccess = async (host: UpdateHostTargetAccess): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<HostTargetAccess>>({
    query: UPDATE_HOST_TARGET_ACCESS,
    variables: {
      update: host,
    },
  });
  return result.updateHostTargetAccess.item;
};

const DELETE_HOST_TARGET_ACCESS = `
mutation deleteHostTargetAccess($id: String!) {
  deleteHostTargetAccess(id: $id) {
    item ${FULL_HOST_TARGET_ACCESS}
  }
}
`;

export const deleteHostTargetAccess = async (id: string): Promise<HostTargetAccess> => {
  const result = await Helper.execGQL<SingletonResult<HostTargetAccess>>({
    query: DELETE_HOST_TARGET_ACCESS,
    variables: {
      id,
    },
  });
  return result.deleteHostTargetAccess.item;
};
