import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';
import { AppHeaderStyle } from 'components/styles/containers/ContainerBox';

export const headerHeight = AppHeaderStyle.borderHeight + AppHeaderStyle.containerHeight + AppHeaderStyle.marginHeight;

export const NotificationWrapperDiv = styled('div')(
  css({
    width: '30%',
    margin: 'auto',
    marginTop: `${headerHeight - 10}px`,
    position: 'absolute',
    overflow: 'hidden',
    top: '0',
    left: '0',
    right: '0',
    zIndex: 101,
  })
);
