import Helper from 'utils/Helper';
import { IDed } from 'gql/Request';
import BastionType from 'gql/BastionType';
import { IterablePaginationResult, PaginationResult, WrapPaginationResultAsIterable } from 'gql/Common';

export default interface Customer extends IDed {
  name: string;
  region: string;
  awsAccountId: string;
  domain: string;
  status: string;
  AwsWorkspacePrefix: string;
  ring: {
    id: string;
    name: string;
  };
  bastionTypes: BastionType[];
  deleted: boolean;
  host: string;
}

/**
 * Return the details for a customer
 */
export const CUSTOMER = `{
  id
  name
  region
  awsAccountId
  domain
  status
  AwsWorkspacePrefix
  ring {
    id 
    name
  }
  bastionTypes
  deleted
  host
}`;

export const CUSTOMER_LITE = `{
  id
  name
  region
  status
  ring {
    id 
    name
  }
  host
  deleted
}`;

/**
 * Return all customers lockbox knows about
 */
const LIST_CUSTOMERS = `
    query listCustomers($filter: CustomerFilterInput, $pagination: PaginationInput) {
      listCustomers(filter: $filter, pagination: $pagination) {
        items ${CUSTOMER_LITE}
        total
        nextToken
    }
  }
`;

const GET_CUSTOMER_BY_ID = `
    query customer($id: String!) {
      customer (id: $id) ${CUSTOMER}
    }
`;

export interface GetCustomersOptions {
  nextToken?: string;
}

/**
 * Calls API for GET_CUSTOMERS
 * @returns Promise<Customer>
 */
export const getCustomers = async (variables?: GetCustomersOptions): Promise<IterablePaginationResult<Customer>> => {
  const result = await Helper.execGQL<PaginationResult<Customer>>({
    query: LIST_CUSTOMERS,
    variables: {
      filter: {
        deleted: false,
      },
      pagination: {
        nextToken: variables?.nextToken,
      },
    },
  });
  const { listCustomers } = result;
  return WrapPaginationResultAsIterable(listCustomers);
};

export const getCustomer = async (variables: { id: string }): Promise<Customer> => {
  const result = await Helper.execGQL<Customer>({
    query: GET_CUSTOMER_BY_ID,
    variables,
  });
  const { customer } = result;
  return customer;
};
