import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Session, { StartSession } from 'gql/Session';
import ActionState, { ActionStatus } from 'store/ActionState';

export type SessionState = {
  session: Session | undefined;
  sessionState: ActionState;
};

export const initialState: SessionState = {
  session: undefined,
  sessionState: { status: ActionStatus.IDLE },
};

const SessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    fetchSignInURL: (state, _: PayloadAction<StartSession>): SessionState => {
      return {
        ...state,
        sessionState: { status: ActionStatus.PROCESSING },
      };
    },
    fetchSignInURLSucceeded: (_, action: PayloadAction<Session>): SessionState => {
      return {
        session: action.payload,
        sessionState: { status: ActionStatus.SUCCEEDED },
      };
    },
    fetchSignInURLFailed: (state, action: PayloadAction<Error>): SessionState => {
      return {
        ...state,
        sessionState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    resetSignInURLState: () => {
      return initialState;
    },
  },
});

export default SessionSlice;
