import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import ActionState, { ActionStatus } from 'store/ActionState';
import { IDed } from 'gql/Request';
import {
  BastionTargetAccess,
  CreateBastionTargetAccess,
  GetBastionTargetAccessOptions,
  ListBastionTargetAccessOptions,
  UpdateBastionTargetAccess,
} from 'gql/BastionTargetAccess';

export type BastionTargetAccessState = {
  bastionTargetAccess?: BastionTargetAccess;
  bastionTargetAccesses: EntityState<BastionTargetAccess>;
  loadingState: ActionState;

  mutationState: ActionState;
};

export const bastionTargetAccessAdapter = createEntityAdapter<BastionTargetAccess>();

export const initialState: BastionTargetAccessState = {
  bastionTargetAccesses: bastionTargetAccessAdapter.getInitialState(),
  loadingState: { status: ActionStatus.IDLE },
  mutationState: { status: ActionStatus.IDLE },
};

const BastionTargetAccessSlice = createSlice({
  name: 'bastionTargetAccess',
  initialState,
  reducers: {
    // GET
    getBastionTargetAccessByID: (state, _: PayloadAction<GetBastionTargetAccessOptions>): BastionTargetAccessState => {
      return { ...state, loadingState: { status: ActionStatus.PROCESSING } };
    },
    getBastionTargetAccessByIDSucceeded: (
      state,
      action: PayloadAction<BastionTargetAccess | undefined>
    ): BastionTargetAccessState => {
      return {
        ...state,
        bastionTargetAccesses: bastionTargetAccessAdapter.upsertMany(
          { ...state.bastionTargetAccesses },
          action.payload ? [action.payload] : []
        ),
        loadingState: { status: ActionStatus.SUCCEEDED },
      };
    },
    getBastionTargetAccessByIDFailed: (state, action: PayloadAction<Error>): BastionTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // LIST
    listBastionTargetAccessForTargetOrGroup: (
      state,
      _: PayloadAction<ListBastionTargetAccessOptions>
    ): BastionTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.PROCESSING },
        bastionTargetAccesses: bastionTargetAccessAdapter.getInitialState(),
      };
    },
    listNextPageBastionTargetAccessForTargetOrGroup: (
      state,
      _: PayloadAction<ListBastionTargetAccessOptions>
    ): BastionTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.PROCESSING },
      };
    },
    listBastionTargetAccessForTargetOrGroupSucceeded: (
      state,
      action: PayloadAction<BastionTargetAccess[]>
    ): BastionTargetAccessState => {
      return {
        ...state,
        bastionTargetAccesses: bastionTargetAccessAdapter.setMany({ ...state.bastionTargetAccesses }, action.payload),
        loadingState: { status: ActionStatus.SUCCEEDED },
      };
    },
    listBastionTargetAccessForTargetOrGroupFailed: (state, action: PayloadAction<Error>): BastionTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // CREATE
    createBastionTargetAccess: (state, _: PayloadAction<CreateBastionTargetAccess>): BastionTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    createBastionTargetAccessSucceeded: (state, _: PayloadAction<IDed>): BastionTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
      };
    },
    createBastionTargetAccessFailed: (state, action: PayloadAction<Error>): BastionTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // UPDATE
    updateBastionTargetAccess: (state, _: PayloadAction<UpdateBastionTargetAccess>): BastionTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    updateBastionTargetAccessSucceeded: (state, _: PayloadAction<IDed>): BastionTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
      };
    },
    updateBastionTargetAccessFailed: (state, action: PayloadAction<Error>): BastionTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // DELETE
    deleteBastionTargetAccess: (state, _: PayloadAction<string>): BastionTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    deleteBastionTargetAccessSucceeded: (state, _: PayloadAction<IDed>): BastionTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
      };
    },
    deleteBastionTargetAccessFailed: (state, action: PayloadAction<Error>): BastionTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // Clear
    clearState: () => {
      return initialState;
    },
  },
});

export default BastionTargetAccessSlice;
