/* eslint-disable max-classes-per-file */
import axios from 'axios';
import Logger from 'logger/Logger';
import Helper, { EndpointType } from 'utils/Helper';

const logger = Logger.getLogger('Config');

// Update the session settings key if the auth setting object changes
export const CONFIG_SESSION_KEY = 'config_v6';

export default interface Config {
  domain: string;
  region: string;
  poolId: string;
  clientId: string;
  idleTimeoutMinutes: number;
  isGovCloud: boolean;
  env: string;
}

export interface ConfigResponse {
  data: Config | undefined;
}

// Get session saved Auth settings
export const getSessionConfig = (): Config => {
  const configRaw: string | null = sessionStorage.getItem(CONFIG_SESSION_KEY);
  return configRaw ? JSON.parse(configRaw) : undefined;
};

// Save Auth session settings
export const setSessionConfig = (config: Config): Config => {
  sessionStorage.setItem(CONFIG_SESSION_KEY, JSON.stringify(config));
  return config;
};

// Return env specific config
export const getAppConfig = async (): Promise<Config | undefined> => {
  let config = getSessionConfig();
  if (!config) {
    let result: ConfigResponse = { data: undefined };
    try {
      result = await axios.get(Helper.getEndpoint(EndpointType.AuthConfig));
    } catch (err) {
      logger.error(err as string);
      return undefined;
    }
    config = setSessionConfig(result.data as Config);
  }
  return config;
};
