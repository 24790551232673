import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import AwsAccountObject, { AwsAccountObjectOptions } from 'gql/AwsAccountObject';
import { IDed } from 'gql/Request';
import ActionState, { ActionStatus } from 'store/ActionState';
import PagerState, { buildPagerState, PagerStatus } from 'store/PagerState';

export type AwsAccountObjectState = {
  awsAccountObject?: AwsAccountObject;

  awsAccountObjects: EntityState<AwsAccountObject> | null;
  fetchAwsAccountObjectsState: ActionState;

  awsAccountObjectForm?: AwsAccountObject;
  saveAwsAccountObjectState: ActionState;

  pagerState: PagerState;
};

export const awsAccountObjectAdapter = createEntityAdapter<AwsAccountObject>();

export const initialState: AwsAccountObjectState = {
  awsAccountObjects: null,
  fetchAwsAccountObjectsState: { status: ActionStatus.IDLE },
  saveAwsAccountObjectState: { status: ActionStatus.IDLE },
  pagerState: { status: PagerStatus.HIDDEN },
};

const AwsAccountObjectSlice = createSlice({
  name: 'awsAccountObject',
  initialState,
  reducers: {
    fetchAwsAccountObjects: (state, _: PayloadAction<AwsAccountObjectOptions>): AwsAccountObjectState => {
      return {
        ...state,
        fetchAwsAccountObjectsState: { status: ActionStatus.PROCESSING },
      };
    },
    fetchAllAwsAccountObjects: (state, _: PayloadAction<AwsAccountObjectOptions>): AwsAccountObjectState => {
      return {
        ...state,
        fetchAwsAccountObjectsState: { status: ActionStatus.PROCESSING },
      };
    },
    fetchAwsAccountObjectsSucceeded: (state, action: PayloadAction<AwsAccountObject[]>): AwsAccountObjectState => {
      const defaultValue = state.awsAccountObjects
        ? state.awsAccountObjects
        : awsAccountObjectAdapter.getInitialState();
      return {
        ...state,
        awsAccountObjects:
          action.payload.length === 0
            ? defaultValue
            : awsAccountObjectAdapter.upsertMany(
                state.awsAccountObjects ? { ...state.awsAccountObjects } : awsAccountObjectAdapter.getInitialState(),
                action.payload
              ),
        fetchAwsAccountObjectsState: { status: ActionStatus.SUCCEEDED },
        pagerState: buildPagerState(state.pagerState, action.payload),
      };
    },
    fetchAwsAccountObjectsFailed: (state, action: PayloadAction<Error>): AwsAccountObjectState => {
      return {
        ...state,
        fetchAwsAccountObjectsState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    saveAwsAccountObject: (state, action: PayloadAction<AwsAccountObject>): AwsAccountObjectState => {
      return {
        ...state,
        awsAccountObjectForm: action.payload,
        saveAwsAccountObjectState: { status: ActionStatus.PROCESSING },
      };
    },
    cancelSaveAwsAccountObject: (state, _: PayloadAction<AwsAccountObject | undefined>): AwsAccountObjectState => {
      return {
        ...state,
        awsAccountObjectForm: undefined,
        saveAwsAccountObjectState: { status: ActionStatus.IDLE },
      };
    },
    saveAwsAccountObjectSucceeded: (state, _: PayloadAction<IDed>): AwsAccountObjectState => {
      return {
        ...state,
        awsAccountObjectForm: undefined,
        saveAwsAccountObjectState: { status: ActionStatus.SUCCEEDED },
      };
    },
    saveAwsAccountObjectFailed: (state, action: PayloadAction<Error>): AwsAccountObjectState => {
      return {
        ...state,
        saveAwsAccountObjectState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
  },
});

export default AwsAccountObjectSlice;
