import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import {
  GetK8sRoleTargetAccessByIdOptions,
  K8sRoleTargetAccess,
  K8sRoleTargetAccessBase,
  ListK8sRoleTargetAccessOptions,
} from 'gql/K8sRoleTargetAccess';
import { IDed } from 'gql/Request';
import ActionState, { ActionStatus } from 'store/ActionState';

export type K8sRoleTargetAccessState = {
  k8sRoleTargetAccesses: EntityState<K8sRoleTargetAccess>;
  loadingState: ActionState;
  mutationState: ActionState;
  mutatedK8sRoleTargetAccessId?: string;
};

export const k8sRoleTargetAccessAdapter = createEntityAdapter<K8sRoleTargetAccess>();

export const initialState: K8sRoleTargetAccessState = {
  k8sRoleTargetAccesses: k8sRoleTargetAccessAdapter.getInitialState(),
  loadingState: { status: ActionStatus.IDLE },
  mutationState: { status: ActionStatus.IDLE },
};

const K8sRoleTargetAccessSlice = createSlice({
  name: 'K8sRoleTargetAccess',
  initialState,
  reducers: {
    // GetById
    getK8sRoleTargetAccessByID: (
      state,
      _: PayloadAction<GetK8sRoleTargetAccessByIdOptions>
    ): K8sRoleTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.PROCESSING },
      };
    },
    getK8sRoleTargetAccessByIDSucceeded: (
      state,
      action: PayloadAction<K8sRoleTargetAccess>
    ): K8sRoleTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.SUCCEEDED },
        k8sRoleTargetAccesses: k8sRoleTargetAccessAdapter.upsertMany({ ...state.k8sRoleTargetAccesses }, [
          action.payload,
        ]),
      };
    },
    getK8sRoleTargetAccessByIDFailed: (state, _: PayloadAction<Error>): K8sRoleTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.FAILED },
      };
    },
    // LIST
    listK8sRoleTargetAccess: (
      state,
      _: PayloadAction<ListK8sRoleTargetAccessOptions | undefined>
    ): K8sRoleTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.PROCESSING },
        k8sRoleTargetAccesses: k8sRoleTargetAccessAdapter.getInitialState(),
      };
    },
    listNextPageK8sRoleTargetAccess: (
      state,
      _: PayloadAction<ListK8sRoleTargetAccessOptions | undefined>
    ): K8sRoleTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.PROCESSING },
      };
    },
    listK8sRoleTargetAccessSucceeded: (
      state,
      action: PayloadAction<K8sRoleTargetAccess[]>
    ): K8sRoleTargetAccessState => {
      return {
        ...state,
        k8sRoleTargetAccesses:
          action.payload.length === 0
            ? state.k8sRoleTargetAccesses
            : k8sRoleTargetAccessAdapter.setMany({ ...state.k8sRoleTargetAccesses }, action.payload),
        loadingState: { status: ActionStatus.SUCCEEDED },
      };
    },
    listK8sRoleTargetAccessFailed: (state, action: PayloadAction<Error>): K8sRoleTargetAccessState => {
      return { ...state, loadingState: { status: ActionStatus.FAILED, error: action.payload } };
    },

    // CREATE
    createK8sRoleTargetAccess: (state, _: PayloadAction<K8sRoleTargetAccessBase>): K8sRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
        mutatedK8sRoleTargetAccessId: undefined,
      };
    },
    createK8sRoleTargetAccessSucceeded: (state, action: PayloadAction<IDed>): K8sRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
        mutatedK8sRoleTargetAccessId: action.payload.id,
      };
    },
    createK8sRoleTargetAccessFailed: (state, action: PayloadAction<Error>): K8sRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // UPDATE
    updateK8sRoleTargetAccess: (state, _: PayloadAction<K8sRoleTargetAccess>): K8sRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    updateK8sRoleTargetAccessSucceeded: (state, action: PayloadAction<IDed>): K8sRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
        mutatedK8sRoleTargetAccessId: action.payload.id,
      };
    },
    updateK8sRoleTargetAccessFailed: (state, action: PayloadAction<Error>): K8sRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // DELETE
    deleteK8sRoleTargetAccess: (state, _: PayloadAction<string>): K8sRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    deleteK8sRoleTargetAccessSucceeded: (state, action: PayloadAction<IDed>): K8sRoleTargetAccessState => {
      return {
        ...state,
        k8sRoleTargetAccesses: k8sRoleTargetAccessAdapter.removeOne(
          { ...state.k8sRoleTargetAccesses },
          action.payload.id
        ),
        mutationState: { status: ActionStatus.SUCCEEDED },
        mutatedK8sRoleTargetAccessId: action.payload.id,
      };
    },
    deleteK8sRoleTargetAccessFailed: (state, action: PayloadAction<Error>): K8sRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    // Clear
    clearState: () => {
      return initialState;
    },
  },
});

export default K8sRoleTargetAccessSlice;
