import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';
import i18next from 'i18next';
import { createScratchpad, getScratchpad, listScratchpads } from 'gql/Scratchpad';
import RootEpic from 'store/RootEpic';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';
import ScratchpadSlice from './ScratchpadSlice';

export const createScratchpadEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(ScratchpadSlice.actions.createScratchpad),
    mergeMap((action) =>
      from(createScratchpad({ create: action.payload })).pipe(
        map((response) => ScratchpadSlice.actions.createScratchpadSucceeded(response)),
        catchError((error) => of(ScratchpadSlice.actions.createScratchpadFailed(error)))
      )
    )
  );

export const createScratchpadSucceededEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(ScratchpadSlice.actions.createScratchpadSucceeded),
    mergeMap(() => [showNotification({ type: 'success', contents: i18next.t('SUCCESS_CREATED_NEW_SCRATCHPAD') })])
  );

export const createScratchpadFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(ScratchpadSlice.actions.createScratchpadFailed),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_CREATE_SCRATCHPAD'), err: payload }))
  );

export const fetchScratchpadEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(ScratchpadSlice.actions.fetchScratchpad),
    mergeMap((action) =>
      from(getScratchpad(action.payload)).pipe(
        map((response) => ScratchpadSlice.actions.fetchScratchpadSucceeded(response)),
        catchError((error) => of(ScratchpadSlice.actions.fetchScratchpadFailed(error)))
      )
    )
  );

export const fetchScratchpadFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(ScratchpadSlice.actions.fetchScratchpadFailed),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_SCRATCHPAD'), err: payload }))
  );

export const fetchScratchpadListEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(ScratchpadSlice.actions.fetchScratchpadList),
    mergeMap((action) =>
      from(listScratchpads(action.payload)).pipe(
        map((response) => ScratchpadSlice.actions.fetchScratchpadListSucceeded(response)),
        catchError((error) => of(ScratchpadSlice.actions.fetchScratchpadListFailed(error)))
      )
    )
  );

export const fetchScratchpadListFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(ScratchpadSlice.actions.fetchScratchpadListFailed),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_SCRATCHPADS'), err: payload }))
  );

const ScratchpadEpics: RootEpic[] = [
  createScratchpadEpic,
  createScratchpadSucceededEpic,
  createScratchpadFailedEpic,
  fetchScratchpadEpic,
  fetchScratchpadFailedEpic,
  fetchScratchpadListEpic,
  fetchScratchpadListFailedEpic,
];

export default ScratchpadEpics;
