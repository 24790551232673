import { createSlice, createEntityAdapter, PayloadAction, EntityState } from '@reduxjs/toolkit';
import ActionState, { ActionStatus } from 'store/ActionState';
import KubernetesClusterObject from 'gql/KubernetesCluster';

export type KubernetesClusterState = {
  kubernetesCluster?: KubernetesClusterObject;
  kubernetesClusters: EntityState<KubernetesClusterObject> | null;
  fetchClustersState: ActionState;
};

export const clusterAdapter = createEntityAdapter<KubernetesClusterObject>();

export const initialState: KubernetesClusterState = {
  kubernetesClusters: null,
  fetchClustersState: { status: ActionStatus.IDLE },
};

const KubernetesClusterSlice = createSlice({
  name: 'cluster',
  initialState,
  reducers: {
    fetchClusters: (state, _: PayloadAction): KubernetesClusterState => {
      return { ...state, fetchClustersState: { status: ActionStatus.PROCESSING } };
    },
    fetchClustersSucceeded: (state, action: PayloadAction<KubernetesClusterObject[]>): KubernetesClusterState => {
      return {
        ...state,
        kubernetesClusters: clusterAdapter.setAll(
          state.kubernetesClusters ? { ...state.kubernetesClusters } : clusterAdapter.getInitialState(),
          action.payload
        ),
        fetchClustersState: { status: ActionStatus.SUCCEEDED },
      };
    },
    fetchClustersFailed: (state, action: PayloadAction<Error>): KubernetesClusterState => {
      return {
        ...state,
        fetchClustersState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
  },
});

export default KubernetesClusterSlice;
