import React from 'react';
import { Stack } from '@tanium/react-stack';
import { Box } from '@tanium/react-box';
import { DefinitionList, DefinitionListItem } from '@tanium/react-definition-list';
import { Modal } from '@tanium/react-modal';
import { Button } from '@tanium/react-button';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'components/common/copyToClipboard/copyToClipboard';
import MediumBoldText from 'components/styles/text/MediumBoldText';
import MediumText from 'components/styles/text/MediumText';

interface Params {
  auditModalData?: string;
  closeModal: () => void;
}

// This page manages the audit modal
const AuditModal = ({ auditModalData, closeModal }: Params): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={!!auditModalData}
      header={t('AUDIT_METADATA')}
      footer={
        <Stack inline itemSpacing={1}>
          <Button
            data-testid="close"
            variant="secondary"
            onClick={(): void => {
              closeModal();
            }}
          >
            {t('CLOSE')}
          </Button>
        </Stack>
      }
    >
      <Box pl={7}>
        <Box overflow={'auto'}>
          <DefinitionList>
            <DefinitionListItem
              term={<MediumBoldText>{t('AUDIT_JSON')}:</MediumBoldText>}
              description={
                <pre>
                  <MediumText>{auditModalData ? JSON.stringify(JSON.parse(auditModalData), null, 2) : '{}'}</MediumText>
                </pre>
              }
            />
          </DefinitionList>
          <br />
        </Box>
        <CopyToClipboard displayedText={t('COPY_TO_CLIPBOARD')} copyText={auditModalData} />
      </Box>
    </Modal>
  );
};

export default AuditModal;
