import i18next from 'i18next';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { getAvailableTargetTypes } from 'gql/TargetType';
import { showNotificationFromError } from 'components/app/store/UserSlice';
import RootEpic from 'store/RootEpic';
import TargetTypesSlice from './TargetTypeSlice';

export const fetchAvailableTargetTypesEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(TargetTypesSlice.actions.fetchAvailableTargetTypes),
    mergeMap(() =>
      from(getAvailableTargetTypes()).pipe(
        map((response) => TargetTypesSlice.actions.fetchAvailableTargetTypesSucceeded(response)),
        catchError(() => of(TargetTypesSlice.actions.fetchAvailableTargetTypesFailed()))
      )
    )
  );

export const fetchAvailableTargetTypeFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(TargetTypesSlice.actions.fetchAvailableTargetTypesFailed),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_GET_AVAILABLE_ACCESS_TYPES'), err: payload })
    )
  );

const TargetTypeEpics: RootEpic[] = [fetchAvailableTargetTypesEpic, fetchAvailableTargetTypeFailedEpic];

export default TargetTypeEpics;
