import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import ActionState, { ActionStatus } from 'store/ActionState';
import { IDed } from 'gql/Request';
import {
  AwsRoleTargetAccess,
  CreateAwsRoleTargetAccess,
  GetAwsRoleTargetAccessOptions,
  ListAwsRoleTargetAccessOptions,
  UpdateAwsRoleTargetAccess,
} from 'gql/AwsRoleTargetAccess';

export type AwsRoleTargetAccessState = {
  awsRoleTargetAccess?: AwsRoleTargetAccess;
  awsRoleTargetAccesses: EntityState<AwsRoleTargetAccess>;
  loadingState: ActionState;

  mutationState: ActionState;
};

export const awsRoleTargetAccessAdapter = createEntityAdapter<AwsRoleTargetAccess>();

export const initialState: AwsRoleTargetAccessState = {
  awsRoleTargetAccesses: awsRoleTargetAccessAdapter.getInitialState(),
  loadingState: { status: ActionStatus.IDLE },
  mutationState: { status: ActionStatus.IDLE },
};

const AwsRoleTargetAccessSlice = createSlice({
  name: 'awsRoleTargetAccess',
  initialState,
  reducers: {
    // GET
    getAwsRoleTargetAccessByID: (state, _: PayloadAction<GetAwsRoleTargetAccessOptions>): AwsRoleTargetAccessState => {
      return { ...state, loadingState: { status: ActionStatus.PROCESSING } };
    },
    getAwsRoleTargetAccessByIDSucceeded: (
      state,
      action: PayloadAction<AwsRoleTargetAccess | undefined>
    ): AwsRoleTargetAccessState => {
      return {
        ...state,
        awsRoleTargetAccesses: awsRoleTargetAccessAdapter.upsertMany(
          { ...state.awsRoleTargetAccesses },
          action.payload ? [action.payload] : []
        ),
        loadingState: { status: ActionStatus.SUCCEEDED },
      };
    },
    getAwsRoleTargetAccessByIDFailed: (state, action: PayloadAction<Error>): AwsRoleTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // LIST
    listAwsRoleTargetAccessForTargetOrGroup: (
      state,
      _: PayloadAction<ListAwsRoleTargetAccessOptions>
    ): AwsRoleTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.PROCESSING },
        awsRoleTargetAccesses: awsRoleTargetAccessAdapter.getInitialState(),
      };
    },
    listNextPageAwsRoleTargetAccessForTargetOrGroup: (
      state,
      _: PayloadAction<ListAwsRoleTargetAccessOptions>
    ): AwsRoleTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.PROCESSING },
      };
    },
    listAwsRoleTargetAccessForTargetOrGroupSucceeded: (
      state,
      action: PayloadAction<AwsRoleTargetAccess[]>
    ): AwsRoleTargetAccessState => {
      return {
        ...state,
        awsRoleTargetAccesses: awsRoleTargetAccessAdapter.setMany({ ...state.awsRoleTargetAccesses }, action.payload),
        loadingState: { status: ActionStatus.SUCCEEDED },
      };
    },
    listAwsRoleTargetAccessForTargetOrGroupFailed: (state, action: PayloadAction<Error>): AwsRoleTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // CREATE
    createAwsRoleTargetAccess: (state, _: PayloadAction<CreateAwsRoleTargetAccess>): AwsRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    createAwsRoleTargetAccessSucceeded: (state, _: PayloadAction<IDed>): AwsRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
      };
    },
    createAwsRoleTargetAccessFailed: (state, action: PayloadAction<Error>): AwsRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // UPDATE
    updateAwsRoleTargetAccess: (state, _: PayloadAction<UpdateAwsRoleTargetAccess>): AwsRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    updateAwsRoleTargetAccessSucceeded: (state, _: PayloadAction<IDed>): AwsRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
      };
    },
    updateAwsRoleTargetAccessFailed: (state, action: PayloadAction<Error>): AwsRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // DELETE
    deleteAwsRoleTargetAccess: (state, _: PayloadAction<string>): AwsRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    deleteAwsRoleTargetAccessSucceeded: (state, _: PayloadAction<IDed>): AwsRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
      };
    },
    deleteAwsRoleTargetAccessFailed: (state, action: PayloadAction<Error>): AwsRoleTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // Clear
    clearState: () => {
      return initialState;
    },
  },
});

export default AwsRoleTargetAccessSlice;
