/* eslint-disable @typescript-eslint/restrict-template-expressions */
import BastionType from 'gql/BastionType';
import IAccessConfiguration from 'common/IAccessConfiguration';
import { IDed } from 'gql/Request';
import Helper from 'utils/Helper';
import { LITE_REQUEST_ACCESS } from 'gql/TargetGroup';
import { Tag } from './Common';
import BaseTarget from './BaseTarget';

export const AwsPartitionId = 'aws';
export const AwsUsGovPartitionId = 'aws-us-gov';

export const AwsPartitions = [
  { label: AwsPartitionId, value: AwsPartitionId },
  { label: AwsUsGovPartitionId, value: AwsUsGovPartitionId },
];

export default interface AwsAccountObject extends BaseTarget {
  awsAccountID: string;
  awsAccountAlias: string;
  awsPartition: string;
  accessRoleARN: string;
  accessRoleExternalID?: string;
  accessConfiguration?: IAccessConfiguration[];
  bastionTypes: BastionType[];
  nextToken?: string;
}

export interface AwsAccountObjectFormObject {
  id?: string;
  awsAccountId: string;
  awsPartition: string;
  name: string;
  description?: string;
  tier: string;
  approvalSlackChannel?: string;
  notifyAutoApproval?: boolean;
  enablePaging: boolean;
  teamRole?: string;
  managersADGroups?: string;
  orgLeaderRole?: string;
  limitedAccessRoles?: string;
  accessRequiresBastion?: boolean;
  accessConfigurationArr: string;
  accessRoleARN: string;
  accessRoleExternalID?: string;
  tags?: string;
}

interface CreateAWSAccountObjectInput {
  awsAccountID: string;
  awsPartition: string;
  name: string;
  description?: string;
  tier: string;
  approvalSlackChannel?: string;
  notifyAutoApproval?: boolean;
  enablePaging: boolean;
  teamRole?: string;
  managersADGroups?: string;
  orgLeaderRole?: string;
  limitedAccessRoles?: string[];
  accessRequiresBastion?: boolean;
  accessRoleARN: string;
  accessRoleExternalID?: string;
  accessConfiguration?: IAccessConfiguration[];
  tags?: Tag[];
}

interface UpdateAWSAccountObjectInput extends IDed {
  awsPartition: string;
  name: string;
  description?: string;
  tier: string;
  approvalSlackChannel?: string;
  notifyAutoApproval?: boolean;
  enablePaging: boolean;
  teamRole?: string;
  managersADGroups?: string;
  orgLeaderRole?: string;
  limitedAccessRoles?: string[];
  accessRequiresBastion?: boolean;
  accessRoleARN: string;
  accessRoleExternalID?: string;
  accessConfiguration?: IAccessConfiguration[];
  tags?: Tag[];
}

export const mapToAwsAccountObject = (awsAccountObjectFormObject: AwsAccountObjectFormObject): AwsAccountObject => {
  return {
    id: awsAccountObjectFormObject.id || '',
    awsAccountID: awsAccountObjectFormObject.awsAccountId,
    awsPartition: awsAccountObjectFormObject.awsPartition,
    name: awsAccountObjectFormObject.name,
    description: awsAccountObjectFormObject.description,
    tier: awsAccountObjectFormObject.tier,
    tags: awsAccountObjectFormObject.tags ? JSON.parse(awsAccountObjectFormObject.tags) : [],
    approvalSlackChannel: awsAccountObjectFormObject.approvalSlackChannel,
    notifyAutoApproval: awsAccountObjectFormObject.notifyAutoApproval,
    enablePaging: awsAccountObjectFormObject.enablePaging,
    teamRole: awsAccountObjectFormObject.teamRole,
    managersADGroups: awsAccountObjectFormObject.managersADGroups,
    orgLeaderRole: awsAccountObjectFormObject.orgLeaderRole,
    limitedAccessRoles: Helper.safeConvertOptionalStringToArray(awsAccountObjectFormObject.limitedAccessRoles),
    accessRequiresBastion: awsAccountObjectFormObject.accessRequiresBastion,
    accessRoleARN: awsAccountObjectFormObject.accessRoleARN,
    accessRoleExternalID: awsAccountObjectFormObject.accessRoleExternalID,
    accessConfiguration: JSON.parse(awsAccountObjectFormObject.accessConfigurationArr || JSON.stringify([], null, 2)),
  } as AwsAccountObject;
};

export const mapToFormObject = (awsAccountObject: AwsAccountObject): AwsAccountObjectFormObject => {
  return {
    id: awsAccountObject.id ? awsAccountObject.id : undefined,
    awsAccountId: awsAccountObject.awsAccountID,
    awsPartition: awsAccountObject.awsPartition,
    name: awsAccountObject.name,
    description: awsAccountObject.description,
    tier: awsAccountObject.tier || '',
    tags: awsAccountObject.tags ? JSON.stringify(awsAccountObject.tags) : '',
    approvalSlackChannel: awsAccountObject.approvalSlackChannel,
    notifyAutoApproval: awsAccountObject.notifyAutoApproval == null ? true : awsAccountObject.notifyAutoApproval,
    enablePaging: awsAccountObject.enablePaging,
    teamRole: awsAccountObject.teamRole,
    managersADGroups: awsAccountObject.managersADGroups,
    orgLeaderRole: awsAccountObject.orgLeaderRole,
    limitedAccessRoles: awsAccountObject.limitedAccessRoles?.join(', '),
    accessRequiresBastion: awsAccountObject.accessRequiresBastion,
    accessRoleARN: awsAccountObject.accessRoleARN,
    accessRoleExternalID: awsAccountObject.accessRoleExternalID,
    accessConfigurationArr: JSON.stringify(awsAccountObject.accessConfiguration || [], null, 2),
  };
};

const mapToCreateInput = (awsAccountObject: AwsAccountObject): CreateAWSAccountObjectInput => {
  return {
    awsAccountID: awsAccountObject.awsAccountID,
    awsPartition: awsAccountObject.awsPartition,
    name: awsAccountObject.name,
    description: awsAccountObject.description,
    tier: awsAccountObject.tier,
    tags: awsAccountObject.tags,
    approvalSlackChannel: awsAccountObject.approvalSlackChannel,
    notifyAutoApproval: awsAccountObject.notifyAutoApproval,
    enablePaging: awsAccountObject.enablePaging,
    teamRole: awsAccountObject.teamRole,
    managersADGroups: awsAccountObject.managersADGroups,
    orgLeaderRole: awsAccountObject.orgLeaderRole,
    limitedAccessRoles: awsAccountObject.limitedAccessRoles,
    accessRequiresBastion: awsAccountObject.accessRequiresBastion,
    accessConfiguration: awsAccountObject.accessConfiguration,
    accessRoleARN: awsAccountObject.accessRoleARN,
    accessRoleExternalID: awsAccountObject.accessRoleExternalID,
  };
};

const mapToUpdateInput = (awsAccountObject: AwsAccountObject): UpdateAWSAccountObjectInput => {
  return {
    id: awsAccountObject.id,
    awsPartition: awsAccountObject.awsPartition,
    name: awsAccountObject.name,
    description: awsAccountObject.description,
    tier: awsAccountObject.tier,
    tags: awsAccountObject.tags,
    approvalSlackChannel: awsAccountObject.approvalSlackChannel,
    notifyAutoApproval: awsAccountObject.notifyAutoApproval,
    enablePaging: awsAccountObject.enablePaging,
    teamRole: awsAccountObject.teamRole,
    managersADGroups: awsAccountObject.managersADGroups,
    orgLeaderRole: awsAccountObject.orgLeaderRole,
    limitedAccessRoles: awsAccountObject.limitedAccessRoles,
    accessRequiresBastion: awsAccountObject.accessRequiresBastion,
    accessConfiguration: awsAccountObject.accessConfiguration,
    accessRoleARN: awsAccountObject.accessRoleARN,
    accessRoleExternalID: awsAccountObject.accessRoleExternalID,
  };
};

export const AWS_ACCOUNT_OBJECT = `
{
  id
  awsAccountID
  awsAccountAlias
  awsPartition
  name
  description
  tier
  approvalSlackChannel
  notifyAutoApproval
  enablePaging
  teamRole
  managersADGroups
  orgLeaderRole
  limitedAccessRoles
  accessRequiresBastion
  accessRoleARN
  accessConfiguration {
    level
    access ${LITE_REQUEST_ACCESS}
  }
  tags {
    key
    value
  }
  bastionTypes
  deleted
  nextToken
}
`;

const GET_AWS_ACCOUNT_OBJECTS = `
  query awsAccountObjects($viewAllRoles: Boolean, $nextToken: String) {
    awsAccountObjects(viewAllRoles: $viewAllRoles, nextToken: $nextToken) ${AWS_ACCOUNT_OBJECT}
  }
`;

export interface AwsAccountObjectOptions {
  viewAllRoles: boolean;
  nextToken?: string;
}

export const getAwsAccountObjects = async (variables: AwsAccountObjectOptions): Promise<AwsAccountObject[]> => {
  const result = await Helper.execGQL<AwsAccountObject[]>({
    query: GET_AWS_ACCOUNT_OBJECTS,
    variables: {
      ...variables,
    },
  });
  let { awsAccountObjects } = result;
  if (result.awsAccountObjects) {
    awsAccountObjects = result.awsAccountObjects.filter((awsAccountObject) => {
      return !awsAccountObject.deleted;
    });
  }
  return awsAccountObjects || [];
};

const CREATE_AWS_ACCOUNT_OBJECT = `
  mutation createAWSAccountObject($AWSAccountObject: CreateAWSAccountObjectInput!) {
    createAWSAccountObject (AWSAccountObject: $AWSAccountObject) {
      id
    }
  }
`;

const UPDATE_AWS_ACCOUNT_OBJECT = `
  mutation updateAWSAccountObject($AWSAccountObject: UpdateAWSAccountObjectInput!) {
    updateAWSAccountObject (AWSAccountObject: $AWSAccountObject) {
      id
    }
  }
`;

export const saveAwsAccountObject = async (awsAccountObject: AwsAccountObject): Promise<IDed | undefined> => {
  return awsAccountObject.id ? updateAwsAccountObject(awsAccountObject) : createAwsAccountObject(awsAccountObject);
};

const createAwsAccountObject = async (awsAccountObject: AwsAccountObject): Promise<IDed | undefined> => {
  const result = await Helper.execGQL<IDed>({
    query: CREATE_AWS_ACCOUNT_OBJECT,
    variables: {
      AWSAccountObject: mapToCreateInput(awsAccountObject),
    },
  });

  return result.createAWSAccountObject;
};

const updateAwsAccountObject = async (awsAccountObject: AwsAccountObject): Promise<IDed | undefined> => {
  const result = await Helper.execGQL<IDed>({
    query: UPDATE_AWS_ACCOUNT_OBJECT,
    variables: {
      AWSAccountObject: mapToUpdateInput(awsAccountObject),
    },
  });

  return result.updateAWSAccountObject;
};
