import { mergeMap, catchError, map, filter } from 'rxjs/operators';
import { from, of } from 'rxjs';
import i18next from 'i18next';
import { getRequestAccess } from 'gql/RequestAccess';
import RequestAccessSlice from 'components/app/store/requestAccess/RequestAccessSlice';
import RootEpic from 'store/RootEpic';

export const fetchRequestAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestAccessSlice.actions.fetchRequestAccess.match),
    mergeMap(() =>
      from(getRequestAccess()).pipe(
        map((response) => RequestAccessSlice.actions.fetchRequestAccessSucceeded(response)),
        catchError((error) => of(RequestAccessSlice.actions.fetchRequestAccessFailed(error)))
      )
    )
  );

export const fetchRequestAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(RequestAccessSlice.actions.fetchRequestAccessFailed.match),
    map(({ payload }) =>
      RequestAccessSlice.actions.showNotificationFromError({
        msg: i18next.t('FAILED_GET_REQUEST'),
        err: payload,
      })
    )
  );

const RequestAccessEpics: RootEpic[] = [fetchRequestAccessEpic, fetchRequestAccessFailedEpic];

export default RequestAccessEpics;
