import { connectRouter, RouterState } from 'connected-react-router';
import { AnyAction, CombinedState, combineReducers, Reducer } from 'redux';
import { History } from 'history';
import UserSlice, { UserState } from 'components/app/store/UserSlice';
import ConsoleSlice, { ConsoleState } from 'components/pages/console/store/ConsoleSlice';
import RequestSlice, { RequestState } from 'components/pages/request/store/RequestSlice';
import SessionSlice, { SessionState } from 'components/pages/request/store/session/SessionSlice';
import ServiceSlice, { GlobalServiceState } from 'components/pages/console/store/GlobalServiceSlice';
import RequestAccessSlice, { RequestAccessState } from 'components/app/store/requestAccess/RequestAccessSlice';
import CustomerSlice, { CustomerState } from 'store/Customer/CustomerSlice';
import AwsAccountObjectSlice, { AwsAccountObjectState } from 'components/pages/console/store/AwsAccountObjectSlice';
import KubernetesClusterSlice, { KubernetesClusterState } from 'components/pages/console/store/KubernetesClusterSlice';
import TargetTypesSlice, { TargetTypesState } from 'components/pages/console/store/TargetTypeSlice';
import FileUploadSlice, { FileUploadState } from 'components/app/store/fileUpload/FileUploadSlice';
import DependencyArtifactsSlice, {
  DependencyArtifactsState,
} from 'components/app/store/dependencyArtifacts/DependencyArtifactsSlice';
import RestartInstanceSlice, { RestartInstanceState } from 'components/app/store/restartInstance/RestartInstanceSlice';
import TargetGroupSlice, { TargetGroupState } from 'components/pages/console/store/TargetGroupSlice';
import ScratchpadSlice, { ScratchpadState } from 'components/pages/request/store/scratchpad/ScratchpadSlice';
import K8sRoleTargetAccessSlice, {
  K8sRoleTargetAccessState,
} from 'components/pages/console/store/K8sRoleTargetAccessSlice';
import AwsRoleTargetAccessSlice, {
  AwsRoleTargetAccessState,
} from 'components/pages/console/store/AwsRoleTargetAccessSlice';
import HostTargetAccessSlice, { HostTargetAccessState } from 'components/pages/console/store/HostTargetAccessSlice';
import BastionTargetAccessSlice, {
  BastionTargetAccessState,
} from 'components/pages/console/store/BastionTargetAccessSlice';
import ChangeRequestSlice, { ChangeRequestState } from 'components/pages/console/store/ChangeRequestSlice';
import SSMParameterSlice, {
  SSMParameterState,
} from 'components/pages/adminPanel/store/ssmParameters/SSMParameterSlice';
import WorkflowSlice, { RestartWorkflowRequestState } from 'components/pages/adminPanel/store/workflow/WorkflowSlice';
import StepFunctionExecutionSlice, {
  StepFunctionExecutionState,
} from 'components/pages/adminPanel/store/stepFunctionExecutions/StepFunctionExecutionSlice';

// Root level reducer builder
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const RootReducer = (history: History): Reducer<RootState, AnyAction> => {
  return combineReducers({
    router: connectRouter(history),
    user: UserSlice.reducer,
    console: ConsoleSlice.reducer,
    request: RequestSlice.reducer,
    session: SessionSlice.reducer,
    service: ServiceSlice.reducer,
    awsAccountObject: AwsAccountObjectSlice.reducer,
    requestAccess: RequestAccessSlice.reducer,
    targetTypes: TargetTypesSlice.reducer,
    fileUpload: FileUploadSlice.reducer,
    restartInstance: RestartInstanceSlice.reducer,
    dependencyArtifacts: DependencyArtifactsSlice.reducer,
    customer: CustomerSlice.reducer,
    cluster: KubernetesClusterSlice.reducer,
    targetGroup: TargetGroupSlice.reducer,
    k8sRoleTargetAccess: K8sRoleTargetAccessSlice.reducer,
    scratchpad: ScratchpadSlice.reducer,
    awsRoleTargetAccess: AwsRoleTargetAccessSlice.reducer,
    hostTargetAccess: HostTargetAccessSlice.reducer,
    bastionTargetAccess: BastionTargetAccessSlice.reducer,
    changeRequest: ChangeRequestSlice.reducer,
    ssmParameter: SSMParameterSlice.reducer,
    workflow: WorkflowSlice.reducer,
    stepFunctionExecution: StepFunctionExecutionSlice.reducer,
  });
};

// Root state for entire app.
export type RootState = CombinedState<{
  targetTypes: TargetTypesState;
  router: RouterState<unknown>;
  user: UserState;
  console: ConsoleState;
  request: RequestState;
  session: SessionState;
  service: GlobalServiceState;
  awsAccountObject: AwsAccountObjectState;
  requestAccess: RequestAccessState;
  fileUpload: FileUploadState;
  restartInstance: RestartInstanceState;
  dependencyArtifacts: DependencyArtifactsState;
  customer: CustomerState;
  cluster: KubernetesClusterState;
  targetGroup: TargetGroupState;
  k8sRoleTargetAccess: K8sRoleTargetAccessState;
  scratchpad: ScratchpadState;
  awsRoleTargetAccess: AwsRoleTargetAccessState;
  hostTargetAccess: HostTargetAccessState;
  bastionTargetAccess: BastionTargetAccessState;
  changeRequest: ChangeRequestState;
  ssmParameter: SSMParameterState;
  workflow: RestartWorkflowRequestState;
  stepFunctionExecution: StepFunctionExecutionState;
}>;

export default RootReducer;
