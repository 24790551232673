import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ActionState, { ActionStatus } from 'store/ActionState';
import { IDed } from 'gql/Request';
import { RestartInstanceRequest, RestartInstanceResponse } from 'gql/RestartInstance';

export type RestartInstanceState = {
  restartInstance?: RestartInstanceResponse;
  instanceDetails?: RestartInstanceRequest;
  restartInstanceState?: ActionState;
};

export const initialState: RestartInstanceState = {
  restartInstance: undefined,
  instanceDetails: undefined,
  restartInstanceState: { status: ActionStatus.IDLE },
};

const RestartInstanceSlice = createSlice({
  name: 'restartInstance',
  initialState,
  reducers: {
    restartInstance: (state, action: PayloadAction<RestartInstanceRequest>) => {
      return {
        ...state,
        instanceDetails: { requestID: action.payload.requestID, instanceID: action.payload.instanceID },
        restartInstanceState: { status: ActionStatus.PROCESSING },
      };
    },
    restartInstanceFail: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        restartInstanceState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    restartInstanceSuccess: (state, _: PayloadAction<IDed>) => {
      return {
        ...state,
        restartInstanceState: { status: ActionStatus.SUCCEEDED },
      };
    },
    resetRestartInstanceState: () => {
      return initialState;
    },
  },
});

export const { restartInstance, restartInstanceFail, restartInstanceSuccess } = RestartInstanceSlice.actions;

export default RestartInstanceSlice;
