import Helper from 'utils/Helper';
import { IDed } from 'gql/Request';
import { PaginationResult, SingletonResult } from './Common';

export default interface ScratchpadItem extends IDed {
  createdBy: string;
  createdAt: string;
  requestId: string;
  content: string;
}

export const SCRATCHPADITEM = `{
  id
  createdBy
  createdAt
  requestId
  content
}`;

export interface CreateScratchpadInput {
  requestId: string;
  content: string;
}

export interface CreateScratchpad {
  create: CreateScratchpadInput;
}

const CREATE_SCRATCHPAD = `
  mutation createScratchpad($create: CreateScratchpadInput!) {
      createScratchpad (create: $create) {
        item ${SCRATCHPADITEM}
      }
  }
`;

export const createScratchpad = async (variables: { create: CreateScratchpad }): Promise<ScratchpadItem> => {
  const result = await Helper.execGQL<SingletonResult<ScratchpadItem>>({
    query: CREATE_SCRATCHPAD,
    variables,
  });
  return result.createScratchpad.item;
};

const GET_SCRATCHPAD = `
  query getScratchpad($scratchpadId: String!, $requestId: String!) {
    getScratchpad (id: $id) {
      item ${SCRATCHPADITEM}
    }
  }
`;

export const getScratchpad = async (input: { scratchpadId: string; requestId: string }): Promise<ScratchpadItem> => {
  const result = await Helper.execGQL<SingletonResult<ScratchpadItem>>({
    query: GET_SCRATCHPAD,
    variables: {
      scratchpadId: input.scratchpadId,
      requestId: input.requestId,
    },
  });
  return result.getScratchpad.item;
};

export interface ScratchpadFilterInput {
  requestId: string;
  size: number;
  nextToken: string;
}

const LIST_SCRATCHPAD = `
  query listScratchpads($filter: ScratchpadFilterInput, $pagination: PaginationInput) {
    listScratchpads(filter: $filter, pagination: $pagination){
      items ${SCRATCHPADITEM}
      total
      nextToken
    }
  }
`;

export const listScratchpads = async (options: ScratchpadFilterInput): Promise<ScratchpadItem[]> => {
  let result = await Helper.execGQL<PaginationResult<ScratchpadItem>>({
    query: LIST_SCRATCHPAD,
    variables: {
      filter: {
        requestId: options.requestId,
      },
      pagination: {
        size: options.size,
        nextToken: options.nextToken,
      },
    },
  });

  const scratchpads = [...result.listScratchpads.items];
  let next = result.listScratchpads.nextToken;

  while (next && next !== '') {
    // eslint-disable-next-line no-await-in-loop
    result = await Helper.execGQL<PaginationResult<ScratchpadItem>>({
      query: LIST_SCRATCHPAD,
      variables: {
        filter: {
          requestId: options.requestId,
        },
        pagination: {
          size: options.size,
          nextToken: next,
        },
      },
    });

    if (result && result.listScratchpads) {
      scratchpads.push(...result.listScratchpads.items);
    }
    next = result && result.listScratchpads ? result.listScratchpads.nextToken : undefined;
  }

  return scratchpads;
};
