import { EntityState, EntityAdapter } from '@reduxjs/toolkit';
import { RootState } from 'store/RootReducer';

// non-exported types from redux-toolkit
type EntityId = number | string;
interface EntitySelectors<T, V> {
  selectIds: (state: V) => EntityId[];
  selectEntities: (state: V) => { [id: string]: T | undefined; [id: number]: T | undefined };
  selectAll: (state: V) => T[];
  selectTotal: (state: V) => number;
  selectById: (id: EntityId) => (state: V) => T | undefined;
}

/**
 * Returns entityAdapter selectors, replacing the selectById selector with a function that accepts an id and produces a selector function.
 * This allows the component to conveniently use the selector as follows:
 * @example
 * // use this:
 * const entity = useSelector(selectById(entityId));
 * // instead of this:
 * const entity = useSelector(state => selectById(state, entityId));
 * @param entityAdapter
 * @param selectState
 */
const generateSelectors = <TEntity, TState extends EntityState<TEntity>>(
  entityAdapter: EntityAdapter<TEntity>,
  selectState: (state: RootState) => TState
): EntitySelectors<TEntity, RootState> => {
  const selectors = entityAdapter.getSelectors<RootState>(selectState);
  const selectById =
    (id: EntityId) =>
    (state: RootState): TEntity | undefined =>
      selectors.selectById(state, id);
  return { ...selectors, selectById };
};

/**
 * Reconcile existing record state using paging
 * @param existingRecords - from state
 * @param incomingRecords - from event payload
 * @param offset - where the incomingAuditRecords fit in the dataset
 * @return the final record state
 */
const buildPagedRecordState = <T>(existingRecords: T[], incomingRecords?: T[], offset = 0): T[] => {
  const newPage = incomingRecords || [];
  if (offset > 0) {
    // Expand the array if the new page is larger
    let newRecords = [...existingRecords];
    const additionalElementCount = offset + newPage.length - newRecords.length;
    if (additionalElementCount > 0) {
      newRecords = newRecords.concat(new Array(additionalElementCount));
    }
    // Splice in the new records where they belong in the array
    newRecords.splice(offset, newPage.length, ...newPage);
    return newRecords;
  }
  return newPage;
};

const SelectorUtils = {
  generateSelectors,
  buildPagedRecordState,
};

export default SelectorUtils;
