import React from 'react';
import { CircleIcon } from '@tanium/react-composed-icons';
import { CheckIcon, ClockIcon, Icon, StateCriticalIcon, StateWarningIcon } from '@tanium/react-graphics';
import { useTheme } from '@tanium/react-theme-context';
import { ThemeColors } from '@tanium/theme-data';

export enum IIconTypes {
  ERROR,
  INFO,
  PENDING,
  SUCCESS,
  WARNING,
}
interface Props {
  type: IIconTypes;
  props?: any;
}

const getCircleIcon =
  (icon: Icon, color: ThemeColors, childProps = {}) =>
  (): React.ReactElement => {
    const { colors } = useTheme();
    return <CircleIcon icon={icon} backgroundColor={colors[color]} iconColor={colors.background} {...childProps} />;
  };

const StatusIcon = ({ type, props }: Props): React.ReactElement => {
  switch (type) {
    case IIconTypes.SUCCESS:
      return getCircleIcon(CheckIcon, ThemeColors.Success, props)();
    case IIconTypes.WARNING:
      return getCircleIcon(StateWarningIcon, ThemeColors.Warning, props)();
    case IIconTypes.ERROR:
      return getCircleIcon(StateCriticalIcon, ThemeColors.Error, props)();
    case IIconTypes.PENDING:
      return getCircleIcon(ClockIcon, ThemeColors.Pending, props)();
    case IIconTypes.INFO:
      return getCircleIcon(StateCriticalIcon, ThemeColors.InputLabel, props)();
    default:
      return <></>;
  }
};

export default StatusIcon;
