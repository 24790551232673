import Helper from 'utils/Helper';

export interface DependencyArtifact {
  key: string;
  downloadURL: string;
}

export interface DependencyArtifactInput {
  OS: string;
}

const GET_DEPENDENCY_ARTIFACTS = `
    query getDependencyArtifacts($OS: String) {
      getDependencyArtifacts(OS: $OS) {
        key
        downloadURL
      }
    }
  `;

export const getDependencyArtifacts = async (variables: DependencyArtifactInput): Promise<DependencyArtifact[]> => {
  const response = await Helper.execGQL<DependencyArtifact[]>({
    query: GET_DEPENDENCY_ARTIFACTS,
    variables,
  });
  return response.getDependencyArtifacts;
};
