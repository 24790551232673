/* eslint-disable @typescript-eslint/restrict-template-expressions */
import Helper from 'utils/Helper';
import IAccessConfiguration from 'common/IAccessConfiguration';
import { LITE_REQUEST_ACCESS } from 'gql/TargetGroup';
import { IDed } from './Request';

export default interface KubernetesClusterObject extends IDed {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  name: string;
  URL: string;
  roles: string[];
  namespaces: string[];
  accessConfiguration?: IAccessConfiguration[];
  deleted: boolean;
}
export interface GetClustersFilter {
  name?: string;
}

export const KUBERENETES_CLUSTER = `
{
  id
  name
  URL
  createdBy
  createdAt
  updatedBy
  updatedAt
  accessConfiguration {
    level
    access ${LITE_REQUEST_ACCESS}
  }
  deleted
}`;

const GET_CLUSTERS = `
  query kubernetesClusters {
    kubernetesClusters ${KUBERENETES_CLUSTER}
  }
`;

export const getClusters = async (filter: GetClustersFilter): Promise<KubernetesClusterObject[]> => {
  const result = await Helper.execGQL<KubernetesClusterObject[]>({
    query: GET_CLUSTERS,
    variables: {
      ...filter,
    },
  });
  let { kubernetesClusters } = result;
  if (result.kubernetesClusters) {
    kubernetesClusters = result.kubernetesClusters.filter((kubernetesCluster) => {
      return !kubernetesCluster.deleted;
    });
  }
  return kubernetesClusters || [];
};
