/* eslint-disable max-classes-per-file */
import { Auth, Amplify } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Helper from 'utils/Helper';
import Logger from 'logger/Logger';
import { getAppConfig } from 'utils/Config';
import { CSRF_HEADER, GetCsrfToken, RemoveCsrfToken } from 'utils/CsrfToken';
import { getEnvVars } from '../env';

const logger = Logger.getLogger('Auth');

export default interface AuthSession {
  accessToken: string;
}

export interface Headers {
  [key: string]: string;
}

// Wrapper around the Authentication methods
interface AuthHandler {
  signOutSession(): Promise<void>;
  signIn(): Promise<void>;
  getCurrentSession(): Promise<AuthSession | undefined>;
}

// If we are running as localhost bypass the normal auth
export const getAuthHandler = (): AuthHandler => {
  const env = getEnvVars();
  if (Helper.isLocalhost() && !env.useRemoteBackend) {
    return new LocalAuth();
  }
  return new ExternalAuth();
};

// Used in mock the auth package when running locally
class LocalAuth implements AuthHandler {
  signOutSession = (): Promise<void> => {
    return Promise.resolve();
  };

  signIn = (): Promise<void> => {
    return Promise.resolve();
  };

  getCurrentSession = (): Promise<AuthSession> => {
    return Promise.resolve({ accessToken: 'jwt' });
  };
}

// Used in production to auth with Amplify
export class ExternalAuth implements AuthHandler {
  signOutSession = async (): Promise<void> => {
    await configure();
    await Auth.signOut({ global: false });
  };

  signIn = async (): Promise<void> => {
    await configure();
    await Auth.federatedSignIn();
  };

  getCurrentSession = async (): Promise<AuthSession | undefined> => {
    await configure();
    try {
      const userSession: CognitoUserSession = await Auth.currentSession();
      if (!userSession?.isValid()) {
        logger.warn('Current user session is invalid');
        return undefined;
      }
      logger.debug('Current user session is valid');
      return { accessToken: userSession.getAccessToken().getJwtToken() };
    } catch (err) {
      logger.error('Get current session failure', err);
      return undefined;
    }
  };
}

export const signOutSession = (): Promise<void> => {
  return getAuthHandler().signOutSession();
};

export const signIn = (): Promise<void> => {
  return getAuthHandler().signIn();
};

export const getCurrentSession = async (): Promise<AuthSession | undefined> => {
  return getAuthHandler().getCurrentSession();
};

// Configure Amplify or skip if we have a session saved
export const configure = async (): Promise<void> => {
  const config = await getAppConfig();
  if (!config) {
    logger.error('Unable to get application configuration');
  } else {
    const { hostname } = window.location;
    const settings = {
      region: 'XX-XXXX-X',
      userPoolId: 'XX-XXXX-X_abcd1234',
      userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',
      mandatorySignIn: false,
      authenticationFlowType: 'CUSTOM_AUTH',
      oauth: {
        domain: hostname,
        scope: ['openid', 'email', 'profile'],
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code',
      },
    };
    settings.region = config.region;
    settings.userPoolId = config.poolId;
    settings.userPoolWebClientId = config.clientId;
    settings.oauth.domain = config.domain;

    // Configure amplify if running in the cloud
    Amplify.configure({
      Auth: settings,
      API: {
        graphql_endpoint: `https://${hostname}/graphql`,
        graphql_headers: customGraphQLHeaders,
      },
    });
  }
};

// Attach custom headers to graphql requests for API auth
export const customGraphQLHeaders = async (): Promise<Headers> => {
  const session = await getAuthHandler().getCurrentSession();
  const csrfToken = GetCsrfToken() || '';
  const accessToken = session?.accessToken || '';
  return {
    Authorization: `Bearer ${accessToken}`,
    'x-auth-subject': 'token',
    [CSRF_HEADER]: csrfToken,
  };
};

// Clear the browser of any auth session (auth in local storage, session storage, etc.)
export const RemoveBrowserAuthSession = (): void => {
  RemoveCsrfToken();

  for (let i = localStorage.length - 1; i >= 0; i--) {
    const k = localStorage.key(i);
    if (k && k.toLowerCase().startsWith('cognito')) {
      localStorage.removeItem(k);
    }
  }
};
