import { useState, useEffect } from 'react';

interface Dimensions {
  width: number;
  height: number;
}

/**
 * @description Returns current window size and updates state if window if resized
 * @return [width, height]
 */
export const useWindowSize = (): Dimensions => {
  const [windowSize, setWindowSize] = useState<Dimensions>({
    width: Number.POSITIVE_INFINITY,
    height: Number.POSITIVE_INFINITY,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};

export default useWindowSize;
