export default interface Route {
  name: string;
  key: string;
  url: string;
}

export const CONSOLE_ROUTE: Route = {
  name: 'Console',
  key: 'tss.lockbox.console',
  url: '/',
};

export const LOGIN_ROUTE: Route = {
  name: 'Login',
  key: 'tss.lockbox.login',
  url: '/login/',
};

export const NOT_AUTHORIZED_ROUTE: Route = {
  name: 'Not Authorized',
  key: 'tss.lockbox.unauthorized',
  url: '/unauthorized/',
};

export const CREATE_SERVICE_ROUTE: Route = {
  name: 'Create Service',
  key: 'tss.lockbox.create.service',
  url: '/create/service/:serviceId?',
};

export const SERVICE_ROUTE: Route = {
  name: 'Service',
  key: 'tss.lockbox.service',
  url: '/service/:serviceId/',
};

export const CREATE_REQUEST_ROUTE: Route = {
  name: 'Create Request',
  key: 'tss.lockbox.create.request',
  url: '/request/',
};

export const REQUEST_ROUTE: Route = {
  name: 'Request',
  key: 'tss.lockbox.request',
  url: '/request/:requestId/',
};

export const CREATE_AWS_ROLE_ROUTE: Route = {
  name: 'Create AWS Role',
  key: 'tss.lockbox.create.role',
  url: '/create/role/:roleId?',
};

export const AWS_ROLE_ROUTE: Route = {
  name: 'AWS Role',
  key: 'tss.lockbox.role',
  url: '/role/:roleId/',
};

export const AWS_ACCOUNT_ROUTE: Route = {
  name: 'AWS Account',
  key: 'tss.lockbox.aws.account',
  url: '/aws/account/:awsAccountObjectId/',
};

export const EDIT_AWS_ACCOUNT_ROUTE: Route = {
  name: 'Edit AWS Account',
  key: 'tss.lockbox.create.aws.account',
  url: '/edit/aws/account/:awsAccountObjectId?',
};

export const TARGET_GROUP_ROUTE: Route = {
  name: 'Target Group',
  key: 'tss.lockbox.target.group',
  url: '/target-group/:targetGroupId?',
};

export const CREATE_TARGET_GROUP_ROUTE: Route = {
  name: 'Create Target Group',
  key: 'tss.lockbox.create.target.group',
  url: '/create/target-group',
};

export const EDIT_TARGET_GROUP_ROUTE: Route = {
  name: 'Edit Target Group',
  key: 'tss.lockbox.edit.target.group',
  url: '/edit/target-group/:targetGroupId',
};

export const ADMIN_PANEL_ROUTE: Route = {
  name: 'Admin Panel',
  key: 'tss.lockbox.admin.panel',
  url: '/admin-panel',
};

export const CHANGE_REQUEST_ROUTE: Route = {
  name: 'Change Request',
  key: 'tss.lockbox.change.request',
  url: '/change-request/:requestId/',
};

export const STEP_FUNCTION_EXECUTION: Route = {
  name: 'Step Function Execution',
  key: 'tss.lockbox.step.function.execution',
  url: '/step-function-execution/:executionArn?',
};
