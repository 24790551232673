import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Customer from 'gql/Customer';
import ActionState, { ActionStatus } from 'store/ActionState';

export type CustomerState = {
  customer?: Customer;
  customerState: ActionState;
};

export const initialState: CustomerState = {
  customer: undefined,
  customerState: { status: ActionStatus.IDLE },
};

const CustomerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    fetchCustomer: (state, _: PayloadAction<{ id: string }>): CustomerState => {
      return { ...state, customerState: { status: ActionStatus.PROCESSING } };
    },
    fetchCustomerSucceeded: (state, action: PayloadAction<Customer>): CustomerState => {
      return {
        ...state,
        customer: action.payload,
        customerState: { status: ActionStatus.SUCCEEDED },
      };
    },
    fetchCustomerFailed: (state, action: PayloadAction<Error>): CustomerState => {
      return {
        ...state,
        customerState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    clearCustomerState: (): CustomerState => {
      return initialState;
    },
  },
});

export default CustomerSlice;
