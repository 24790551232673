export type Environment = {
  port: string;
  useRemoteBackend: boolean;
};

export const env: Environment = {
  useRemoteBackend: false,
  port: '',
};

export function getEnvVars(): Environment {
  return env;
}
