import Helper from 'utils/Helper';
import { IDed } from 'gql/Request';

export interface RecorderSnapshot extends IDed {
  instanceID: string;
  snapshotURI: string;
}

/**
 * Return the pre-signed URL for a recorder DB snapshot
 */
const SNAPSHOTS = `{
    instanceID
    snapshotURI
  }`;

const GET_RECORDER_SNAPSHOTS = `
    query recorderSnapshots($requestID: String!) {
      recorderSnapshots (requestID: $requestID) ${SNAPSHOTS}
    }
  `;

/**
 * Calls API for GET_RECORDER_SNAPSHOTS
 * @param variables { id: string }
 * @returns Promise<Request>
 */
export const getRecorderSnapshots = async (requestID: string): Promise<RecorderSnapshot[]> => {
  const result = await Helper.execGQL<RecorderSnapshot[]>({
    query: GET_RECORDER_SNAPSHOTS,
    variables: { requestID },
  });
  return result.recorderSnapshots;
};
