import Helper from 'utils/Helper';
import { IDed } from 'gql/Request';

export default interface Config extends IDed {
  configValue: string;
  nextToken?: string;
}

// A map of server identifiers to users
export interface TaniumCESystemUsers {
  [key: string]: string[];
}

// A struct of supported Access Levels
export interface AccessLevel {
  name: string;
  accessLevel: number;
  defaultUserRole: string;
}

export interface LockboxMetadata {
  uiVersion: string;
  handlerVersion: string;
  infraVersion: string;
  slackUrl: string;
  updatedAt: string;
}

export interface LockboxSettings {
  sessionLimitSettings: SessionLimitSettings;
  accessLevels: AccessLevel[];
  taniumCESystemUsers: TaniumCESystemUsers;
  bastionMaxDuration: string;
  maxPageSize: number;
  autoApprovedRings: Map<string, boolean>;
  workingHoursSettings: WorkingHoursSettings;
}

export interface WorkingHoursSettings {
  lockboxLocation: string;
  startTime: string;
  endTime: string;
  validDays: string[];
}

export interface SessionLimitSettings {
  limitsDisabled: boolean;
  defaultLimit: number;
  roleLimits: Map<string, number>;
}

export interface FetchAllConfigs {
  nextToken?: string;
}

const GET_CONFIGS = `
    query configs($nextToken: String) {
      configs(nextToken: $nextToken) {
        id
        configValue
        nextToken
      }
    }
  `;

const UPDATE_SETTINGS_CONFIG = `
    mutation updateSettingsConfig ($update: UpdateSettingsConfigInput!) {
      updateSettingsConfig(update: $update) {
        configValue
      }
    }
  `;

export const updateSettingsConfig = async (config: Config): Promise<Config> => {
  const result = await Helper.execGQL<Config>({
    query: UPDATE_SETTINGS_CONFIG,
    variables: {
      update: config,
    },
  });
  return result.updateSettingsConfig;
};

/**
 * Calls API for GET_CONFIGS
 * @returns Promise<Config>
 */
export const getConfigs = async (variables: FetchAllConfigs): Promise<Config[]> => {
  const result = await Helper.execGQL<Config[]>({
    query: GET_CONFIGS,
    variables: {
      ...variables,
    },
  });
  return result.configs;
};
