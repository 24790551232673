/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { Suspense } from 'react';
import { createHashHistory } from 'history';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import { ThemeProvider as TaniumThemeProvider } from '@tanium/react-theme-context';
import { bluestar, darkmode } from '@tanium/theme-data';
import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { QueryParamProvider } from 'use-query-params';
import { ToastNotificationsWrapper } from '@tanium/react-toast-notifications-wrapper';
import { ToastNotificationsDisplay } from '@tanium/react-toast-notifications-display';
import { AppElementProvider } from '@tanium/react-app-element-context';
import { HistoryProvider } from '@tanium/react-history-context';
import { configureStore } from 'store';
import {
  CONSOLE_ROUTE,
  CREATE_REQUEST_ROUTE,
  LOGIN_ROUTE,
  REQUEST_ROUTE,
  CREATE_SERVICE_ROUTE,
  SERVICE_ROUTE,
  NOT_AUTHORIZED_ROUTE,
  EDIT_AWS_ACCOUNT_ROUTE,
  AWS_ACCOUNT_ROUTE,
  TARGET_GROUP_ROUTE,
  CREATE_TARGET_GROUP_ROUTE,
  EDIT_TARGET_GROUP_ROUTE,
  ADMIN_PANEL_ROUTE,
  CHANGE_REQUEST_ROUTE,
  STEP_FUNCTION_EXECUTION,
} from 'Route';
import AppContainer from 'components/app/AppContainer';
import Spinner from 'components/common/spinner/Spinner';
import useDarkTheme from 'common/hooks/useDarkTheme/useDarkTheme';
import i18n from '../../i18n';

const history = createHashHistory();
const store = configureStore(history);

const App: React.VFC = () => {
  // Hide "componentWillMount has been renamed" warning in dev environments until UX-1064 is fixed

  /* istanbul ignore next */
  const ConsolePage = React.lazy(() => import('components/pages/console/ConsolePage'));
  /* istanbul ignore next */
  const CreateRequestPage = React.lazy(() => import('components/pages/request/CreateRequestPage'));
  /* istanbul ignore next */
  const CreateGlobalServicePage = React.lazy(() => import('components/pages/service/CreateGlobalServicePage'));
  /* istanbul ignore next */
  const RequestPage = React.lazy(() => import('components/pages/request/RequestPage'));
  /* istanbul ignore next */
  const PageNotFound = React.lazy(() => import('components/base_page/PageNotFound'));
  /* istanbul ignore next */
  const ServicePage = React.lazy(() => import('components/pages/service/ServicePage'));
  /* istanbul ignore next */
  const TargetGroupPage = React.lazy(() => import('components/pages/targetGroup/TargetGroupPage'));
  /* istanbul ignore next */
  const EditAwsAccountPage = React.lazy(() => import('components/pages/awsAccount/EditAwsAccountPage'));
  /* istanbul ignore next */
  const AwsAccountPage = React.lazy(() => import('components/pages/awsAccount/AwsAccountPage'));
  /* istanbul ignore next */
  const CreateTargetGroupPage = React.lazy(() => import('components/pages/targetGroup/CreateTargetGroupPage'));
  /* istanbul ignore next */
  const UpdateTargetGroupPage = React.lazy(() => import('components/pages/targetGroup/UpdateTargetGroupPage'));
  /* istanbul ignore next */
  const AdminPanelPage = React.lazy(() => import('components/pages/adminPanel/AdminPanelPage'));
  /* istanbul ignore next */
  const ChangeRequestPage = React.lazy(() => import('components/pages/changeRequest/ChangeRequestPage'));
  /* istanbul ignore next */
  const StepFunctionExecutionPage = React.lazy(() => import('components/pages/adminPanel/StepFunctionExecutionPage'));

  const { t } = useTranslation();

  const [isDarkMode] = useDarkTheme();

  return (
    <AppElementProvider selector="#root">
      <I18nextProvider i18n={i18n}>
        <ReduxProvider store={store}>
          <EmotionThemeProvider theme={isDarkMode ? darkmode : bluestar}>
            <TaniumThemeProvider theme={isDarkMode ? darkmode : bluestar}>
              <ToastNotificationsWrapper>
                <ToastNotificationsDisplay />
                <React.Fragment>
                  <ConnectedRouter history={history}>
                    <HistoryProvider history={history}>
                      <QueryParamProvider ReactRouterRoute={Route}>
                        <Suspense fallback={<Spinner />}>
                          <AppContainer
                            children={
                              <Switch>
                                <Route exact path={CONSOLE_ROUTE.url}>
                                  <ConsolePage />
                                </Route>
                                <Route exact path={CREATE_REQUEST_ROUTE.url}>
                                  <CreateRequestPage />
                                </Route>
                                <Route path={REQUEST_ROUTE.url}>
                                  <RequestPage />
                                </Route>
                                <Route path={CREATE_SERVICE_ROUTE.url}>
                                  <CreateGlobalServicePage />
                                </Route>
                                <Route path={SERVICE_ROUTE.url}>
                                  <ServicePage />
                                </Route>
                                <Route path={TARGET_GROUP_ROUTE.url}>
                                  <TargetGroupPage />
                                </Route>
                                <Route path={CREATE_TARGET_GROUP_ROUTE.url}>
                                  <CreateTargetGroupPage />
                                </Route>
                                <Route path={EDIT_TARGET_GROUP_ROUTE.url}>
                                  <UpdateTargetGroupPage />
                                </Route>
                                <Route path={EDIT_AWS_ACCOUNT_ROUTE.url}>
                                  <EditAwsAccountPage />
                                </Route>
                                <Route path={AWS_ACCOUNT_ROUTE.url}>
                                  <AwsAccountPage />
                                </Route>
                                <Route path={ADMIN_PANEL_ROUTE.url}>
                                  <AdminPanelPage />
                                </Route>
                                <Route path={CHANGE_REQUEST_ROUTE.url}>
                                  <ChangeRequestPage />
                                </Route>
                                <Route path={STEP_FUNCTION_EXECUTION.url}>
                                  <StepFunctionExecutionPage />
                                </Route>
                                <Route path={LOGIN_ROUTE.url}>
                                  <PageNotFound errorMessage={t('USER_IS_NOT_AUTHENTICATED')} />
                                </Route>
                                <Route path={NOT_AUTHORIZED_ROUTE.url}>
                                  <PageNotFound errorMessage={t('USER_IS_NOT_AUTHORIZED')} />
                                </Route>
                                <Route component={PageNotFound} />
                              </Switch>
                            }
                          />
                        </Suspense>
                      </QueryParamProvider>
                    </HistoryProvider>
                  </ConnectedRouter>
                </React.Fragment>
              </ToastNotificationsWrapper>
            </TaniumThemeProvider>
          </EmotionThemeProvider>
        </ReduxProvider>
      </I18nextProvider>
    </AppElementProvider>
  );
};

export default App;
