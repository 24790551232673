import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ScratchpadItem, { CreateScratchpadInput } from 'gql/Scratchpad';
import ActionState, { ActionStatus } from 'store/ActionState';

export type ScratchpadState = {
  scratchpads: ScratchpadItem[];
  scratchpadState: ActionState;
};

export const initialState: ScratchpadState = {
  scratchpads: [],
  scratchpadState: { status: ActionStatus.IDLE },
};

const ScratchpadSlice = createSlice({
  name: 'scratchpad',
  initialState,
  reducers: {
    createScratchpad(state, _: PayloadAction<CreateScratchpadInput>): ScratchpadState {
      return { ...state, scratchpadState: { status: ActionStatus.PROCESSING } };
    },
    createScratchpadSucceeded: (state, action: PayloadAction<ScratchpadItem>): ScratchpadState => {
      return {
        scratchpads: [...state.scratchpads, action.payload],
        scratchpadState: {
          status: ActionStatus.SUCCEEDED,
        },
      };
    },
    createScratchpadFailed: (state, action: PayloadAction<Error>): ScratchpadState => {
      return {
        ...state,
        scratchpadState: {
          status: ActionStatus.FAILED,
          error: action.payload,
        },
      };
    },
    fetchScratchpad: (state, _: PayloadAction<{ scratchpadId: string; requestId: string }>): ScratchpadState => {
      return {
        ...state,
        scratchpadState: {
          status: ActionStatus.PROCESSING,
        },
      };
    },
    fetchScratchpadSucceeded: (state, action: PayloadAction<ScratchpadItem>): ScratchpadState => {
      return {
        ...state,
        scratchpads: [action.payload],
        scratchpadState: {
          status: ActionStatus.SUCCEEDED,
        },
      };
    },
    fetchScratchpadFailed: (state, action: PayloadAction<Error>): ScratchpadState => {
      return {
        ...state,
        scratchpadState: {
          status: ActionStatus.FAILED,
          error: action.payload,
        },
      };
    },
    fetchScratchpadList: (state, _: PayloadAction<{ requestId: string }>): ScratchpadState => {
      return {
        ...state,
        scratchpadState: {
          status: ActionStatus.PROCESSING,
        },
      };
    },
    fetchScratchpadListSucceeded: (_, action: PayloadAction<ScratchpadItem[]>): ScratchpadState => {
      return {
        scratchpads: [...new Set(action.payload)],
        scratchpadState: {
          status: ActionStatus.SUCCEEDED,
        },
      };
    },
    fetchScratchpadListFailed: (state, action: PayloadAction<Error>): ScratchpadState => {
      return {
        ...state,
        scratchpadState: {
          status: ActionStatus.FAILED,
          error: action.payload,
        },
      };
    },
  },
});

export default ScratchpadSlice;
