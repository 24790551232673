import Helper from 'utils/Helper';

export default interface RestartWorkflowRequest {
  requestID: string;
}

const RESTART_WORKFLOW = `
        mutation restartWorkflow($requestID: String!) {
            restartWorkflow(requestID: $requestID) {
                requestID
            }
        }
  `;

export const restartWorkflow = async (request: RestartWorkflowRequest): Promise<RestartWorkflowRequest> => {
  const result = await Helper.execGQL<RestartWorkflowRequest>({
    query: RESTART_WORKFLOW,
    variables: {
      ...request,
    },
  });
  return result.restartWorkflow;
};
