/* eslint-disable import/prefer-default-export */
import { createSelector } from '@reduxjs/toolkit';
import { GlobalServiceState, serviceAdapter } from 'components/pages/console/store/GlobalServiceSlice';
import GlobalService, { CreateOrUpdateGlobalServiceObject } from 'gql/GlobalService';
import { ActionStatus } from 'store/ActionState';
import { RootState } from 'store/RootReducer';
import PagerState from 'store/PagerState';

const selectSelf = (state: RootState): GlobalServiceState => {
  return state.service;
};

const selectFetchServicesState = createSelector(selectSelf, (state: GlobalServiceState): ActionStatus => {
  return state.serviceState.status;
});

const selectCreateProcessing = createSelector(selectSelf, (state: GlobalServiceState): boolean => {
  return state.createServiceState.status === ActionStatus.PROCESSING;
});

const selectFormData = createSelector(
  selectSelf,
  (state: GlobalServiceState): CreateOrUpdateGlobalServiceObject | undefined => {
    return state.serviceForm;
  }
);

const selectAll = createSelector(selectSelf, (state: GlobalServiceState): GlobalService[] =>
  state.services ? serviceAdapter.getSelectors().selectAll(state.services) : []
);

const selectPagerState = createSelector(selectSelf, (state: GlobalServiceState): PagerState => state.pagerState);

const GlobalServiceSelectors = {
  selectFetchServicesState,
  selectCreateProcessing,
  selectFormData,
  selectAll,
  selectPagerState,
};

export default GlobalServiceSelectors;
