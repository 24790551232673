import { IDed } from 'gql/Request';
import { PaginationResult, SingletonResult } from 'gql/Common';
import Helper from 'utils/Helper';
import BastionType from './BastionType';

export interface CreateBastionTargetAccess {
  adGroups: string[];
  description?: string;
  targetOrTargetGroupID: string;
  name: string;
  launchTemplateName: string;
  autoscalingGroupName: string;
  bastionType: BastionType;
  proxyKMSKeyARN: string;
  canPortForward: boolean;
}

export interface UpdateBastionTargetAccess extends IDed, CreateBastionTargetAccess {}

export interface BastionTargetAccess extends UpdateBastionTargetAccess {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
}

export const BASTION_TARGET_ACCESS = `
{
    id
    createdBy
    createdAt
    updatedBy
    updatedAt
    targetOrTargetGroupID
    description
    adGroups
    name
    launchTemplateName
    autoscalingGroupName
    bastionType
    proxyKMSKeyARN
    canPortForward
}`;

const GET_BASTION_TARGET_ACCESS = `
  query getBastionTargetAccess($id: String!) {
    getBastionTargetAccess (id: $id) {
      item ${BASTION_TARGET_ACCESS}
    }
  }
`;

export interface GetBastionTargetAccessOptions {
  id: string;
}

export const getBastionTargetAccess = async (
  options: GetBastionTargetAccessOptions
): Promise<BastionTargetAccess | undefined> => {
  const result = await Helper.execGQL<SingletonResult<BastionTargetAccess>>({
    query: GET_BASTION_TARGET_ACCESS,
    variables: options,
  });
  return result.getBastionTargetAccess?.item;
};

const LIST_BASTION_TARGET_ACCESS = `
  query listBastionTargetAccess($filter: TargetBoundAccessFilterInput, $pagination: PaginationInput) {
    listBastionTargetAccess (filter: $filter, pagination: $pagination) {
      items ${BASTION_TARGET_ACCESS}
      total
      nextToken
    }
  }
`;

export interface ListBastionTargetAccessOptions {
  size?: number;
  nextToken?: string;
  includeAllTargetGroups: boolean;
  targetOrTargetGroupID: string;
  userADGroups?: string[];
}

export const listBastionTargetAccess = async (
  options: ListBastionTargetAccessOptions
): Promise<PaginationResult<BastionTargetAccess>> => {
  const result = await Helper.execGQL<PaginationResult<BastionTargetAccess>>({
    query: LIST_BASTION_TARGET_ACCESS,
    variables: {
      filter: {
        includeAllTargetGroups: options.includeAllTargetGroups,
        targetOrTargetGroupID: options.targetOrTargetGroupID,
        userADGroups: options.userADGroups,
      },
      pagination: {
        size: options.size,
        nextToken: options.nextToken,
      },
    },
  });
  return result.listBastionTargetAccess;
};

const CREATE_BASTION_TARGET_ACCESS = `
  mutation createBastionTargetAccess($create: CreateBastionTargetAccessInput!) {
    createBastionTargetAccess(create: $create) {
      item {
        id
      }
    } 
  }`;

export const createBastionTargetAccess = async (bastion: CreateBastionTargetAccess): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<BastionTargetAccess>>({
    query: CREATE_BASTION_TARGET_ACCESS,
    variables: {
      create: bastion,
    },
  });
  return result.createBastionTargetAccess.item;
};

const UPDATE_BASTION_TARGET_ACCESS = `
  mutation updateBastionTargetAccess($update: UpdateBastionTargetAccessInput!) {
      updateBastionTargetAccess(update: $update) {
        item { 
          id
        }
      }
  }`;

export const updateBastionTargetAccess = async (bastion: UpdateBastionTargetAccess): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<BastionTargetAccess>>({
    query: UPDATE_BASTION_TARGET_ACCESS,
    variables: {
      update: bastion,
    },
  });
  return result.updateBastionTargetAccess.item;
};

const DELETE_BASTION_TARGET_ACCESS = `
mutation deleteBastionTargetAccess($id: String!) {
  deleteBastionTargetAccess(id: $id) {
    item ${BASTION_TARGET_ACCESS}
  }
}
`;

export const deleteBastionTargetAccess = async (id: string): Promise<BastionTargetAccess> => {
  const result = await Helper.execGQL<SingletonResult<BastionTargetAccess>>({
    query: DELETE_BASTION_TARGET_ACCESS,
    variables: {
      id,
    },
  });
  return result.deleteBastionTargetAccess.item;
};
