import Helper from 'utils/Helper';
import { IDed } from './Request';

export interface PresignedUrlResponse extends IDed {
  presignedURL: string;
}

export interface PresignedUrlRequest {
  requestID: string;
  instanceID: string;
  fileName: string;
  contentMD5: string;
}

export interface UploadFileToInstanceRequest {
  requestID: string;
  instanceID: string;
  fileName: string;
  fileUploadID: string;
}

const CREATE_PRESIGNED_URL = `
    mutation presignFileUpload($requestID: String!, $instanceID: String!, $fileName: String!, $contentMD5: String!) {
      presignFileUpload(requestID: $requestID, instanceID: $instanceID, fileName: $fileName, contentMD5: $contentMD5) {
        id
        presignedURL
      }
    }
  `;

const CREATE_UPLOAD_FILE_TO_INSTANCE = `
  mutation uploadFileToInstance($requestID: String!, $instanceID: String!, $fileName: String!, $fileUploadID: String!) {
    uploadFileToInstance(requestID: $requestID, instanceID: $instanceID, fileName: $fileName, fileUploadID: $fileUploadID) {
      id
    }
  }
`;

export const createPresignedUrl = async (variables: PresignedUrlRequest): Promise<PresignedUrlResponse> => {
  const response = await Helper.execGQL<PresignedUrlResponse>({
    query: CREATE_PRESIGNED_URL,
    variables,
  });
  return response.presignFileUpload;
};

export const uploadFileToInstanceCall = async (variables: UploadFileToInstanceRequest): Promise<IDed> => {
  const response = await Helper.execGQL<IDed>({
    query: CREATE_UPLOAD_FILE_TO_INSTANCE,
    variables,
  });
  return response.uploadFileToInstance;
};
