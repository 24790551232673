import { IDed } from 'gql/Request';
import IAccessConfiguration from 'common/IAccessConfiguration';
import { PaginationResult, SingletonResult, Tag } from 'gql/Common';
import Helper from 'utils/Helper';

export interface BaseTargetGroup {
  targetIDs?: string[];
  priority?: number;
  dynamicTarget?: Tag[];
  name?: string;
  description?: string;
  approvalSlackChannel?: string;
  notifyAutoApproval?: boolean;
  enablePaging?: boolean;
  requiresBastion?: boolean;
  teamADGroups?: string;
  managersADGroups?: string;
  leadsADGroups?: string;
  limitedAccessRoles?: string[];
  policy?: IAccessConfiguration[];
}
export interface TargetGroup extends IDed, BaseTargetGroup {
  createdBy?: string;
  createdAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  deleted?: boolean;
}

export const LITE_REQUEST_ACCESS = `{
  type
  targetAccessID
}`;

export const FULL_TARGET_GROUP = `
{
  id
  priority
  dynamicTarget {
    key
    value
  }
  targetIDs
  createdBy
  createdAt
  updatedBy
  updatedAt
  name
  description
  approvalSlackChannel
  notifyAutoApproval
  enablePaging
  requiresBastion
  teamADGroups
  managersADGroups
  leadsADGroups
  policy {
    level
    access ${LITE_REQUEST_ACCESS}
  }
  limitedAccessRoles
  deleted
}`;

export const LITE_TARGET_GROUP = `
{
  id
  priority
  targetIDs
  createdBy
  createdAt
  updatedBy
  updatedAt
  name
  description
  deleted
}`;

const LIST_TARGET_GROUPS = `
  query listTargetGroups($filter: TargetGroupFilterInput, $pagination: PaginationInput) {
    listTargetGroups (filter: $filter, pagination: $pagination) {
      items ${LITE_TARGET_GROUP}
      total
      nextToken
    }
  }
`;

export interface ListTargetGroupOptions {
  targetID?: string;
  size?: number;
  nextToken?: string;
}

export const listTargetGroups = async (options?: ListTargetGroupOptions): Promise<PaginationResult<TargetGroup>> => {
  const result = await Helper.execGQL<PaginationResult<TargetGroup>>({
    query: LIST_TARGET_GROUPS,
    variables: {
      filter: {
        targetID: options?.targetID,
      },
      pagination: {
        size: options?.size,
        nextToken: options?.nextToken,
      },
    },
  });
  result.listTargetGroups.items = result.listTargetGroups.items.filter((tg) => !tg.deleted);
  return result.listTargetGroups;
};

const GET_TARGET_GROUP = `
  query getTargetGroup($id: String!) {
    getTargetGroup(id: $id) {
      item ${FULL_TARGET_GROUP}
    }
  }`;

export const getTargetGroup = async (id: string): Promise<TargetGroup> => {
  const result = await Helper.execGQL<SingletonResult<TargetGroup>>({
    query: GET_TARGET_GROUP,
    variables: {
      id,
    },
  });
  return result.getTargetGroup.item;
};

const CREATE_TARGET_GROUP = `
  mutation createTargetGroup($create: CreateTargetGroupInput!) {
    createTargetGroup(create: $create) {
      item {
        id
      }
    } 
  }`;

export const createTargetGroup = async (group: TargetGroup): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<TargetGroup>>({
    query: CREATE_TARGET_GROUP,
    variables: {
      create: group,
    },
  });
  return result.createTargetGroup.item;
};

const UPDATE_TARGET_GROUP = `
  mutation updateTargetGroup($update: UpdateTargetGroupInput!) {
    updateTargetGroup(update: $update) {
      item { 
        id
      }
    }
  }`;

export const updateTargetGroup = async (group: TargetGroup): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<TargetGroup>>({
    query: UPDATE_TARGET_GROUP,
    variables: {
      update: group,
    },
  });
  return result.updateTargetGroup.item;
};

const DELETE_TARGET_GROUP = `
mutation deleteTargetGroup($id: String!) {
  deleteTargetGroup(id: $id) {
      item {
          id
      }
  }
} `;

export const deleteTargetGroup = async (id: string): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<TargetGroup>>({
    query: DELETE_TARGET_GROUP,
    variables: {
      id,
    },
  });
  return result.deleteTargetGroup.item;
};
