import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestTargetType } from 'gql/Request';
import { ActionStatus } from 'store/ActionState';

export type TargetTypesState = {
  availableTargetTypes: RequestTargetType[];
  status: ActionStatus;
};

const initialState: TargetTypesState = {
  availableTargetTypes: [],
  status: ActionStatus.IDLE,
};

const TargetTypesSlice = createSlice({
  name: 'targetTypes',
  initialState,
  reducers: {
    fetchAvailableTargetTypes: (state) => {
      state.status = ActionStatus.PROCESSING;
    },
    fetchAvailableTargetTypesSucceeded: (state, action: PayloadAction<RequestTargetType[]>) => {
      state.availableTargetTypes = action.payload;
      state.status = ActionStatus.SUCCEEDED;
    },
    fetchAvailableTargetTypesFailed: (state) => {
      state.status = ActionStatus.FAILED;
    },
  },
});

export default TargetTypesSlice;
