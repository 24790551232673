import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import ActionState, { ActionStatus } from 'store/ActionState';
import { IDed } from 'gql/Request';
import {
  HostTargetAccess,
  CreateHostTargetAccess,
  ListHostTargetAccessOptions,
  UpdateHostTargetAccess,
  GetHostTargetAccessOptions,
} from 'gql/HostTargetAccess';

export type HostTargetAccessState = {
  hostTargetAccess?: HostTargetAccess;
  hostTargetAccesses: EntityState<HostTargetAccess>;
  loadingState: ActionState;

  mutationState: ActionState;
};

export const hostTargetAccessAdapter = createEntityAdapter<HostTargetAccess>();

export const initialState: HostTargetAccessState = {
  hostTargetAccesses: hostTargetAccessAdapter.getInitialState(),
  loadingState: { status: ActionStatus.IDLE },
  mutationState: { status: ActionStatus.IDLE },
};

const HostTargetAccessSlice = createSlice({
  name: 'hostTargetAccess',
  initialState,
  reducers: {
    // GET
    getHostTargetAccessByID: (state, _: PayloadAction<GetHostTargetAccessOptions>): HostTargetAccessState => {
      return { ...state, loadingState: { status: ActionStatus.PROCESSING } };
    },
    getHostTargetAccessByIDSucceeded: (
      state,
      action: PayloadAction<HostTargetAccess | undefined>
    ): HostTargetAccessState => {
      return {
        ...state,
        hostTargetAccesses: hostTargetAccessAdapter.upsertMany(
          { ...state.hostTargetAccesses },
          action.payload ? [action.payload] : []
        ),
        loadingState: { status: ActionStatus.SUCCEEDED },
      };
    },
    getHostTargetAccessByIDFailed: (state, action: PayloadAction<Error>): HostTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // LIST
    listHostTargetAccessForTargetOrGroup: (
      state,
      _: PayloadAction<ListHostTargetAccessOptions>
    ): HostTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.PROCESSING },
        hostTargetAccesses: hostTargetAccessAdapter.getInitialState(),
      };
    },
    listNextPageHostTargetAccessForTargetOrGroup: (
      state,
      _: PayloadAction<ListHostTargetAccessOptions>
    ): HostTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.PROCESSING },
      };
    },
    listHostTargetAccessForTargetOrGroupSucceeded: (
      state,
      action: PayloadAction<HostTargetAccess[]>
    ): HostTargetAccessState => {
      return {
        ...state,
        hostTargetAccesses: hostTargetAccessAdapter.setMany({ ...state.hostTargetAccesses }, action.payload),
        loadingState: { status: ActionStatus.SUCCEEDED },
      };
    },
    listHostTargetAccessForTargetOrGroupFailed: (state, action: PayloadAction<Error>): HostTargetAccessState => {
      return {
        ...state,
        loadingState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // CREATE
    createHostTargetAccess: (state, _: PayloadAction<CreateHostTargetAccess>): HostTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    createHostTargetAccessSucceeded: (state, _: PayloadAction<IDed>): HostTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
      };
    },
    createHostTargetAccessFailed: (state, action: PayloadAction<Error>): HostTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // UPDATE
    updateHostTargetAccess: (state, _: PayloadAction<UpdateHostTargetAccess>): HostTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    updateHostTargetAccessSucceeded: (state, _: PayloadAction<IDed>): HostTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
      };
    },
    updateHostTargetAccessFailed: (state, action: PayloadAction<Error>): HostTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // DELETE
    deleteHostTargetAccess: (state, _: PayloadAction<string>): HostTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    deleteHostTargetAccessSucceeded: (state, _: PayloadAction<IDed>): HostTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
      };
    },
    deleteHostTargetAccessFailed: (state, action: PayloadAction<Error>): HostTargetAccessState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // Clear
    clearState: () => {
      return initialState;
    },
  },
});

export default HostTargetAccessSlice;
