import Helper from 'utils/Helper';
import { PaginationResult } from './Common';

export default interface StepFunctionExecution {
  executionArn: string;
  name: string;
  status: string;
  input: string;
  output: string;
  startDate: string;
  stopDate: string;
  cause: string;
}

export interface StepFunctionFilterInput {
  status?: string;
  size?: number;
  nextToken?: string;
}

export interface DescribeStepFunctionExecutionInput {
  executionArn: string;
  requestID?: string;
}

export const STEPFUNCTIONEXECUTIONITEM = `{
    executionArn
    name
    status
    input
    output
    startDate
    stopDate
    cause
  }`;

const LIST_ACCESS_REQUEST_STEP_FUNCTION_EXECUTIONS = `
        query listAccessRequestExecutions($filter: StepFunctionFilterInput, $pagination: PaginationInput) {
          listAccessRequestExecutions(filter: $filter, pagination: $pagination) {
                items ${STEPFUNCTIONEXECUTIONITEM}
                total
                nextToken
            }
        }
  `;

const LIST_CHANGE_REQUEST_STEP_FUNCTION_EXECUTIONS = `
        query listChangeRequestExecutions($filter: StepFunctionFilterInput, $pagination: PaginationInput) {
          listChangeRequestExecutions(filter: $filter, pagination: $pagination) {
                items ${STEPFUNCTIONEXECUTIONITEM}
                total
                nextToken
            }
        }
  `;

const DESCRIBE_STEP_FUNCTION_EXECUTION = `
        query describeStepFunctionExecution($input: StepFunctionExecutionInput!) {
            describeStepFunctionExecution(input: $input) {
                name status startDate stopDate input output cause
            }
        }
`;

export const describeStepFunctionExecution = async (
  options: DescribeStepFunctionExecutionInput
): Promise<StepFunctionExecution> => {
  const result = await Helper.execGQL<StepFunctionExecution>({
    query: DESCRIBE_STEP_FUNCTION_EXECUTION,
    variables: {
      input: {
        executionArn: options.executionArn,
        requestID: options.requestID,
      },
    },
  });
  return result.describeStepFunctionExecution;
};

export const listAccessRequestExecutions = async (
  options: StepFunctionFilterInput
): Promise<StepFunctionExecution[]> => {
  const result = await Helper.execGQL<PaginationResult<StepFunctionExecution>>({
    query: LIST_ACCESS_REQUEST_STEP_FUNCTION_EXECUTIONS,
    variables: {
      filter: {
        status: options?.status,
      },
      pagination: {
        size: options?.size,
        nextToken: options?.nextToken,
      },
    },
  });

  return result.listAccessRequestExecutions.items;
};

export const listChangeRequestExecutions = async (
  options: StepFunctionFilterInput
): Promise<StepFunctionExecution[]> => {
  const result = await Helper.execGQL<PaginationResult<StepFunctionExecution>>({
    query: LIST_CHANGE_REQUEST_STEP_FUNCTION_EXECUTIONS,
    variables: {
      filter: {
        status: options?.status,
      },
      pagination: {
        size: options?.size,
        nextToken: options?.nextToken,
      },
    },
  });

  return result.listChangeRequestExecutions.items;
};
