/* eslint-disable no-param-reassign */
import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import Helper from 'utils/Helper';
import ActionState, { ActionStatus } from 'store/ActionState';
import { RequestAccess } from 'gql/Request';
import { INotification, IErrorNotification } from 'common/INotification';
import IAccessTypes from 'common/IAccessTypes';

export interface AccessTypeOption {
  value: IAccessTypes;
  disabledThroughRbac: boolean;
  disabledThroughUi: boolean;
}

export type RequestAccessState = {
  requestAccessState?: ActionState;
  requestAccess?: RequestAccess[];
  notification?: INotification;
  accessTypeOptions?: { [key in IAccessTypes]?: AccessTypeOption };
};

export const requestAccessAdapter = createEntityAdapter<RequestAccess[]>();

export const initialState: RequestAccessState = {
  requestAccess: [],
  requestAccessState: { status: ActionStatus.IDLE },
  accessTypeOptions: undefined,
};

const RequestAccessSlice = createSlice({
  name: 'requestAccess',
  initialState,
  reducers: {
    clearNotification: (state): RequestAccessState => {
      return { ...state, notification: undefined };
    },
    showNotification: (state, action: PayloadAction<INotification>): RequestAccessState => {
      return { ...state, notification: action.payload };
    },
    showNotificationFromError: (state, action: PayloadAction<IErrorNotification>): RequestAccessState => {
      const { msg, err } = action.payload;
      const contents = Helper.humanReadableError(msg, err);
      return { ...state, notification: { type: 'error', contents } };
    },
    fetchRequestAccess: (state): RequestAccessState => {
      return { ...state, requestAccessState: { status: ActionStatus.PROCESSING } };
    },
    fetchRequestAccessSucceeded: (state, action: PayloadAction<RequestAccess[]>) => {
      state.requestAccess = action.payload;
      state.requestAccessState = { status: ActionStatus.SUCCEEDED };
      action.payload.forEach((access: RequestAccess) => {
        if (access.type in IAccessTypes) {
          if (state.accessTypeOptions && access.type in state.accessTypeOptions) {
            state.accessTypeOptions[access.type]!.disabledThroughRbac = false;
          } else {
            if (!state.accessTypeOptions) {
              state.accessTypeOptions = {};
            }
            state.accessTypeOptions[access.type] = {
              value: access.type,
              disabledThroughRbac: false,
              disabledThroughUi: false,
            };
          }
        }
      });
    },
    fetchRequestAccessFailed: (state, action: PayloadAction<Error>): RequestAccessState => {
      return {
        ...state,
        requestAccessState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
    enableOption: (state: RequestAccessState, action: PayloadAction<IAccessTypes>) => {
      if (state.accessTypeOptions && action.payload in state.accessTypeOptions) {
        state.accessTypeOptions[action.payload]!.disabledThroughUi = false;
      }
    },
    disableOption: (state: RequestAccessState, action: PayloadAction<IAccessTypes>) => {
      if (state.accessTypeOptions && action.payload in state.accessTypeOptions) {
        state.accessTypeOptions[action.payload]!.disabledThroughUi = true;
      }
    },
  },
});

export const { showNotification, showNotificationFromError } = RequestAccessSlice.actions;

export default RequestAccessSlice;
