import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const PREFERS_COLOR_THEME = 'prefers-color-scheme';

export const useDarkTheme = (): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [isDarkThemeOS, setIsDarkThemeOS] = useState(
    localStorage.getItem(PREFERS_COLOR_THEME)
      ? localStorage.getItem(PREFERS_COLOR_THEME) === 'dark'
      : typeof window.matchMedia === 'function' && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    if (typeof window.matchMedia === 'function') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

      const handleThemeChange = /* istanbul ignore next */ () => {
        document.documentElement.setAttribute('dark-theme', localStorage.getItem(PREFERS_COLOR_THEME) as string);
        if (localStorage.getItem(PREFERS_COLOR_THEME) && localStorage.getItem(PREFERS_COLOR_THEME) === 'dark') {
          setIsDarkThemeOS(true);
        } else if (localStorage.getItem(PREFERS_COLOR_THEME) && localStorage.getItem(PREFERS_COLOR_THEME) === 'light') {
          setIsDarkThemeOS(false);
        } else {
          setIsDarkThemeOS(mediaQuery.matches);
        }
      };

      mediaQuery.addEventListener('change', handleThemeChange);
      document.addEventListener('theme-change', handleThemeChange);

      return () => {
        mediaQuery.removeEventListener('change', handleThemeChange);
        document.removeEventListener('theme-change', handleThemeChange);
      };
    }

    return undefined;
  }, []);

  // Return the OS theme
  document.documentElement.setAttribute('dark-theme', isDarkThemeOS ? 'dark' : 'light');
  return [isDarkThemeOS, setIsDarkThemeOS];
};

export default useDarkTheme;
