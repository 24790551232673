import React from 'react';
import { Stack, StackChild } from '@tanium/react-stack';
import { Panel } from '@tanium/react-panel';
import { Box } from '@tanium/react-box';
import { DefinitionList, DefinitionListItem } from '@tanium/react-definition-list';
import { useTranslation } from 'react-i18next';
import MediumBoldText from 'components/styles/text/MediumBoldText';
import MediumText from 'components/styles/text/MediumText';
import Helper from 'utils/Helper';
import Approval, { approvalStatus } from 'gql/Approval';

export interface IApprovals {
  approvals: Approval[];
}

export const Approvals: React.VFC<IApprovals> = ({ approvals }: IApprovals) => {
  const { t } = useTranslation();

  if (approvals?.length) {
    return (
      <Panel title={t('APPROVALS')} isCollapsible>
        <Box pl={8}>
          <Stack itemSpacing={10} direction="columns">
            {approvals.map((approval, i) => (
              <StackChild key={`${approval.id}-${i}`}>
                <DefinitionList>
                  {approval.acceptedAt && (
                    <>
                      <DefinitionListItem
                        term={<MediumText>{t('APPROVED_BY')}:</MediumText>}
                        description={<MediumBoldText>{approval.acceptedBy}</MediumBoldText>}
                      />
                      <DefinitionListItem
                        term={<MediumText>{t('APPROVED_AT')}:</MediumText>}
                        description={
                          <MediumBoldText>{Helper.localDateIn12HrFormat(approval.acceptedAt)}</MediumBoldText>
                        }
                      />
                    </>
                  )}
                  {approval.rejectedAt && (
                    <>
                      <DefinitionListItem
                        term={<MediumText>{t('REJECTED_BY')}:</MediumText>}
                        description={<MediumBoldText>{approval.rejectedBy}</MediumBoldText>}
                      />
                      <DefinitionListItem
                        term={<MediumText>{t('REJECTED_AT')}:</MediumText>}
                        description={
                          <MediumBoldText>{Helper.localDateIn12HrFormat(approval.rejectedAt)}</MediumBoldText>
                        }
                      />
                    </>
                  )}
                  <DefinitionListItem
                    term={<MediumText>{t('STATUS')}:</MediumText>}
                    description={<MediumBoldText>{approvalStatus(approval)}</MediumBoldText>}
                  />
                </DefinitionList>
              </StackChild>
            ))}
          </Stack>
        </Box>
      </Panel>
    );
  }
  return null;
};

export default Approvals;
