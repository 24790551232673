import { NotFound as NotFoundIcon } from '@tanium/react-graphics';
import React from 'react';
import ErrorText from 'components/styles/text/ErrorText';
import CenteredSmallDiv from 'components/styles/containers/CenteredSmallDiv';

interface Props {
  errorMessage: string;
}

/**
 * Display an error message when a page cannot be loaded.
 */
const PageNotFound: React.FC<Props> = ({ errorMessage }) => {
  const message = errorMessage;
  return (
    <CenteredSmallDiv>
      <p>
        <ErrorText>{message}</ErrorText>
      </p>
      <NotFoundIcon />
    </CenteredSmallDiv>
  );
};

export default PageNotFound;
