import React from 'react';
import { Box } from '@tanium/react-box';
import { ThemeColors } from '@tanium/theme-data';
import { Stack, StackChild } from '@tanium/react-stack';
import ContainerBox, { AppHeaderStyle } from 'components/styles/containers/ContainerBox';
import User from 'gql/User';
import TaniumLogo from 'components/app_header/TaniumLogo';
import CurrentUser from 'components/app_header/CurrentUser';
import SignOut from 'components/app_header/SignOut';
import SignIn from 'components/app_header/SignIn';
import HelpLink from 'components/app_header/HelpLink';
import AdminPanelLink from 'components/app_header/AdminPanelLink';
import { PermissionAction, PermissionResource, hasPermission } from 'gql/Permission';

interface Props {
  user?: User;
}

const AppHeader: React.VFC<Props> = ({ user }) => {
  const canViewAdminPanel = hasPermission(user?.permissions, PermissionAction.READ, PermissionResource.ADMIN_PANEL);

  return (
    <Box position="fixed" top={0} width="100%" zIndex={99}>
      <Box bg={ThemeColors.BrandRed} height={`${AppHeaderStyle.borderHeight}px`} width="100%" />
      <ContainerBox>
        <Box mx={3}>
          <TaniumLogo />
        </Box>
        <Box mx={3} flexGrow={1} />
        {user ? (
          <Stack itemSpacing={2} itemDividers justifyContent="center" p={3}>
            {canViewAdminPanel && (
              <StackChild>
                <AdminPanelLink />
              </StackChild>
            )}
            <StackChild>
              <HelpLink />
            </StackChild>
            <StackChild>
              <CurrentUser user={user} />
            </StackChild>
            <StackChild>
              <SignOut />
            </StackChild>
          </Stack>
        ) : (
          <Box mx={3}>
            <SignIn />
          </Box>
        )}
      </ContainerBox>
    </Box>
  );
};

export default AppHeader;
