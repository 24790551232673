/* eslint-disable no-lonely-if */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefinitionList, DefinitionListItem } from '@tanium/react-definition-list';
import MediumBoldText from 'components/styles/text/MediumBoldText';
import { DependencyArtifact } from 'gql/DependencyArtifacts';
import RequiredSoftwareLinks from 'components/pages/request/requestPartials/requiredSoftwarePartials/RequiredSoftwareLinks';

export const filterArtifacts = (key: string, artifacts: DependencyArtifact[]): DependencyArtifact | undefined => {
  return artifacts.find((a) => a.key.includes(key));
};

interface Params {
  dependencyArtifacts: DependencyArtifact[];
  os: string;
}

// This page displays download links for dependencies
const RequiredSoftware = ({ dependencyArtifacts, os }: Params): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <DefinitionList data-testid="definitionList">
      {os === 'darwin' ? (
        <React.Fragment data-testid="darwin">
          <DefinitionListItem
            term={<MediumBoldText>{t('TANIUM_CLOUD_CLI')}:</MediumBoldText>}
            description={
              <RequiredSoftwareLinks
                artifact={filterArtifacts('darwin/tanium-cloud-cli', dependencyArtifacts)}
                installationLink="https://git.corp.tanium.com/tanium-cloud/tanium-cloud-cli#installation"
              />
            }
          />
          <DefinitionListItem
            term={<MediumBoldText>{t('TANIUM_CLOUD_CLI_ARM64')}:</MediumBoldText>}
            description={
              <RequiredSoftwareLinks
                artifact={filterArtifacts('darwin_arm64/tanium-cloud-cli', dependencyArtifacts)}
                installationLink="https://git.corp.tanium.com/tanium-cloud/tanium-cloud-cli#installation"
              />
            }
          />
        </React.Fragment>
      ) : (
        <DefinitionListItem
          term={<MediumBoldText>{t('TANIUM_CLOUD_CLI')}:</MediumBoldText>}
          description={
            <RequiredSoftwareLinks
              artifact={filterArtifacts('tanium-cloud-cli', dependencyArtifacts)}
              installationLink="https://git.corp.tanium.com/tanium-cloud/tanium-cloud-cli#installation"
            />
          }
        />
      )}
      <DefinitionListItem
        term={<MediumBoldText>{t('AWS_CLI')}:</MediumBoldText>}
        description={
          <RequiredSoftwareLinks
            artifact={filterArtifacts('aws-cli', dependencyArtifacts)}
            installationLink="https://docs.aws.amazon.com/cli/latest/userguide/install-cliv2-mac.html"
          />
        }
      />
      <DefinitionListItem
        term={<MediumBoldText>{t('SESSION_MANAGER_PLUGIN')}:</MediumBoldText>}
        description={
          <RequiredSoftwareLinks
            artifact={filterArtifacts('session-manager', dependencyArtifacts)}
            installationLink="https://docs.aws.amazon.com/systems-manager/latest/userguide/session-manager-working-with-install-plugin.html"
          />
        }
      />
      <DefinitionListItem
        term={<MediumBoldText>{t('VNC_CLIENT')}:</MediumBoldText>}
        description={
          <RequiredSoftwareLinks
            artifact={filterArtifacts('vnc-viewer', dependencyArtifacts)}
            installationLink="https://www.realvnc.com/en/connect/download/viewer/"
          />
        }
      />
    </DefinitionList>
  );
};

export default RequiredSoftware;
