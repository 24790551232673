import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ActionState, { ActionStatus } from 'store/ActionState';
import { DependencyArtifact, DependencyArtifactInput } from 'gql/DependencyArtifacts';

export type DependencyArtifactsState = {
  dependencyArtifacts: DependencyArtifact[];
  dependencyArtifactFetchState?: ActionState;
};

export const initialState: DependencyArtifactsState = {
  dependencyArtifacts: [],
  dependencyArtifactFetchState: { status: ActionStatus.IDLE },
};

const DependencyArtifactsSlice = createSlice({
  name: 'dependencyArtifact',
  initialState,
  reducers: {
    fetchDependencyArtifacts: (state, _: PayloadAction<DependencyArtifactInput>) => {
      return { ...state, dependencyArtifactFetchState: { status: ActionStatus.PROCESSING } };
    },
    fetchDependencyArtifactsSuccess: (state, action: PayloadAction<DependencyArtifact[]>) => {
      return {
        ...state,
        dependencyArtifacts: action.payload,
        dependencyArtifactFetchState: { status: ActionStatus.SUCCEEDED },
      };
    },
    fetchDependencyArtifactsFail: (state, action: PayloadAction<Error>) => {
      return {
        ...state,
        dependencyArtifactFetchState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },
  },
});

export const { fetchDependencyArtifacts, fetchDependencyArtifactsSuccess, fetchDependencyArtifactsFail } =
  DependencyArtifactsSlice.actions;

export default DependencyArtifactsSlice;
