import { IDed, RequestTargetType } from 'gql/Request';
import { SingletonResult } from 'gql/Common';
import Helper from 'utils/Helper';
import Audit from 'gql/Audit';
import Approval from 'gql/Approval';

interface AuditFields {
  createdBy: string;
  createdAt: string;
  updatedBy?: string;
  updatedAt?: string;
}

export interface ChangeSet extends IDed {
  createdBy: string;
  createdAt: string;
  requestId: string;
  targetID: string;
  proposedValue: string;
}

export interface BaseChangeRequest {
  targetType: RequestTargetType;
  targetId: string;
  targetName: string;
  accessLevel: number;
  isApproved: boolean;
  isRejected: boolean;
  canAcceptOrReject: boolean;
  requestCloseTime: string;
  approvals: Approval[];
  audits: Audit[];
  changes: ChangeSet[];
}
export interface ChangeRequest extends IDed, AuditFields, BaseChangeRequest {
  deleted?: boolean;
}

export const FULL_TARGET_GROUP = `
{
    id
    createdBy
    createdAt
    updatedBy
    updatedAt
    targetType
    targetId
    targetName
    accessLevel
    isApproved
    isRejected
    canAcceptOrReject
    requestCloseTime
    approvals {
        id
        createdBy
        createdAt
        updatedBy
        updatedAt
        requestId
        acceptedBy
        acceptedAt
        rejectedBy
        rejectedAt
        canAccept
        canReject
    }
    audits {
        id
        createdBy
        createdAt
        updatedBy
        updatedAt
        requestId
        diffs {
            member
            oldValue
            newValue
        }
        oldImage
        newImage
        eventName
        itemType
    }
    changes {
        id
        createdBy
        createdAt
        targetID
        proposedValue
    }
}`;

export const LITE_TARGET_GROUP = `
{
  id
  priority
  targetIDs
  createdBy
  createdAt
  updatedBy
  updatedAt
  name
  description
  deleted
}`;

const GET_CHANGE_REQUEST = `
  query getChangeRequest($id: String!) {
    getChangeRequest(id: $id) {
      item ${FULL_TARGET_GROUP}
    }
  }`;

export const getChangeRequest = async (id: string): Promise<ChangeRequest> => {
  const result = await Helper.execGQL<SingletonResult<ChangeRequest>>({
    query: GET_CHANGE_REQUEST,
    variables: {
      id,
    },
  });
  return result.getChangeRequest.item;
};

const CREATE_CHANGE_REQUEST = `
  mutation createChangeRequest($create: CreateChangeRequestInput!) {
    createChangeRequest(create: $create) {
      item {
        id
      }
    } 
  }`;

export const createChangeRequest = async (change: ChangeRequest): Promise<IDed> => {
  const result = await Helper.execGQL<SingletonResult<ChangeRequest>>({
    query: CREATE_CHANGE_REQUEST,
    variables: {
      create: change,
    },
  });
  return result.createChangeRequest.item;
};
