import { createSelector } from '@reduxjs/toolkit';
import {
  K8sRoleTargetAccessState,
  k8sRoleTargetAccessAdapter,
} from 'components/pages/console/store/K8sRoleTargetAccessSlice';
import { K8sRoleTargetAccess } from 'gql/K8sRoleTargetAccess';
import { ActionStatus } from 'store/ActionState';
import { RootState } from 'store/RootReducer';

const selectSelf = (state: RootState): K8sRoleTargetAccessState => {
  return state.k8sRoleTargetAccess;
};

const selectK8sRoleTargetAccess = (id?: string) => {
  return (state: RootState): K8sRoleTargetAccess | undefined => {
    return id
      ? k8sRoleTargetAccessAdapter.getSelectors().selectById(state.k8sRoleTargetAccess.k8sRoleTargetAccesses, id)
      : undefined;
  };
};

const selectK8sRoleTargetAccessStarted = createSelector(selectSelf, (state: K8sRoleTargetAccessState): boolean => {
  return state.loadingState.status !== ActionStatus.IDLE;
});

const selectK8sRoleTargetAccessLoading = createSelector(selectSelf, (state: K8sRoleTargetAccessState): boolean => {
  return state.loadingState.status === ActionStatus.PROCESSING;
});

const selectK8sRoleTargetAccessList = createSelector(
  selectSelf,
  (state: K8sRoleTargetAccessState): K8sRoleTargetAccess[] => {
    return k8sRoleTargetAccessAdapter.getSelectors().selectAll(state.k8sRoleTargetAccesses);
  }
);

const selectMutationSucceeded = createSelector(selectSelf, (state: K8sRoleTargetAccessState): boolean => {
  return state.mutationState.status === ActionStatus.SUCCEEDED;
});

const K8sRoleTargetAccessSelectors = {
  selectK8sRoleTargetAccessStarted,
  selectK8sRoleTargetAccessList,
  selectMutationSucceeded,
  selectK8sRoleTargetAccessLoading,
  selectK8sRoleTargetAccess,
};

export default K8sRoleTargetAccessSelectors;
