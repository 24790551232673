import { CreateRequest } from 'gql/Request';
import Helper from 'utils/Helper';

export default interface RequestAccessLevelConfig {
  accessLevel: number;
  userRoles: string[];
  willPage: boolean;
}

const REQUEST_ACCESS_LEVEL_CONFIG = `
{
  accessLevel
  userRoles
  willPage
}`;

const CALCULATE_REQUEST_ACCESS_LEVEL = `
  query calculateRequestAccessLevel($createRequest: CreateRequestInput!) {
    calculateRequestAccessLevel(createRequest: $createRequest) ${REQUEST_ACCESS_LEVEL_CONFIG}
  }
`;

export const calculateRequestAccessLevel = async (
  createRequestInput: CreateRequest
): Promise<RequestAccessLevelConfig> => {
  const result = await Helper.execGQL<RequestAccessLevelConfig>({
    query: CALCULATE_REQUEST_ACCESS_LEVEL,
    variables: {
      createRequest: createRequestInput,
    },
  });
  return result.calculateRequestAccessLevel;
};
