import { Buffer } from 'buffer';
import axios from 'axios';
import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import moment from 'moment-timezone';
import { now } from 'moment';
import i18next from 'i18next';
import SparkMD5 from 'spark-md5';
import Logger from 'logger/Logger';
import { retryAsync, RetryOptions } from 'utils/RetryHelper';
import Request, { RequestTargetType } from 'gql/Request';
import IApprovalLevel from 'common/IApprovalLevel';
import IAccessTypes from 'common/IAccessTypes';
import { AwsRoleTargetAccess } from 'gql/AwsRoleTargetAccess';
import { BastionTargetAccess } from 'gql/BastionTargetAccess';
import { HostTargetAccess } from 'gql/HostTargetAccess';
import { K8sRoleTargetAccess } from 'gql/K8sRoleTargetAccess';
import { getEnvVars } from '../env';
import { customGraphQLHeaders } from './AuthSession';

const logger = Logger.getLogger('Helper');

export enum EndpointType {
  AuthConfig = 'authconfig',
  Graphql = 'graphql',
  Csrf = 'csrf_token',
}

export const isLocalhost = (): boolean => {
  const { hostname } = window.location;
  return hostname === 'localhost';
};

export const getEndpoint = (endpointType: EndpointType): string => {
  let endpoint = endpointType.toString();
  // If not localhost, use absolute endpoint path
  if (!isLocalhost()) {
    const env = getEnvVars();
    const { protocol, hostname } = window.location;
    let host = `${protocol}//${hostname}`;
    if (env.port) {
      host += `:${env.port}`;
    }
    endpoint = `${host}/${endpoint}`;
  }
  return endpoint;
};

export const getPlatform = (): string | null => {
  const ua: string = navigator.appVersion.toLowerCase();
  if (ua.includes('macintosh') || ua.includes('ipad') || ua.includes('iphone')) {
    return 'apple';
  }
  if (ua.includes('windows')) {
    return 'windows';
  }
  return null;
};

export const findGetParameter = (parameterName: string, search = window.location.search): string | null => {
  let result = null;
  let tmp = [];
  const items = search.substring(1).split('&');
  for (let index = 0; index < items.length; index++) {
    tmp = items[index].split('=');
    if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
  }
  return result;
};

// Replace the tokenized components of a react route with the param values
export const interpolateRoute = (url: string, params: { [key: string]: string }): string => {
  let interpolatedUrl = url;
  Object.keys(params).forEach((key) => {
    interpolatedUrl = interpolatedUrl.replace(RegExp(`:${key}[?]?`), params[key]);
  });
  return interpolatedUrl;
};

// Executes graphql, either with local axios or via an authenticated API managed by Amplify
export const execGQLWithoutRetry = async <T>(
  options: GraphQLOptions,
  localhost = isLocalhost()
): Promise<{ [key: string]: T }> => {
  let response: any = null;
  logger.debug('Executing GQL request', { options });
  try {
    if (localhost) {
      const headers = await customGraphQLHeaders();
      response = (await axios.post(getEndpoint(EndpointType.Graphql), options, { headers })).data;
    } else {
      response = await API.graphql(options);
    }
  } catch (err) {
    // For some reason Amplify throws a failing GQL request result rather than returning it with error messages
    response = err;
  }
  if (response?.errors?.length > 0) {
    const errorMessage = response.errors.map((error: { message: string }) => error.message).join(', ');
    logger.error('Error: Graphql request resulted in error response', { errorMessage, response });
    throw new Error(errorMessage);
  }
  if (!response?.data) {
    const errorMessage = 'Graphql did not return a valid result';
    logger.error('Error: Graphql result contains no data', { errorMessage, response });
    throw new Error(errorMessage);
  }
  logger.debug('GQL returned valid response', { response, options });
  return response.data;
};

/**
 * Executes GraphQL wrapped with retry functionality
 * @param options GraphQL parameters
 * @param retryOptions Configures how the GraphQL call is retried
 * @returns Result of GraphQL call
 */
export const execGQLWithRetry = async <T>(
  options: GraphQLOptions,
  retryOptions?: Partial<RetryOptions>,
  localhost = isLocalhost()
): Promise<{ [key: string]: T }> => {
  const isQuery = API.getGraphqlOperationType(options.query as string) === 'query';

  return retryAsync(
    async () => execGQLWithoutRetry(options, localhost),
    // TODO: update to allow retrying on transient errors for mutation operations
    () => isQuery,
    retryOptions
  );
};

/**
 * Function to convert ISO string to 'YYYY-MM-DD hh:mm A' format in user's local timezone.
 * @param date ISO string 'YYYY-MM-DDTHH:mm:ss.sssZ'
 * @returns string in 'MM/DD/YY - hh:mm A z' format
 */
export const localDateIn12HrFormat = (date: string): string => {
  if (!date) {
    return '';
  }
  // by default moment.tz return date in UTC if it cannot guess user's timezone.
  return moment.tz(new Date(date), moment.tz.guess()).format('MM/DD/YY - hh:mm A z');
};

/**
 * Function to convert ISO string to 'YYYY-MM-DD hh:mm:ss A' format in user's local timezone.
 * @param date ISO string 'YYYY-MM-DDTHH:mm:ss.sssZ'
 * @returns string in 'MM/DD/YY - hh:mm:ss A z' format
 */
export const localDateInTimestampFormat = (date: string): string => {
  if (!date) {
    return '';
  }
  // by default moment.tz return date in UTC if it cannot guess user's timezone.
  return moment.tz(new Date(date), moment.tz.guess()).format('MM/DD/YY - hh:mm:ss A z');
};

/**
 * Simple function to make a time more human friendly
 * @param seconds
 * @returns time string
 */
export const humanReadableSeconds = (seconds?: number): string => {
  if (!seconds || seconds < 0) {
    return '';
  }
  if (seconds > 7200) {
    return `${Math.round(seconds / 3600)} ${i18next.t('HOURS')}`;
  }
  if (seconds > 120) {
    return `${Math.round(seconds / 60)} ${i18next.t('MINUTES')}`;
  }
  return `${seconds} ${i18next.t('SECONDS')}`;
};

/**
 * Simple function to convert a snake case enum to human friendly case
 * @param status
 * @returns human readable string
 */
export const humanReadableStatus = (status?: string): string => {
  if (!status) {
    return '';
  }

  return status
    .split('_')
    .map((e: string) => {
      return e.substring(0, 1).toUpperCase() + e.substring(1).toLowerCase();
    })
    .join(' ');
};

/**
 * Append a message with error text
 * @param msg
 * @param err
 * @returns
 */
export const humanReadableError = (msg: string, err: Error): string => {
  const msgs = err.message.split(':');
  return `${msg} - ${msgs.splice(0, Math.min(4, msgs.length)).join(':')}`;
};

/**
 * Get the last section of an ID (UUID) for readability
 * @param id
 * @returns last section of an ID
 */
export const humanReadableId = (id: string): string => {
  if (!id) {
    return '';
  }

  // UUIDs are formatted as five sections separated by hyphens, in the form 8-4-4-4-12
  return id.substring(id.length - 12);
};

/**
 * Takes a CE id and returns a link to the CE detail page
 *
 * @param id - CE id (that may or may not be prefixed)
 * @returns URI for CE detail page
 */
export const ceLink = (id: string, host: string): string => {
  const trimmedID = id.replace('customer_', '');
  return `https://${host}/console/#/customer/${trimmedID}/details/`;
};

/**
 * Attempts to parse a JSON object
 * @param value any object
 * @returns true if the object parses to JSON, false otherwise
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const jsonValidation = (value?: any): boolean => {
  if (!value) return true;
  try {
    JSON.parse(value);
    return true;
  } catch (err) {
    return false;
  }
};

/**
 * Creates a md5 hash of a file. Reads the file in 2mb chunks, performing md5 along the way.
 * @param file - JS File class
 * @returns - string of the file hash
 */
export const getMd5HashOfFile = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      const { result } = e.target as FileReader;
      spark.append(result as ArrayBuffer); // Append array buffer
      currentChunk++;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        const hash = Buffer.from(spark.end(), 'hex').toString('base64');
        resolve(hash);
      }
    };

    fileReader.onerror = (err) => {
      reject(err);
    };

    function loadNext() {
      const start = currentChunk * chunkSize;
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize;

      const blob = file.slice(start, end);
      fileReader.readAsArrayBuffer(blob);
    }

    loadNext();
  });
};

export const getNullableEventValue = (event: Record<string, any>): any => {
  let { value } = event.target;
  if (value === '') {
    value = undefined;
  }
  return value;
};

export const isRequestSessionOpen = (request: Request): boolean => {
  return !!request?.sessionCloseTime && request.sessionCloseTime.isAfter(now());
};

/**
 * @description returns true if the request should be ignored towards counting in the limit
 * @param request
 * @returns boolean
 */
export const shouldIgnoreSession = (request: Request): boolean => {
  return (
    request.accessLevel === IApprovalLevel.AutoApprovalLevel &&
    // If the request is an aws account request, it will not be counted towards the limit
    (request.targetType === RequestTargetType.AWSAccount ||
      // If the request is only for a bastion, it will not be counted towards the limit
      (request.access?.length === 1 && request.access[0].type === IAccessTypes.BASTION))
  );
};

/**
 * @description converts an optional string into a string array by safely splitting on ','
 * @param s optional string
 * @returns string[]
 */
export const safeConvertOptionalStringToArray = (s: string | null | undefined): string[] => {
  if (s) {
    return s
      .split(',')
      .map((x) => x.trim())
      .filter((x) => x !== '');
  }
  return [];
};

/**
 * @description converts boolean to string safely
 * @param b optional boolean
 * @returns string
 */
export const safeConvertBooleanToString = (b: boolean | null | undefined): string => {
  if (typeof b === 'boolean') {
    return b.toString();
  }
  return '';
};

export const buildTargetAccessIDtoNameLookup = (
  awsRoleAccess: AwsRoleTargetAccess[],
  bastionAccess: BastionTargetAccess[],
  hostAccess: HostTargetAccess[],
  k8sRoleAccess: K8sRoleTargetAccess[]
): { [key: string]: string } => {
  const lookup: { [key: string]: string } = {};
  awsRoleAccess.forEach((item) => {
    lookup[item.id] = item.awsRoleName;
  });
  bastionAccess.forEach((item) => {
    lookup[item.id] = item.name;
  });
  hostAccess.forEach((item) => {
    lookup[item.id] = `${item.userName}@${item.hostName}`;
  });
  k8sRoleAccess.forEach((item) => {
    lookup[item.id] =
      item.namespace !== ''
        ? `${item.apiAccessType}:${item.roleName}:${item.namespace}`
        : `${item.apiAccessType}:${item.roleName}`;
  });
  return lookup;
};

const Helper = {
  isLocalhost,
  getEndpoint,
  getPlatform,
  findGetParameter,
  interpolateRoute,
  execGQLWithoutRetry,
  execGQL: execGQLWithRetry,
  execGQLWithRetry,
  localDateIn12HrFormat,
  localDateInTimestampFormat,
  humanReadableSeconds,
  humanReadableStatus,
  humanReadableError,
  humanReadableId,
  ceLink,
  jsonValidation,
  getNullableEventValue,
  getMd5HashOfFile,
  isRequestSessionOpen,
  shouldIgnoreSession,
  safeConvertOptionalStringToArray,
  safeConvertBooleanToString,
  buildTargetAccessIDtoNameLookup,
};

export default Helper;
