import { Box } from '@tanium/react-box';
import { Button } from '@tanium/react-button';
import { DefinitionList, DefinitionListItem } from '@tanium/react-definition-list';
import { Link } from '@tanium/react-link';
import { Modal } from '@tanium/react-modal';
import { Stack } from '@tanium/react-stack';
import { Tab, TabSet } from '@tanium/react-tabs';
import { TabSelectEvent } from '@tanium/react-use-tabs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { osName, OsTypes } from 'react-device-detect';
import DependencyArtifactsSelectors from 'components/app/store/dependencyArtifacts/DependencyArtifactsSelectors';
import DependencyArtifactsSlice from 'components/app/store/dependencyArtifacts/DependencyArtifactsSlice';
import RequiredSoftware from 'components/pages/request/requestPartials/RequiredSoftware';
import RequestSelectors from 'components/pages/request/store/RequestSelectors';
import RequestSlice from 'components/pages/request/store/RequestSlice';
import MediumBoldText from 'components/styles/text/MediumBoldText';
import MediumText from 'components/styles/text/MediumText';
import Helper from 'utils/Helper';
import { Tabs } from './HelpLink';

interface HelpModalFAQRow {
  text: string;
  url?: string;
}

const faqRows: HelpModalFAQRow[] = [
  {
    text: 'LOCKBOX_HELP_REQUEST',
    url: 'https://pages.git.corp.tanium.com/tanium-cloud/eng-docs/#/guide/lockbox_usage?id=how-to-open-a-lockbox-request',
  },
  {
    text: 'LOCKBOX_HELP_START_SESSION',
    url: 'https://pages.git.corp.tanium.com/tanium-cloud/eng-docs/#/guide/lockbox_usage?id=starting-a-lockbox-session',
  },
  {
    text: 'LOCKBOX_HELP_TIME_LIMITS',
    url: 'https://pages.git.corp.tanium.com/tanium-cloud/eng-docs/#/guide/lockbox_usage?id=time-limits',
  },
  {
    text: 'TIPS_AND_TRICKS',
    url: 'https://pages.git.corp.tanium.com/tanium-cloud/eng-docs/#/guide/lockbox_usage?id=bastion-considerations-and-tips',
  },
  {
    text: 'LOCKBOX_HELP_APPROVAL_PENDING',
    url: 'https://pages.git.corp.tanium.com/tanium-cloud/eng-docs/#/guide/lockbox_usage?id=lockbox-approvals',
  },
  {
    text: 'TANIUM_CLOUD_USAGE',
    url: 'https://pages.git.corp.tanium.com/tanium-cloud/eng-docs/#/guide/tanium-cloud_usage',
  },
];

interface HelpModalParams {
  isOpen: boolean;
  closeModal: () => void;
  tab: string;
  setTab: (tab: string) => void;
}

const HelpModal = ({ isOpen, closeModal, tab, setTab }: HelpModalParams): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lockboxMetadata = useSelector(RequestSelectors.selectLockboxMetadata());
  const dependencyArtifacts = useSelector(DependencyArtifactsSelectors.selectDependencyArtifacts);
  const [os, setOS] = useState('');

  useEffect(() => {
    dispatch(RequestSlice.actions.fetchConfigs());
  }, [dispatch]);

  useEffect(() => {
    let userOs = 'linux';
    /* istanbul ignore next */
    if (osName === OsTypes.MAC_OS) {
      /* istanbul ignore next */
      userOs = 'darwin';
      /* istanbul ignore next */
    } else if (osName === OsTypes.Windows) {
      /* istanbul ignore next */
      userOs = 'windows';
    }
    dispatch(DependencyArtifactsSlice.actions.fetchDependencyArtifacts({ OS: userOs }));
    setOS(userOs);
  }, [dispatch, os]);

  const openContactUs = () => {
    window.open(lockboxMetadata.slackUrl, '_blank', 'noreferrer');
  };

  const handleTabSelect = (e: TabSelectEvent): void => {
    const selectedTab = e.selectedId.toString();
    setTab(selectedTab);
  };

  return (
    <Modal
      isOpen={isOpen}
      header={t('HELP')}
      footer={
        <Stack inline itemSpacing={1}>
          <Button
            data-testid="contact-us"
            variant="primary"
            onClick={openContactUs}
            disabled={!lockboxMetadata.slackUrl}
          >
            {t('CONTACT_US')}
          </Button>
          <Button data-testid="close" variant="secondary" onClick={closeModal}>
            {t('CLOSE')}
          </Button>
        </Stack>
      }
    >
      <Box px={1}>
        <Box>
          <TabSet onSelect={handleTabSelect} selectedId={tab}>
            <Tab key={Tabs.FAQ} id={Tabs.FAQ} label={t('FAQ')}>
              <Box>
                <Box>
                  <MediumBoldText>{t('LOCKBOX_HELP')}:</MediumBoldText>
                </Box>
                {faqRows?.map(
                  (faqRow, i) =>
                    faqRow.text && (
                      <Box fontSize={14} pt={1} key={i}>
                        <Link href={faqRow.url} target="_blank">
                          <div>{t(faqRow.text)}</div>
                        </Link>
                      </Box>
                    )
                )}
              </Box>
            </Tab>
            <Tab key={Tabs.VERSION} id={Tabs.VERSION} label={t('VERSION')}>
              <Box p={1}>
                <DefinitionList>
                  <DefinitionListItem
                    term={<MediumBoldText>{t('UI_VERSION')}:</MediumBoldText>}
                    description={<MediumText>{lockboxMetadata.uiVersion || '-'}</MediumText>}
                  />
                  <DefinitionListItem
                    term={<MediumBoldText>{t('HANDLER_VERSION')}:</MediumBoldText>}
                    description={<MediumText>{lockboxMetadata.handlerVersion || '-'}</MediumText>}
                  />
                  <DefinitionListItem
                    term={<MediumBoldText>{t('INFRASTRUCTURE_VERSION')}:</MediumBoldText>}
                    description={<MediumText>{lockboxMetadata.infraVersion || '-'}</MediumText>}
                  />
                  <DefinitionListItem
                    term={<MediumBoldText>{t('UPDATED_AT')}:</MediumBoldText>}
                    description={
                      <MediumText>{Helper.localDateIn12HrFormat(lockboxMetadata.updatedAt) || '-'}</MediumText>
                    }
                  />
                </DefinitionList>
              </Box>
            </Tab>
            <Tab key={Tabs.REQUIRED_SOFTWARE} id={Tabs.REQUIRED_SOFTWARE} label={t('REQUIRED_SOFTWARE')}>
              <Box p={1}>
                <RequiredSoftware dependencyArtifacts={dependencyArtifacts} os={os} />
              </Box>
            </Tab>
          </TabSet>
        </Box>
      </Box>
    </Modal>
  );
};

export default HelpModal;
