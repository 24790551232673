import React from 'react';
import { Box } from '@tanium/react-box';
import { Stack, StackChild } from '@tanium/react-stack';
import { BreadcrumbItem, Breadcrumbs } from '@tanium/react-breadcrumbs';
import { useTranslation } from 'react-i18next';
import LargeBoldText from 'components/styles/text/LargeBoldText';
import LockboxLogo from 'static/lockbox_logo.svg';
import { Link } from '@tanium/react-link';

const DEFAULT_ICON_HEIGHT = 32;

interface Props {
  /** The page title string to display at the top of the page */
  title: string;
  /** List if breadcrumbs so far in the parent chain */
  breadcrumbs: BreadcrumbItem[];
  /** (optional) One or many buttons to display at the top of the page */
  buttons?: React.ReactNode[];
  /** (optional) For UI testing */
  testId?: string;
}

/**
 * Used for top-level pages to display a page title with optional action buttons above page content.
 */
const Page: React.FC<Props> = ({ title, breadcrumbs, buttons, children, testId }) => {
  const { t } = useTranslation();
  return (
    <Box data-testid={testId}>
      <Stack itemSpacing={2} flexWrap={'wrap'}>
        <StackChild grow={1}>
          <Box display="inline-flex" alignItems="center" padding={1}>
            <Link href="/">
              <Box display="flex" alignItems="center">
                <Box paddingRight={1}>
                  <img src={LockboxLogo} alt={title} height={DEFAULT_ICON_HEIGHT} />
                </Box>
                <Box>
                  <LargeBoldText>{t('LOCKBOX')}</LargeBoldText>
                </Box>
              </Box>
            </Link>
            <Box paddingLeft={1}>
              <Breadcrumbs breadcrumbs={[...breadcrumbs]} currentPageLabel={title} />
            </Box>
          </Box>
        </StackChild>

        {buttons &&
          buttons.map((button, i) => {
            return (
              <StackChild mt={1} key={`page-button-${i}`}>
                {button}
              </StackChild>
            );
          })}
      </Stack>
      <Box mb={4}>
        <hr />
      </Box>
      <Box mt={1} display="flex" flexDirection="column">
        {children}
      </Box>
    </Box>
  );
};

export default Page;
