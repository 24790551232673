import Permission, { PERMISSION } from 'gql/Permission';
import Helper from 'utils/Helper';

export default interface User {
  username: string;
  email: string;
  roles: string[];
  permissions: Permission[];
}

const GET_USER_INFO = `
    query user {
      user {
        username
        email
        roles
        permissions ${PERMISSION}
      }
    }
  `;

/**
 * Calls API for GET_USER_INFO
 * @returns Promise<User>
 */
export const getUser = async (): Promise<User> => {
  const result = await Helper.execGQL<User>({
    query: GET_USER_INFO,
  });
  return result.user;
};
