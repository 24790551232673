/* eslint-disable no-lonely-if */
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon } from '@tanium/react-graphics';
import { CircleIcon } from '@tanium/react-composed-icons';
import { HEX_335880 } from '@tanium/colors';
import MediumText from 'components/styles/text/MediumText';
import { DependencyArtifact } from 'gql/DependencyArtifacts';
import Tooltip from 'components/tooltip/Tooltip';

export interface IRequiredSoftwareLinks {
  artifact?: DependencyArtifact;
  installationLink?: string;
}

// This page manages the audit modal
const RequiredSoftwareLinks: React.VFC<IRequiredSoftwareLinks> = ({
  artifact,
  installationLink,
}): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      {artifact && (
        <>
          <Tooltip content={artifact.key}>
            <MediumText>
              <a target="_blank" rel="noreferrer" href={artifact.downloadURL}>
                {t('DOWNLOAD_LINK')}
                <CircleIcon
                  icon={ExternalLinkIcon}
                  outerRadius={5}
                  innerRadius={5}
                  iconColor={HEX_335880}
                  backgroundColor={'rgba(0,0,0,0)'}
                />
              </a>
            </MediumText>
          </Tooltip>
          &nbsp;
        </>
      )}
      {installationLink && (
        <MediumText>
          <a target="_blank" rel="noreferrer" href={installationLink}>
            {t('INSTALLATION_INSTRUCTIONS')}
            <CircleIcon
              icon={ExternalLinkIcon}
              outerRadius={5}
              innerRadius={5}
              iconColor={HEX_335880}
              backgroundColor={'rgba(0,0,0,0)'}
            />
          </a>
        </MediumText>
      )}
    </>
  );
};

export default RequiredSoftwareLinks;
