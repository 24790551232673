import { DependencyArtifact } from 'gql/DependencyArtifacts';
import { RootState } from 'store/RootReducer';

const selectDependencyArtifacts = (state: RootState): DependencyArtifact[] => {
  return state.dependencyArtifacts.dependencyArtifacts;
};

const DependencyArtifactsSelectors = {
  selectDependencyArtifacts,
};

export default DependencyArtifactsSelectors;
