import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Panel } from '@tanium/react-panel';
import { useTranslation } from 'react-i18next';
import {
  FieldRendererProps,
  RibbonColumn,
  StackedRibbonListMinimalStatic,
  StackedRibbonListWrapper,
} from '@tanium/react-stacked-ribbon-list';
import { Button } from '@tanium/react-button';
import { Box } from '@tanium/react-box';
import Audit from 'gql/Audit';
import { useWindowSize } from 'common/hooks/useWindowSize';
import MediumBoldText from 'components/styles/text/MediumBoldText';
import { CodeTextGreen, CodeTextRed } from 'components/styles/text/CodeText';
import PaddedText from 'components/styles/text/PaddedText';
import Helper from 'utils/Helper';
import { IResponsive } from 'common/IResponsive';
import AuditModal from 'components/pages/request/requestPartials/AuditModal';

export interface IAuditHistory {
  audits: Audit[] | [];
}

export type IColumnDefinition = RibbonColumn<Audit> & IResponsive;

export const AuditHistory: React.VFC<IAuditHistory> = ({ audits }: IAuditHistory) => {
  const [auditModalData, setAuditModelData] = useState<string | undefined>();
  const [columns, setColumns] = useState<IColumnDefinition[]>([]);
  const { t } = useTranslation();
  const size = useWindowSize();

  const auditGetter = useCallback((index: number) => audits[index], [audits]);

  // Handle opening / closing the audit modal
  const viewAuditDetails = useCallback(
    (audit: Audit) => {
      setAuditModelData(audit.newImage || audit.oldImage);
    },
    [setAuditModelData]
  );

  const closeAuditModal = () => {
    setAuditModelData(undefined);
  };

  const auditColumns = useMemo(
    () =>
      [
        {
          label: t('ACTOR'),
          dataKey: 'createdBy',
          primary: true,
          width: 180,
          isSortDisabled: true,
          renderer: ({ itemData }: FieldRendererProps<Audit>): React.ReactElement => {
            return <div className="truncated-text">{itemData.createdBy.replace('@tanium.com', '')}</div>;
          },
        },
        {
          label: t('ACTION'),
          dataKey: 'eventName',
          grow: 1,
          isSortDisabled: true,
          breakpoint: 750,
          renderer: ({ itemData }: FieldRendererProps<Audit>): React.ReactElement => {
            return <>{itemData.eventName.toLowerCase()}</>;
          },
        },
        {
          label: t('ITEM'),
          dataKey: 'itemType',
          grow: 1,
          breakpoint: 750,
          renderer: ({ itemData }: FieldRendererProps<Audit>): React.ReactElement => {
            return <>{itemData.itemType}</>;
          },
        },
        {
          label: t('OCCURRED_AT'),
          dataKey: 'createdAt',
          grow: 1,
          breakpoint: 490,
          renderer: ({ itemData }: FieldRendererProps<Audit>): React.ReactElement => {
            return <>{Helper.localDateIn12HrFormat(itemData.createdAt)}</>;
          },
        },
        {
          label: t('DIFFS'),
          dataKey: 'diffs',
          grow: 1,
          breakpoint: 880,
          renderer: ({ itemData }: FieldRendererProps<Audit>): React.ReactElement => {
            return (
              <>
                {itemData.diffs &&
                  itemData.diffs.map((diff, i) => {
                    return (
                      !['updatedAt', 'updatedBy'].includes(diff.member) && (
                        <React.Fragment key={diff.member}>
                          <Box paddingTop={i ? 1 : 0}>
                            <div>
                              Field: <MediumBoldText>{diff.member}</MediumBoldText>
                            </div>
                            <div aria-label={t('PRIOR_VALUE')} title={t('PRIOR_VALUE')}>
                              <CodeTextRed>
                                {diff.oldValue && (
                                  <PaddedText>
                                    <MediumBoldText>{'-- '}</MediumBoldText>
                                    {diff.oldValue}
                                  </PaddedText>
                                )}
                              </CodeTextRed>
                            </div>
                            <div aria-label={t('UPDATED_TO')} title={t('UPDATED_TO')}>
                              <CodeTextGreen>
                                {diff.newValue && (
                                  <PaddedText>
                                    <MediumBoldText>{'++ '}</MediumBoldText>
                                    {diff.newValue}
                                  </PaddedText>
                                )}
                              </CodeTextGreen>
                            </div>
                          </Box>
                        </React.Fragment>
                      )
                    );
                  })}
              </>
            );
          },
        },
        {
          label: t('DETAILS'),
          dataKey: 'newImage',
          width: 80,
          renderer: ({ itemData }: FieldRendererProps<Audit>): React.ReactElement => {
            return (
              <Button
                data-testid={`view-details-${itemData.id}`}
                onClick={() => viewAuditDetails(itemData)}
                variant="secondary"
              >
                {t('DETAILS')}
              </Button>
            );
          },
        },
      ] as IColumnDefinition[],
    [t, viewAuditDetails]
  );

  useEffect(() => {
    const filteredForPageWidth = auditColumns.filter((column) => !(size.width < column.breakpoint));
    setColumns(filteredForPageWidth);
  }, [size, auditColumns]);

  if (audits?.length) {
    return (
      <div>
        <Panel title={t('ACTIVITY_HISTORY')} isCollapsible defaultCollapsed>
          <StackedRibbonListWrapper>
            <StackedRibbonListMinimalStatic
              columns={columns}
              dataGetter={auditGetter}
              isItemLoaded={(): boolean => true}
              itemCount={audits.length}
              itemIdGetter={(i): string => auditGetter(i).id}
            />
          </StackedRibbonListWrapper>
        </Panel>
        <AuditModal auditModalData={auditModalData} closeModal={closeAuditModal} />
      </div>
    );
  }
  return null;
};

export default AuditHistory;
