import Helper from 'utils/Helper';
import { IDed } from './Request';

export interface RestartInstanceRequest {
  requestID: string;
  instanceID: string;
}

export interface RestartInstanceResponse extends IDed {
  requestID: string;
  instanceID: string;
}

const RESTART_INSTANCE = `
        mutation restartInstance($requestID: String!, $instanceID: String!) {
            restartInstance(requestID: $requestID, instanceID: $instanceID) {
                requestID
                instanceID
            }
        }
  `;

export const execRestartInstance = async (variables: RestartInstanceRequest): Promise<RestartInstanceResponse> => {
  const response = await Helper.execGQL<RestartInstanceResponse>({
    query: RESTART_INSTANCE,
    variables,
  });
  return response.restartInstance;
};
