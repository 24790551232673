import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/RootReducer';
import { ActionStatus } from 'store/ActionState';
import { AwsRoleTargetAccess } from 'gql/AwsRoleTargetAccess';
import { AwsRoleTargetAccessState, awsRoleTargetAccessAdapter } from './AwsRoleTargetAccessSlice';

const selectSelf = (state: RootState): AwsRoleTargetAccessState => {
  return state.awsRoleTargetAccess;
};

const selectAwsRoleTargetAccessLoading = createSelector(selectSelf, (state: AwsRoleTargetAccessState): boolean => {
  return state.loadingState.status === ActionStatus.PROCESSING;
});

const selectAwsRoleTargetAccesses = createSelector(
  selectSelf,
  (state: AwsRoleTargetAccessState): AwsRoleTargetAccess[] => {
    return awsRoleTargetAccessAdapter.getSelectors().selectAll(state.awsRoleTargetAccesses);
  }
);

const selectAwsRoleTargetAccess = (id?: string) => {
  return (state: RootState): AwsRoleTargetAccess | undefined => {
    return id
      ? awsRoleTargetAccessAdapter.getSelectors().selectById(state.awsRoleTargetAccess.awsRoleTargetAccesses, id)
      : undefined;
  };
};

const selectMutationSucceeded = createSelector(selectSelf, (state: AwsRoleTargetAccessState): boolean => {
  return state.mutationState.status === ActionStatus.SUCCEEDED;
});

const AwsRoleTargetAccessSelectors = {
  selectMutationSucceeded,
  selectAwsRoleTargetAccessLoading,
  selectAwsRoleTargetAccesses,
  selectAwsRoleTargetAccess,
};

export default AwsRoleTargetAccessSelectors;
