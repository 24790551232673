import { catchError, concatAll, filter, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import i18next from 'i18next';
import { PayloadAction } from '@reduxjs/toolkit';
import RootEpic from 'store/RootEpic';
import K8sRoleTargetAccessSlice from 'components/pages/console/store/K8sRoleTargetAccessSlice';
import {
  createK8sRoleTargetAccess,
  listK8sRoleTargetAccess,
  updateK8sRoleTargetAccess,
  deleteK8sRoleTargetAccess,
  getK8sRoleTargetAccess,
  ListK8sRoleTargetAccessOptions,
} from 'gql/K8sRoleTargetAccess';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';
import { PermissionAction, PermissionResource, hasPermission } from 'gql/Permission';

// GET
export const getK8sRoleTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.getK8sRoleTargetAccessByID.match),
    mergeMap(({ payload }) => {
      return from(getK8sRoleTargetAccess(payload)).pipe(
        map((response) => K8sRoleTargetAccessSlice.actions.getK8sRoleTargetAccessByIDSucceeded(response)),
        catchError((error) => {
          return of(K8sRoleTargetAccessSlice.actions.getK8sRoleTargetAccessByIDFailed(error));
        })
      );
    })
  );

// LIST
export const listK8sRoleTargetAccessEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(() =>
      hasPermission(
        state$.value.user.user?.permissions,
        PermissionAction.READ,
        PermissionResource.K8S_ROLE_TARGET_ACCESS
      )
    ),
    filter(
      (action): action is PayloadAction<ListK8sRoleTargetAccessOptions> =>
        K8sRoleTargetAccessSlice.actions.listK8sRoleTargetAccess.match(action) ||
        K8sRoleTargetAccessSlice.actions.listNextPageK8sRoleTargetAccess.match(action)
    ),
    mergeMap(({ payload }) => {
      return from(listK8sRoleTargetAccess(payload)).pipe(
        map((response) => {
          return response.nextToken
            ? [
                K8sRoleTargetAccessSlice.actions.listK8sRoleTargetAccessSucceeded(response.items),
                K8sRoleTargetAccessSlice.actions.listNextPageK8sRoleTargetAccess({
                  targetOrTargetGroupID: payload.targetOrTargetGroupID,
                  includeAllTargetGroups: payload.includeAllTargetGroups,
                  userADGroups: payload.userADGroups,
                  nextToken: response.nextToken,
                  size: payload.size,
                }),
              ]
            : [K8sRoleTargetAccessSlice.actions.listK8sRoleTargetAccessSucceeded(response.items)];
        }),
        concatAll(),
        catchError((error) => {
          return of(K8sRoleTargetAccessSlice.actions.listK8sRoleTargetAccessFailed(error));
        })
      );
    })
  );

export const listK8sRoleTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.listK8sRoleTargetAccessFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_K8S_TARGET_ACCESS'), err: payload }))
  );

// CREATE
export const createK8sRoleTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.createK8sRoleTargetAccess.match),
    mergeMap(({ payload }) =>
      from(createK8sRoleTargetAccess(payload)).pipe(
        map((response) => K8sRoleTargetAccessSlice.actions.createK8sRoleTargetAccessSucceeded(response)),
        catchError((error) => of(K8sRoleTargetAccessSlice.actions.createK8sRoleTargetAccessFailed(error)))
      )
    )
  );

export const createK8sRoleTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.createK8sRoleTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_CREATED_K8S_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const createK8sRoleTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.createK8sRoleTargetAccessFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_CREATE_K8S_TARGET_ACCESS'), err: payload }))
  );

// UPDATE
export const updateK8sRoleTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.updateK8sRoleTargetAccess.match),
    mergeMap(({ payload }) => {
      return from(updateK8sRoleTargetAccess(payload)).pipe(
        map((response) => K8sRoleTargetAccessSlice.actions.updateK8sRoleTargetAccessSucceeded(response)),
        catchError((error) => of(K8sRoleTargetAccessSlice.actions.updateK8sRoleTargetAccessFailed(error)))
      );
    })
  );

export const updateK8sRoleTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.updateK8sRoleTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_UPDATED_K8S_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const updateK8sRoleTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.updateK8sRoleTargetAccessFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_UPDATE_K8S_TARGET_ACCESS'), err: payload });
    })
  );

// DELETE
export const deleteK8sRoleTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.deleteK8sRoleTargetAccess.match),
    mergeMap(({ payload }) => {
      return from(deleteK8sRoleTargetAccess(payload)).pipe(
        map((response) => K8sRoleTargetAccessSlice.actions.deleteK8sRoleTargetAccessSucceeded(response)),
        catchError((error) => of(K8sRoleTargetAccessSlice.actions.deleteK8sRoleTargetAccessFailed(error)))
      );
    })
  );

export const deleteK8sRoleTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.deleteK8sRoleTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_DELETED_K8S_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const deleteK8sRoleTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(K8sRoleTargetAccessSlice.actions.deleteK8sRoleTargetAccessFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_DELETE_K8S_TARGET_ACCESS'), err: payload });
    })
  );

const K8sRoleTargetAccessEpics: RootEpic[] = [
  getK8sRoleTargetAccessEpic,
  listK8sRoleTargetAccessEpic,
  listK8sRoleTargetAccessFailedEpic,
  createK8sRoleTargetAccessEpic,
  createK8sRoleTargetAccessSuccessEpic,
  createK8sRoleTargetAccessFailedEpic,
  updateK8sRoleTargetAccessEpic,
  updateK8sRoleTargetAccessSuccessEpic,
  updateK8sRoleTargetAccessFailedEpic,
  deleteK8sRoleTargetAccessEpic,
  deleteK8sRoleTargetAccessSuccessEpic,
  deleteK8sRoleTargetAccessFailedEpic,
];

export default K8sRoleTargetAccessEpics;
