import { catchError, concatAll, filter, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import i18next from 'i18next';
import { PayloadAction } from '@reduxjs/toolkit';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';
import RootEpic from 'store/RootEpic';
import {
  ListBastionTargetAccessOptions,
  createBastionTargetAccess,
  deleteBastionTargetAccess,
  getBastionTargetAccess,
  listBastionTargetAccess,
  updateBastionTargetAccess,
} from 'gql/BastionTargetAccess';
import { PermissionAction, PermissionResource, hasPermission } from 'gql/Permission';
import BastionTargetAccessSlice from './BastionTargetAccessSlice';

// GET
export const getBastionTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.getBastionTargetAccessByID.match),
    mergeMap(({ payload }) => {
      return from(getBastionTargetAccess(payload)).pipe(
        map((response) => BastionTargetAccessSlice.actions.getBastionTargetAccessByIDSucceeded(response)),
        catchError((error) => {
          return of(BastionTargetAccessSlice.actions.getBastionTargetAccessByIDFailed(error));
        })
      );
    })
  );

export const getBastionTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.getBastionTargetAccessByIDFailed.match),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_GET_BASTION_TARGET_ACCESS'), err: payload })
    )
  );

// LIST
export const listBastionTargetAccessEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(() =>
      hasPermission(
        state$.value.user.user?.permissions,
        PermissionAction.READ,
        PermissionResource.BASTION_TARGET_ACCESS
      )
    ),
    filter(
      (action): action is PayloadAction<ListBastionTargetAccessOptions> =>
        BastionTargetAccessSlice.actions.listBastionTargetAccessForTargetOrGroup.match(action) ||
        BastionTargetAccessSlice.actions.listNextPageBastionTargetAccessForTargetOrGroup.match(action)
    ),
    mergeMap(({ payload }) => {
      return from(listBastionTargetAccess(payload)).pipe(
        map((response) => {
          return response.nextToken
            ? [
                BastionTargetAccessSlice.actions.listBastionTargetAccessForTargetOrGroupSucceeded(response.items),
                BastionTargetAccessSlice.actions.listNextPageBastionTargetAccessForTargetOrGroup({
                  includeAllTargetGroups: payload.includeAllTargetGroups,
                  targetOrTargetGroupID: payload.targetOrTargetGroupID,
                  userADGroups: payload.userADGroups,
                  nextToken: response.nextToken,
                  size: payload.size,
                }),
              ]
            : [BastionTargetAccessSlice.actions.listBastionTargetAccessForTargetOrGroupSucceeded(response.items)];
        }),
        concatAll(),
        catchError((error) => {
          return of(BastionTargetAccessSlice.actions.listBastionTargetAccessForTargetOrGroupFailed(error));
        })
      );
    })
  );

export const listBastionTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.listBastionTargetAccessForTargetOrGroupFailed.match),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_GET_BASTION_TARGET_ACCESS'), err: payload })
    )
  );

// CREATE
export const createBastionTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.createBastionTargetAccess.match),
    mergeMap(({ payload }) =>
      from(createBastionTargetAccess(payload)).pipe(
        map((response) => BastionTargetAccessSlice.actions.createBastionTargetAccessSucceeded(response)),
        catchError((error) => of(BastionTargetAccessSlice.actions.createBastionTargetAccessFailed(error)))
      )
    )
  );

export const createBastionTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.createBastionTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_CREATED_BASTION_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const createBastionTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.createBastionTargetAccessFailed.match),
    map(({ payload }) =>
      showNotificationFromError({ msg: i18next.t('FAILED_CREATE_BASTION_TARGET_ACCESS'), err: payload })
    )
  );

// UPDATE
export const updateBastionTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.updateBastionTargetAccess.match),
    mergeMap(({ payload }) => {
      return from(updateBastionTargetAccess(payload)).pipe(
        map((response) => BastionTargetAccessSlice.actions.updateBastionTargetAccessSucceeded(response)),
        catchError((error) => of(BastionTargetAccessSlice.actions.updateBastionTargetAccessFailed(error)))
      );
    })
  );

export const updateBastionTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.updateBastionTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_UPDATED_BASTION_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const updateBastionTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.updateBastionTargetAccessFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_UPDATE_BASTION_TARGET_ACCESS'), err: payload });
    })
  );

// DELETE
export const deleteBastionTargetAccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.deleteBastionTargetAccess.match),
    mergeMap(({ payload }) => {
      return from(deleteBastionTargetAccess(payload)).pipe(
        map((response) => BastionTargetAccessSlice.actions.deleteBastionTargetAccessSucceeded(response)),
        catchError((error) => of(BastionTargetAccessSlice.actions.deleteBastionTargetAccessFailed(error)))
      );
    })
  );

export const deleteBastionTargetAccessSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.deleteBastionTargetAccessSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_DELETED_BASTION_TARGET_ACCESS', action.payload.id),
        }),
      ];
    })
  );

export const deleteBastionTargetAccessFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(BastionTargetAccessSlice.actions.deleteBastionTargetAccessFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_DELETE_BASTION_TARGET_ACCESS'), err: payload });
    })
  );

const BastionTargetAccessEpics: RootEpic[] = [
  getBastionTargetAccessEpic,
  getBastionTargetAccessFailedEpic,
  listBastionTargetAccessEpic,
  listBastionTargetAccessFailedEpic,
  createBastionTargetAccessEpic,
  createBastionTargetAccessSuccessEpic,
  createBastionTargetAccessFailedEpic,
  updateBastionTargetAccessEpic,
  updateBastionTargetAccessSuccessEpic,
  updateBastionTargetAccessFailedEpic,
  deleteBastionTargetAccessEpic,
  deleteBastionTargetAccessFailedEpic,
  deleteBastionTargetAccessSuccessEpic,
];

export default BastionTargetAccessEpics;
