/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN from 'locales/en/translation.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  defaultNS: 'translation',
  resources: {
    en: {
      translation: EN,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  initImmediate: true,
});

export default i18n;
