import React from 'react';
import { Link } from 'react-router-dom';
import logo from 'static/tanium_logo.svg';

const DEFAULT_LOGO_HEIGHT = 24;

const TaniumLogo: React.VFC = () => {
  return (
    <Link to="/">
      <img src={logo} alt="Tanium logo" height={DEFAULT_LOGO_HEIGHT} />
    </Link>
  );
};

export default TaniumLogo;
