import i18next from 'i18next';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import RootEpic from 'store/RootEpic';
import WorkflowSlice from 'components/pages/adminPanel/store/workflow/WorkflowSlice';
import { restartWorkflow } from 'gql/Workflow';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';

export const restartWorkflowEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(WorkflowSlice.actions.restartWorkflow.match),
    mergeMap(({ payload }) => {
      return from(restartWorkflow(payload)).pipe(
        map((response) => WorkflowSlice.actions.restartWorkflowSucceeded(response)),
        catchError((error) => of(WorkflowSlice.actions.restartWorkflowFailed(error)))
      );
    })
  );

export const restartWorkflowSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(WorkflowSlice.actions.restartWorkflowSucceeded.match),
    mergeMap((action) => {
      return [
        showNotification({
          type: 'success',
          contents: i18next.t('SUCCESS_RESTARTED_WORKFLOW', action.payload.requestID),
        }),
      ];
    })
  );

export const restartWorkflowFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(WorkflowSlice.actions.restartWorkflowFailed.match),
    map(({ payload }) => {
      return showNotificationFromError({ msg: i18next.t('FAILED_RESTART_WORKFLOW'), err: payload });
    })
  );

const WorkflowEpics: RootEpic[] = [restartWorkflowEpic, restartWorkflowSuccessEpic, restartWorkflowFailedEpic];

export default WorkflowEpics;
