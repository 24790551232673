import styled from '@tanium/react-emotion-9';
import { css } from '@tanium/style-system';

type ICodeText = {
  fontSize?: string;
  backgroundColor?: string;
  padding?: string;
};

export const CodeText = styled('code')<ICodeText>`
  font-family: source-code-pro, monospace;
  font-size: ${(props) => props.fontSize || '14px'};
  background-color: ${(props) => props.backgroundColor || 'inherit'};
  padding: ${(props) => props.padding || 'none'};
`;

export const CodeTextGreen = styled(CodeText)(
  css({
    lineHeight: '1.25em',
    fontFamily: 'monospace',
    backgroundColor: 'rgba(0, 255, 45, 0.25)',
    display: 'inline-block',
  })
);

export const CodeTextRed = styled(CodeText)(
  css({
    lineHeight: '1.25em',
    fontFamily: 'monospace',
    backgroundColor: 'rgba(255, 15, 0, 0.35)',
    display: 'inline-block',
  })
);

export default CodeText;
