import i18next from 'i18next';
import Helper from 'utils/Helper';
import { IDed } from 'gql/Request';

export default interface Approval extends IDed {
  createdBy: string;
  createdAt: string;
  updatedBy: string;
  updatedAt: string;
  requestId: string;
  acceptedBy?: string;
  acceptedAt?: string;
  rejectedBy?: string;
  rejectedAt?: string;
  canAccept: boolean;
  canReject: boolean;
}

export const approvalStatus = (approval: Approval): string => {
  let status = i18next.t('PENDING');
  if (approval.acceptedAt) {
    status = i18next.t('APPROVED');
  } else if (approval.rejectedAt) {
    status = i18next.t('REJECTED');
  }
  return status;
};

export const APPROVAL = `{
  id
  createdBy
  createdAt
  updatedBy
  updatedAt
  requestId
  acceptedBy
  acceptedAt
  rejectedBy
  rejectedAt
  canAccept
  canReject
}`;

export interface AcceptOrRejectApproval {
  approvalID: string;
  requestID: string;
  grant: boolean;
}

const GRANT_APPROVAL = `
    mutation acceptOrRejectApproval($approvalID: String!, $requestID: String!, $grant: Boolean!) {
      acceptOrRejectApproval (approvalID: $approvalID, requestID: $requestID, grant: $grant) ${APPROVAL}
    }
  `;

export const grantApproval = async (variables: AcceptOrRejectApproval): Promise<Approval> => {
  const result = await Helper.execGQL<Approval>({
    query: GRANT_APPROVAL,
    variables,
  });
  return result.acceptOrRejectApproval;
};
