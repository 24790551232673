import { mergeMap, catchError, map } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ofType } from 'redux-observable';
import i18next from 'i18next';
import RootEpic from 'store/RootEpic';
import { showNotificationFromError } from 'components/app/store/UserSlice';
import { getCustomer } from 'gql/Customer';
import CustomerSlice from './CustomerSlice';

export const fetchCustomerDataEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(CustomerSlice.actions.fetchCustomer),
    mergeMap((action) =>
      from(getCustomer({ id: action.payload.id })).pipe(
        map((response) => CustomerSlice.actions.fetchCustomerSucceeded(response)),
        catchError((error) => of(CustomerSlice.actions.fetchCustomerFailed(error)))
      )
    )
  );

export const fetchCustomerDataFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    ofType(CustomerSlice.actions.fetchCustomerFailed),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_CUSTOMER'), err: payload }))
  );

const RequestEpics: RootEpic[] = [fetchCustomerDataEpic, fetchCustomerDataFailedEpic];

export default RequestEpics;
