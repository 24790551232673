import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { ListTargetGroupOptions, TargetGroup } from 'gql/TargetGroup';
import ActionState, { ActionStatus } from 'store/ActionState';
import { IDed } from 'gql/Request';

export type TargetGroupState = {
  targetGroup?: TargetGroup;
  targetGroups: EntityState<TargetGroup>;
  loadingState: ActionState;
  mutationState: ActionState;
  saveForm?: TargetGroup;
  mutatedTargetGroupId?: string;
};

export const targetGroupAdapter = createEntityAdapter<TargetGroup>();

export const initialState: TargetGroupState = {
  targetGroups: targetGroupAdapter.getInitialState(),
  loadingState: { status: ActionStatus.IDLE },
  mutationState: { status: ActionStatus.IDLE },
};

const TargetGroupSlice = createSlice({
  name: 'targetGroup',
  initialState,
  reducers: {
    // LIST
    listTargetGroups: (state, _: PayloadAction<ListTargetGroupOptions | undefined>): TargetGroupState => {
      return { ...state, loadingState: { status: ActionStatus.PROCESSING } };
    },
    listTargetGroupsSucceeded: (state, action: PayloadAction<TargetGroup[]>): TargetGroupState => {
      return {
        ...state,
        targetGroups:
          action.payload.length === 0
            ? state.targetGroups
            : targetGroupAdapter.upsertMany({ ...state.targetGroups }, action.payload),
        loadingState: { status: ActionStatus.SUCCEEDED },
      };
    },
    listTargetGroupsFailed: (state, action: PayloadAction<Error>): TargetGroupState => {
      return { ...state, loadingState: { status: ActionStatus.FAILED, error: action.payload } };
    },

    // GET
    getTargetGroup: (state, _: PayloadAction<string>): TargetGroupState => {
      return { ...state, targetGroup: undefined, loadingState: { status: ActionStatus.PROCESSING } };
    },
    getTargetGroupSucceeded: (state, action: PayloadAction<TargetGroup>): TargetGroupState => {
      return {
        ...state,
        targetGroup: action.payload,
        loadingState: { status: ActionStatus.SUCCEEDED },
      };
    },
    getTargetGroupFailed: (state, action: PayloadAction<Error>): TargetGroupState => {
      return { ...state, loadingState: { status: ActionStatus.FAILED, error: action.payload } };
    },

    // CREATE
    createTargetGroup: (state, action: PayloadAction<TargetGroup>): TargetGroupState => {
      return {
        ...state,
        saveForm: action.payload,
        mutationState: { status: ActionStatus.PROCESSING },
        mutatedTargetGroupId: undefined,
      };
    },
    createTargetGroupSucceeded: (state, action: PayloadAction<IDed>): TargetGroupState => {
      return {
        ...state,
        saveForm: undefined,
        mutationState: { status: ActionStatus.SUCCEEDED },
        mutatedTargetGroupId: action.payload.id,
      };
    },
    createTargetGroupFailed: (state, action: PayloadAction<Error>): TargetGroupState => {
      return {
        ...state,
        saveForm: undefined,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
      };
    },

    // UPDATE
    updateTargetGroup: (state, action: PayloadAction<TargetGroup>): TargetGroupState => {
      return {
        ...state,
        saveForm: action.payload,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    updateTargetGroupSucceeded: (state, action: PayloadAction<IDed>): TargetGroupState => {
      return {
        ...state,
        saveForm: undefined,
        mutationState: { status: ActionStatus.SUCCEEDED },
        mutatedTargetGroupId: action.payload.id,
      };
    },
    updateTargetGroupFailed: (state, action: PayloadAction<Error>): TargetGroupState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
        saveForm: undefined,
      };
    },

    // DELETE
    deleteTargetGroup: (state, _: PayloadAction<string>): TargetGroupState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.PROCESSING },
      };
    },
    deleteTargetGroupSucceeded: (state, action: PayloadAction<IDed>): TargetGroupState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.SUCCEEDED },
        mutatedTargetGroupId: action.payload.id,
      };
    },
    deleteTargetGroupFailed: (state, action: PayloadAction<Error>): TargetGroupState => {
      return {
        ...state,
        mutationState: { status: ActionStatus.FAILED, error: action.payload },
        saveForm: undefined,
      };
    },
  },
});

export default TargetGroupSlice;
