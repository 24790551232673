enum LogLevel {
  Trace,
  Debug,
  Info,
  Warn,
  Error,
}

export type LogLevelStrings = keyof typeof LogLevel;

export default LogLevel;
