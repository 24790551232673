import { push } from 'connected-react-router';
import i18next from 'i18next';
import { from, of } from 'rxjs';
import { catchError, concatAll, map, mergeMap, filter } from 'rxjs/operators';
import { showNotification, showNotificationFromError } from 'components/app/store/UserSlice';
import AwsAccountObjectSlice from 'components/pages/console/store/AwsAccountObjectSlice';
import { saveAwsAccountObject, getAwsAccountObjects } from 'gql/AwsAccountObject';
import { hasPermission, PermissionAction, PermissionResource } from 'gql/Permission';
import { AWS_ACCOUNT_ROUTE, CONSOLE_ROUTE } from 'Route';
import RootEpic from 'store/RootEpic';
import Helper from 'utils/Helper';

export const fetchAwsAccountObjectsEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(() =>
      hasPermission(state$.value.user.user?.permissions, PermissionAction.READ, PermissionResource.AWS_ACCOUNT)
    ),
    filter(AwsAccountObjectSlice.actions.fetchAwsAccountObjects.match),
    mergeMap((action) =>
      from(
        getAwsAccountObjects({ viewAllRoles: action.payload.viewAllRoles, nextToken: action.payload.nextToken })
      ).pipe(
        map((response) => AwsAccountObjectSlice.actions.fetchAwsAccountObjectsSucceeded(response)),
        catchError((error) => of(AwsAccountObjectSlice.actions.fetchAwsAccountObjectsFailed(error)))
      )
    )
  );

export const fetchAllAwsAccountObjectsEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(() =>
      hasPermission(state$.value.user.user?.permissions, PermissionAction.READ, PermissionResource.AWS_ACCOUNT)
    ),
    filter(AwsAccountObjectSlice.actions.fetchAllAwsAccountObjects.match),
    mergeMap((action) =>
      from(
        getAwsAccountObjects({ viewAllRoles: action.payload.viewAllRoles, nextToken: action.payload.nextToken })
      ).pipe(
        map((response) =>
          response.length > 0 && response[response.length - 1].nextToken
            ? [
                AwsAccountObjectSlice.actions.fetchAwsAccountObjectsSucceeded(response),
                AwsAccountObjectSlice.actions.fetchAllAwsAccountObjects({
                  viewAllRoles: action.payload.viewAllRoles,
                  nextToken: response[response.length - 1].nextToken,
                }),
              ]
            : [AwsAccountObjectSlice.actions.fetchAwsAccountObjectsSucceeded(response)]
        ),
        concatAll(),
        catchError((error) => of(AwsAccountObjectSlice.actions.fetchAwsAccountObjectsFailed(error)))
      )
    )
  );

export const fetchAwsAccountObjectsFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsAccountObjectSlice.actions.fetchAwsAccountObjectsFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_GET_AWS_ACCOUNTS'), err: payload }))
  );

export const saveAwsAccountObjectEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsAccountObjectSlice.actions.saveAwsAccountObject.match),
    mergeMap((action) =>
      from(saveAwsAccountObject(action.payload)).pipe(
        map((response) => AwsAccountObjectSlice.actions.saveAwsAccountObjectSucceeded(response!)),
        catchError((error) => of(AwsAccountObjectSlice.actions.saveAwsAccountObjectFailed(error)))
      )
    )
  );

export const saveAwsAccountObjectSuccessEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsAccountObjectSlice.actions.saveAwsAccountObjectSucceeded.match),
    mergeMap((action) => [
      showNotification({
        type: 'success',
        contents: i18next.t('SUCCESS_SAVED_AWS_ACCOUNT', action.payload.id),
      }),
      push(Helper.interpolateRoute(AWS_ACCOUNT_ROUTE.url, { awsAccountObjectId: action.payload.id })),
    ])
  );

export const saveAwsAccountObjectFailedEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsAccountObjectSlice.actions.saveAwsAccountObjectFailed.match),
    map(({ payload }) => showNotificationFromError({ msg: i18next.t('FAILED_SAVE_AWS_ACCOUNT'), err: payload }))
  );

export const saveAwsAccountObjectCanceledEpic: RootEpic = (action$) =>
  action$.pipe(
    filter(AwsAccountObjectSlice.actions.cancelSaveAwsAccountObject.match),
    map((action) =>
      action.payload
        ? push(Helper.interpolateRoute(AWS_ACCOUNT_ROUTE.url, { awsAccountObjectId: action.payload.id }))
        : push(`${CONSOLE_ROUTE.url}?tab=awsAccounts`)
    )
  );

const AwsAccountObjectEpics: RootEpic[] = [
  fetchAwsAccountObjectsEpic,
  fetchAwsAccountObjectsFailedEpic,
  saveAwsAccountObjectEpic,
  saveAwsAccountObjectSuccessEpic,
  saveAwsAccountObjectFailedEpic,
  saveAwsAccountObjectCanceledEpic,
  fetchAllAwsAccountObjectsEpic,
];

export default AwsAccountObjectEpics;
