/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import LogLevel from 'logger/LogLevel';
import LoggerConfig from 'logger/LoggerConfig';

export default class Logger {
  /* istanbul ignore next */
  private static config: LoggerConfig = {
    // We are disabling the default appender in test env
    appenders: [],
    level: LogLevel.Info,
  };

  private static loggers: Record<string, Logger> = {};

  constructor(private tag: string) {}

  public static setConfig(config: LoggerConfig): void {
    Logger.config = config;
  }

  public static getLogger(tag: string): Logger {
    if (!this.loggers[tag]) {
      this.loggers[tag] = new Logger(tag);
    }

    return this.loggers[tag];
  }

  public error(msg: string, meta?: any): void {
    this.log(LogLevel.Error, msg, meta);
  }

  public warn(msg: string, meta?: any): void {
    this.log(LogLevel.Warn, msg, meta);
  }

  public info(msg: string, meta?: any): void {
    this.log(LogLevel.Info, msg, meta);
  }

  public debug(msg: string, meta?: any): void {
    this.log(LogLevel.Debug, msg, meta);
  }

  public trace(msg: string, meta?: any): void {
    this.log(LogLevel.Trace, msg, meta);
  }

  private log(level: LogLevel, message: string, meta?: any): void {
    if (level < Logger.config.level) {
      return;
    }

    const now = new Date();
    Logger.config.appenders.forEach((a) => {
      a.append(level, now, message, this.tag, meta);
    });
  }
}
