import React, { useState, useEffect } from 'react';
import { Stack } from '@tanium/react-stack';
import { Modal } from '@tanium/react-modal';
import { Button } from '@tanium/react-button';
import { useTranslation } from 'react-i18next';

interface Params {
  isOpen: boolean;
  timeoutSeconds: number;
  closeModal: (proceed: boolean) => void;
}

// This page manages the ConfirmationRejectModal
const ConfirmationRejectModal = ({ isOpen, timeoutSeconds, closeModal }: Params): React.ReactElement => {
  const [secondsRemaining, setSecondsRemaining] = useState<number>(timeoutSeconds);
  const { t } = useTranslation();

  // Enable the reject button after the passed time
  useEffect(() => {
    if (!isOpen) {
      setSecondsRemaining(timeoutSeconds);
    }
    setTimeout(() => {
      if (isOpen && secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      }
    }, 1000);
  }, [isOpen, secondsRemaining, setSecondsRemaining, timeoutSeconds]);

  return (
    <Modal
      isOpen={isOpen}
      header={t('CONFIRM_REJECTION')}
      footer={
        <Stack inline itemSpacing={1}>
          <Button
            data-testid="confirm-cancel"
            variant="secondary"
            onClick={(): void => {
              closeModal(false);
            }}
          >
            {t('CANCEL')}
          </Button>
          <Button
            data-testid="confirm-reject"
            variant="primary"
            disabled={secondsRemaining > 0}
            onClick={(): void => {
              closeModal(true);
            }}
          >
            {t('REJECT')}
            {secondsRemaining > 0 ? ` (${secondsRemaining})` : ''}
          </Button>
        </Stack>
      }
    >
      <p>{t('PROCEED_WITH_REJECTION')}</p>
    </Modal>
  );
};

export default ConfirmationRejectModal;
