export enum PagerStatus {
  HIDDEN,
  DISPLAYED,
  DISABLED,
}

/**
 * PagerState maintains the state of the pager.
 */
export default interface PagerState {
  /**
   * enum to indicate the status of the More Items button.
   */
  status: PagerStatus;
  nextToken?: string;
}

export const buildPagerState = (currentState: PagerState, payload: any[]): PagerState => {
  let { status } = currentState;
  let nextToken;
  if (payload.length > 0) {
    const lastRequest = payload[payload.length - 1];
    nextToken = lastRequest.nextToken;
    if (nextToken && currentState.status === PagerStatus.HIDDEN) {
      status = PagerStatus.DISPLAYED;
    }
    if (!nextToken && currentState.status === PagerStatus.DISPLAYED) {
      status = PagerStatus.DISABLED;
    }
  } else if (status === PagerStatus.DISPLAYED) {
    status = PagerStatus.DISABLED;
  }
  return {
    status,
    nextToken,
  };
};
