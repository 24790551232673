import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToastNotificationsControl } from '@tanium/react-toast-notifications-context';
import IdleTimer from 'react-idle-timer';
import { useTranslation } from 'react-i18next';
import { Box } from '@tanium/react-box';
import { headerHeight } from 'components/styles/containers/NotificationWrapperDiv';
import AppHeader from 'components/app_header/AppHeader';
import PageNotFound from 'components/base_page/PageNotFound';
import User from 'gql/User';
import Helper from 'utils/Helper';
import AuthSession, { RemoveBrowserAuthSession, getCurrentSession, signIn } from 'utils/AuthSession';
import UserSelectors from 'components/app/store/UserSelectors';
import UserSlice from 'components/app/store/UserSlice';
import Spinner from 'components/common/spinner/Spinner';
import { getAppConfig } from 'utils/Config';
import { SaveCsrfToken } from 'utils/CsrfToken';
import Logger from 'logger/Logger';

const logger = Logger.getLogger('AppContainer');
const csrfLogger = Logger.getLogger('CSRF');

const ToMS = 1000 * 60;

export const accessPathKey = 'accessPath';

interface Props {
  children: ReactElement<any, any> | null;
  defaultIdleTimeout?: number;
}

const AppContainer: React.FC<Props> = ({ children, defaultIdleTimeout = 15 * ToMS }) => {
  const dispatch = useDispatch();
  const user: User | undefined = useSelector(UserSelectors.selectUser);
  const isLoadingUser = useSelector(UserSelectors.selectIsLoadingUser);
  const { showNotification } = useToastNotificationsControl();
  const notification = useSelector(UserSelectors.selectNotification);
  const history = useHistory();
  const { t } = useTranslation();

  const [sessionIdleTime, setSessionIdleTime] = useState(defaultIdleTimeout);

  // Set session idle time from the app config
  useEffect(() => {
    async function getConfig() {
      const config = await getAppConfig();
      if (config) {
        logger.info('Setting session idle timeout from config', config);
        setSessionIdleTime(config.idleTimeoutMinutes * ToMS);
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getConfig();
  }, [setSessionIdleTime]);

  // Get auth session
  useEffect(() => {
    async function getAuthState() {
      const authSession: AuthSession | undefined = await getCurrentSession();

      // If we are not signed in and there is not an active auth redirect with the access code, attempt to sign in
      /* istanbul ignore next */
      if (!authSession && !Helper.findGetParameter('code')) {
        const path = history.location.pathname;
        const query = history.location.search;
        const location = `${path}${query}`;
        if (location !== '/' && location !== '') {
          window.localStorage.setItem(accessPathKey, location);
        }

        await signIn();
      } else {
        csrfLogger.debug('saving CSRF Token');
        await SaveCsrfToken();
        dispatch(UserSlice.actions.fetchUser());
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getAuthState();
  }, [dispatch, history]);

  // useEffect with a dependency on notification, so that showNotification is not triggered on every render of this component
  useEffect(() => {
    if (notification) {
      showNotification({
        type: notification.type,
        contents: notification.contents,
      });
    }
  }, [notification, showNotification, dispatch]);

  // useEffect to handle triggering post-auth logic
  useEffect(() => {
    if (isLoadingUser === false) {
      const accessPath = window.localStorage.getItem(accessPathKey);
      if (accessPath !== null) {
        window.localStorage.removeItem(accessPathKey);
        history.push(accessPath);
      }
    }
  }, [isLoadingUser, history]);

  // If the user idles beyond timeout then logout, clear state
  const handleOnIdle = () => {
    // sign out the user _only_ from lockbox, but not from the identity broker
    RemoveBrowserAuthSession();
    dispatch(UserSlice.actions.signOutUser());
  };

  return (
    <>
      <IdleTimer timeout={sessionIdleTime} onIdle={handleOnIdle} debounce={250} />
      <AppHeader user={user} />
      <Box
        marginTop={`${headerHeight}px`}
        paddingLeft="1em"
        paddingRight="1em"
        height={`calc(100vh - ${headerHeight}px)`}
        overflow="visible"
        maxWidth="1800px"
        margin="4em auto 0 auto"
      >
        {isLoadingUser ? (
          <Spinner />
        ) : (
          <>{user ? children : <PageNotFound errorMessage={t('USER_IS_NOT_AUTHENTICATED')} />}</>
        )}
      </Box>
    </>
  );
};

export default AppContainer;
