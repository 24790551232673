import { combineEpics, Epic } from 'redux-observable';
import { AnyAction } from 'redux';
import { RootState } from 'store/RootReducer';
import UserEpics from 'components/app/store/UserEpics';
import ConsoleEpics from 'components/pages/console/store/ConsoleEpics';
import RequestEpics from 'components/pages/request/store/RequestEpics';
import GlobalServiceEpics from 'components/pages/console/store/GlobalServiceEpics';
import RequestAccessEpics from 'components/app/store/requestAccess/RequestAccessEpics';
import SessionEpics from 'components/pages/request/store/session/SessionEpic';
import AwsAccountObjectEpics from 'components/pages/console/store/AwsAccountObjectEpics';
import TargetTypeEpics from 'components/pages/console/store/TargetTypeEpics';
import FileUploadEpics from 'components/app/store/fileUpload/FileUploadEpics';
import DependencyArtifactEpics from 'components/app/store/dependencyArtifacts/DependencyArtifactsEpics';
import RestartInstanceEpics from 'components/app/store/restartInstance/RestartInstanceEpics';
import CustomerEpics from 'store/Customer/CustomerEpics';
import KubernetesClusterEpics from 'components/pages/console/store/KubernetesClusterEpics';
import TargetGroupEpics from 'components/pages/console/store/TargetGroupEpics';
import ScratchpadEpics from 'components/pages/request/store/scratchpad/ScratchpadEpics';
import K8sRoleTargetAccessEpics from 'components/pages/console/store/K8sRoleTargetAccessEpics';
import AwsRoleTargetAccessEpics from 'components/pages/console/store/AwsRoleTargetAccessEpics';
import HostTargetAccessEpics from 'components/pages/console/store/HostTargetAccessEpics';
import SSMParameterEpics from 'components/pages/adminPanel/store/ssmParameters/SSMParameterEpics';
import BastionTargetAccessEpics from 'components/pages/console/store/BastionTargetAccessEpics';
import WorkflowEpics from 'components/pages/adminPanel/store/workflow/WorkflowEpics';
import ChangeRequestEpics from 'components/pages/console/store/ChangeRequestEpics';
import StepFunctionExecutionEpics from 'components/pages/adminPanel/store/stepFunctionExecutions/StepFunctionExecutionEpics';

export type RootEpic = Epic<AnyAction, AnyAction, RootState>;

export const combinedEpics = combineEpics(
  ...UserEpics,
  ...ConsoleEpics,
  ...RequestEpics,
  ...SessionEpics,
  ...GlobalServiceEpics,
  ...RequestAccessEpics,
  ...AwsAccountObjectEpics,
  ...TargetTypeEpics,
  ...FileUploadEpics,
  ...RestartInstanceEpics,
  ...DependencyArtifactEpics,
  ...CustomerEpics,
  ...KubernetesClusterEpics,
  ...TargetGroupEpics,
  ...ScratchpadEpics,
  ...AwsRoleTargetAccessEpics,
  ...HostTargetAccessEpics,
  ...K8sRoleTargetAccessEpics,
  ...SSMParameterEpics,
  ...BastionTargetAccessEpics,
  ...WorkflowEpics,
  ...ChangeRequestEpics,
  ...StepFunctionExecutionEpics
);

export default RootEpic;
