import Helper from 'utils/Helper';
import { SingletonResult } from 'gql/Common';

export default interface SSMParameter {
  name: string;
  value: string;
}

export enum SSMParameterType {
  SlackToken = 'Slack',
  CSRFToken = 'CSRF',
  PagerDutyToken = 'PagerDuty',
  IntegrationTest = 'Integration Test',
}

const UPDATE_SSM_PARAMETER = `
    mutation updateSSMParameter($update: UpdateSSMParameterInput!) {
        updateSSMParameter(update: $update) {
            item {
                name
                value
            }
        }
    }
`;

export const updateSSMParameter = async (ssmParam: SSMParameter): Promise<SSMParameter> => {
  const result = await Helper.execGQL<SingletonResult<SSMParameter>>({
    query: UPDATE_SSM_PARAMETER,
    variables: {
      update: ssmParam,
    },
  });
  return result.updateSSMParameter.item;
};
